import React, { useContext, useEffect, useState } from "react";
import BBCode from "@bbob/react/es/Component";
import BBCodeParser from "../../functions/applyBBCode";
import { getEvent } from "../../api";
import Linkify from "react-linkify";
import DefaultThumbnail from "../../assets/images/DefaultThumbnail.png";
import "./style.scss";

// constants
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import removeExcessiveBreaks from "../../functions/removeExcessiveBreaks";
import { AllowedBBCodeTags, Colors, Time } from "../../constants";
import { UserContext } from "../../App";
import UsernameAvatarDisplay from "../Insertions/UsernameAvatarDisplay";
import moment from "moment";

const Event = (props) => {
	const currentUserData = useContext(UserContext);

	const eventId = props.match.params.id;
	const [event, setEvent] = useState<any>({});
	const [hasDoneQuery, setHasDoneQuery] = useState<boolean>(false);
	const [eventMessage, setEventMessage] = useState<string>("");
	const [isQuerying, setIsQuerying] = useState<boolean>(false);

	const active = event?.active;
	const placements = event?.placements || [];
	const loaded = event?._id;
	const startDate = event?.startDate;
	const endDate = event?.endDate;
	const startDateFormatted = (tFormat) => moment(startDate).format(tFormat);
	const endDateFormatted = (tFormat) => moment(endDate).format(tFormat);

	// event status
	const eventStatusId = active ? 0 : placements?.length ? 2 : loaded ? 1 : -1;
	const eventStatuses = ["Active", "Results pending", "Ended"];
	const eventStatus = eventStatuses[eventStatusId] || "Loading...";
	const showStartTime = !active && startDate >= new Date();

	useEffect(() => {
		if (isQuerying) return setHasDoneQuery(true);

		if (!hasDoneQuery) {
			setIsQuerying(true);
			getEvent(eventId)
				.then((response) => {
					setEvent({
						...response?.data?.event,
						description: `[h1]Test description[/h1]`,
					});
				})
				.catch((error) => {
					setEventMessage(error?.response?.data?.eventMessage);
					if (error?.response?.status === 504)
						setEventMessage(
							"Couldn't connect to LSS servers, try refreshing the page.",
						);
				})
				.finally(() => setIsQuerying(false));
		}
	}, [eventId, hasDoneQuery, isQuerying]);

	// returns activity status view
	const renderActivityStatus = (classVal) => (
		<OverlayTrigger
			placement="bottom"
			overlay={
				<Tooltip id={`${classVal}-tip`}>
					<div>Contest {active ? "runs" : "ran"} from</div>
					<b />
					<b />
					<div className={Colors.GREEN}>
						{startDateFormatted(Time.MMM_DD_YYYY_TIME)}
					</div>
					<b />
					<b />
					<div>until exactly</div>
					<b />
					<b />
					<div className={Colors.BLACK}>
						{endDateFormatted(Time.MMM_DD_YYYY_TIME)}
					</div>
				</Tooltip>
			}>
			<div
				className={`${classVal} event-buttons__activity-display 
	${Colors.Array.R_O_G[eventStatusId]} unselectable`}
				style={{ width: "100%" }}>
				<h2>{eventStatus}</h2>
				{showStartTime ? (
					<span className="event-buttons__time">
						Starts on
						<br />
						<b>{startDateFormatted(Time.MMM_DD_TIME)}</b>
					</span>
				) : (
					<span className="event-buttons__time">
						End{active ? "s" : "ed"} on <br />
						<b>{endDateFormatted(Time.MMM_DD_TIME)}</b>
					</span>
				)}
			</div>
		</OverlayTrigger>
	);

	// no events found
	if (!loaded)
		return (
			<div className="container mt-4">
				{isQuerying ? (
					<div
						className="d-flex justify-content-center"
						style={{ marginTop: "25vh", marginBottom: "25vh" }}>
						<Spinner animation="grow" variant="primary" />
					</div>
				) : (
					<div
						className="card card-body"
						style={{
							textAlign: "center",
							marginTop: "20vh",
							marginBottom: "20vh",
						}}>
						<h2 className="center-textoutput">
							{eventMessage ||
								"Something went wrong loading this event."}
						</h2>
					</div>
				)}
			</div>
		);

	// render events page
	return (
		<>
			<div className="container mt-4 d-flex event-page">
				{renderActivityStatus("d-lg-none")}
				<div className="event-main">
					<div className="document-header justify-content-center">
						<h1>{event?.name}</h1>
					</div>
					<div className="card no-top-radius card-body">
						{/* event thumbnail and hosts/judges*/}
						<JudgeHostThumbDisplay
							event={event}
							hoverCardProps={props?.hoverCardProps}
						/>
						<hr />
						{/* event info */}
						{event?.description && (
							<>
								<Linkify
									componentDecorator={(
										decoratedHref,
										decoratedText,
										key,
									) => (
										<a
											href={decoratedHref}
											target="_blank" // open links in a new tab
											rel="noopener noreferrer"
											key={key}>
											{decoratedText}
										</a>
									)}>
									<BBCode
										plugins={[BBCodeParser()]}
										options={{
											onlyAllowTags:
												AllowedBBCodeTags.BBCODE_EXTENDED_TAGS,
										}}>
										{removeExcessiveBreaks(
											event?.description,
										)}
									</BBCode>
								</Linkify>
								<hr />
							</>
						)}
					</div>
				</div>
				<div className="d-flex flex-column event-buttons">
					{renderActivityStatus("d-none d-lg-block")}
					<RenderButtons
						event={event}
						currentUserData={currentUserData}
					/>
				</div>
			</div>
		</>
	);
};

const RenderButtons = (props) => {
	const event = props?.event;
	const currentUserData = props?.currentUserData;
	const active = event?.active;
	const isJudge = event?.judges?.some(
		(judge) => judge?._id === currentUserData?._id,
	);
	const isHost = event?.hosts?.some(
		(host) => host?._id === currentUserData?._id,
	);

	return (
		<>
			{active && !isHost && !isJudge && (
				<button className="btn btn-primary btn-block">Submit</button>
			)}
			{isHost && (
				<button className="btn btn-primary btn-block" disabled={true}>
					Edit
				</button>
			)}
		</>
	);
};

const JudgeHostThumbDisplay = (props) => {
	// hosts and judges
	const event = props?.event;
	const hosts = event?.hosts || [];
	const judges = event?.judges || [];
	const hostHeader = hosts?.length === 1 ? "Host" : "Hosts";
	const judgeHeader = judges?.length === 1 ? "Judge" : "Judges";

	return (
		<div className="d-flex flex-row judge-host-flex">
			<div
				style={{
					backgroundImage: `url(${
						event?.thumbnail || DefaultThumbnail
					}`,
				}}
				className="event-img"></div>
			<div style={{ width: "100%" }}>
				<h2>{hostHeader}</h2>
				<div className="flex-user-display">
					{hosts?.map((host) => (
						<React.Fragment key={host?._id}>
							<UsernameAvatarDisplay
								user={host}
								{...props?.hoverCardProps}
							/>
						</React.Fragment>
					))}
				</div>
				<hr />
				<h2>{judgeHeader}</h2>
				<div className="flex-user-display">
					{judges?.length ? (
						judges?.map((judge) => (
							<React.Fragment key={judge?._id}>
								<UsernameAvatarDisplay
									user={judge}
									{...props?.hoverCardProps}
								/>
							</React.Fragment>
						))
					) : (
						<i className={Colors.GRAY}>This event has no judges.</i>
					)}
				</div>
			</div>
		</div>
	);
};

// const ImageUpload = (props) => {
// 	const [image, setImage] = useState<any>(null);

// 	const onDrop = (acceptedFiles) => {
// 		const file = acceptedFiles[0];
// 		setImage(file);
// 		props.setSubmission({
// 			...props.submission,
// 			image: file,
// 		});
// 	};

// 	const { getRootProps, getInputProps, isDragActive } = useDropzone({
// 		onDrop,
// 		accept: {
// 			"image/*": [".png", ".jpg", ".jpeg"],
// 		},
// 		multiple: false,
// 	});

// 	return (
// 		<span>
// 			<div className="thumbnail-upload" {...getRootProps()}>
// 				<input
// 					type="file"
// 					{...getInputProps()}
// 					accept="image/png, image/jpeg, image/jpg"
// 				/>
// 				{!image && isDragActive ? (
// 					<p>Drop your image here...</p>
// 				) : (
// 					<p>Drag or click to upload your file here!</p>
// 				)}
// 				{image && (
// 					<div>
// 						<p>
// 							<u>Submission to be sent in</u>
// 						</p>
// 						<img
// 							src={URL.createObjectURL(image)}
// 							alt="Thumbnail"
// 							className="uploaded-image"
// 						/>
// 					</div>
// 				)}
// 			</div>
// 		</span>
// 	);
// };

// const MapSubmissions = () => {
// 	const [submissions, setSubmissions] = useState<any[]>([]);
// 	const [hasDoneQuery, setHasDoneQuery] = useState<boolean>(false);

// 	// use effect hook trigger upon JSX element render
// 	useEffect(() => {
// 		if (!hasDoneQuery && !submissions?.length) {
// 			setHasDoneQuery(true);
// 			getEventSubmissions().then((response) => {
// 				if (response.data) setSubmissions(response.data.submissions);
// 			});
// 		}
// 	}, [submissions?.length, hasDoneQuery]);

// 	// cleanup function upon unmount
// 	useEffect(() => {
// 		return () => {
// 			setHasDoneQuery(true);
// 			setSubmissions([null]);
// 		};
// 	}, []);

// 	// function getting image url from description
// 	function findImageGifUrls(content) {
// 		const pattern = /(?:https?|ftp):\/\/\S+\.(?:jpg|jpeg|png|gif)/gi;
// 		let match = undefined;
// 		if (content) match = content?.match(pattern);
// 		if (match) return match[0];
// 	}

// 	return submissions?.length > 0 ? (
// 		<div className="container mt-4">
// 			<h1 className="center-textoutput" style={{ marginBottom: "18px" }}>
// 				<span className="tags">All submissions</span>
// 			</h1>
// 			<div className="events-wrapper">
// 				{submissions?.map((submission, key) => {
// 					let image;

// 					if (submission?.content)
// 						image = findImageGifUrls(submission.content);

// 					// change the opacity state when hovering to true/false
// 					const handleOpacity = (mode) => {
// 						if (
// 							submission?.content !== "" &&
// 							submission?.content !== undefined
// 						) {
// 							setSubmissions((prevState) =>
// 								prevState.map((state) =>
// 									state._id === submission._id
// 										? // mode is either true or false
// 										  { ...state, isHovered: mode }
// 										: state
// 								)
// 							);
// 						}
// 					};

// 					return (
// 						<div
// 							key={submission?._id}
// 							style={{ height: "max-height" }}
// 							className="row">
// 							<div
// 								className="card"
// 								style={{
// 									boxShadow: "unset",
// 									borderColor: "rgba(0,0,0,0)",
// 								}}>
// 								<div
// 									className="card-body"
// 									style={{
// 										borderRadius: "20px",
// 										boxShadow:
// 											"0 0 50px 5px rgba(0, 0, 0, 0.4)",
// 									}}>
// 									<div
// 										className="width-adjust"
// 										style={{
// 											whiteSpace: "nowrap",
// 											overflow: "hidden",
// 											textOverflow: "elipsis",
// 										}}>
// 										<h4
// 											style={{
// 												opacity: submission?.isHovered
// 													? "0"
// 													: "1",
// 												transition:
// 													"opacity 0.2s ease-in-out",
// 											}}
// 											className="center-textoutput">
// 											<b>{submission?.name}</b>
// 										</h4>
// 										<div className="col-12 submission">
// 											<img
// 												style={{
// 													opacity:
// 														submission?.isHovered
// 															? "0"
// 															: "1",
// 												}}
// 												onError={(e) => {
// 													// @ts-ignore
// 													e.target.src =
// 														DefaultThumbnail;
// 												}}
// 												alt=""
// 												className="submission__image"
// 												src={
// 													submission?.image
// 														? submission?.image
// 														: image
// 														? image
// 														: DefaultThumbnail
// 												}
// 											/>
// 											<div
// 												className="submission__subtext"
// 												style={{
// 													opacity:
// 														submission?.isHovered
// 															? "1"
// 															: "0",
// 												}}
// 												onMouseEnter={() =>
// 													handleOpacity(true)
// 												}
// 												onMouseLeave={() =>
// 													handleOpacity(false)
// 												}>
// 												{submission?.content}
// 											</div>
// 										</div>
// 									</div>
// 								</div>
// 							</div>
// 						</div>
// 					);
// 				})}
// 			</div>
// 		</div>
// 	) : null;
// };

export default Event;
