import { api } from "./index";

//!----------------------------//
//! --- account api routes --- //
//!----------------------------//

// --- customization --- //
export const changeTheme = (contentID, themeData) =>
	api.patch(
		`/account/${contentID}/customization/theme?type=account`,
		themeData,
	);
export const executeCrafting = (contentID, themeData) =>
	api.patch(
		`/account/${contentID}/customization/craft/${themeData?.type}/${themeData?.value}?type=account`,
	);

export const changeAvatar = (contentID, avatarData) => {
	// regular
	if (avatarData?.field === "text")
		return api.patch(
			`/account/${contentID}/customization/avatar?type=account`,
			avatarData,
		);
	const file = avatarData?.avatar;

	const imageData = new FormData();
	imageData.append("image", file);
	// image
	return api
		.post(`/accesspoint/image/upload/${contentID}?type=account`, imageData)
		.then((response) => {
			avatarData.avatar = response?.data?.image;
		})
		.catch(() => {})
		.then(() => {
			return api.patch(
				`/account/${contentID}/customization/avatar?type=account`,
				avatarData,
			);
		});
};
export const changeBanner = (contentID, bannerData) => {
	// regular
	if (bannerData?.field === "text")
		return api.patch(
			`/account/${contentID}/customization/banner?type=account`,
			bannerData,
		);
	const file = bannerData?.banner;

	const imageData = new FormData();
	imageData.append("image", file);
	// image
	return api
		.post(`/accesspoint/image/upload/${contentID}?type=account`, imageData)
		.then((response) => {
			bannerData.banner = response?.data?.image;
		})
		.catch(() => {})
		.then(() => {
			return api.patch(
				`/account/${contentID}/customization/banner?type=account`,
				bannerData,
			);
		});
};
export const updateAboutMe = (contentID, aboutMeData) =>
	api.patch(
		`/account/${contentID}/customization/about?type=account`,
		aboutMeData,
	);
export const checkYoutubeVideo = (input) =>
	api.get(`account/checkurl?input=${input}`);
export const changeMusic = (musicData, contentID) =>
	api.patch(
		`/account/${contentID}/customization/music?type=account`,
		musicData,
	);

// general settings
export const changeGeneralSettings = (updatedSettings) =>
	api.patch(`/account/settings/general`, updatedSettings);
export const changeUsername = (formData) =>
	api.patch(`/account/settings/username`, formData);
export const changeFlairs = (contentID, CommentText) =>
	api.patch(
		`/account/${contentID}/customization/flairs?type=account`,
		CommentText,
	);
export const changeSocial = (formData) =>
	api.patch(`/account/settings/social`, formData);

// password editing
export const requestPasswordReset = (formData) =>
	api.post("/account/security/request_password_reset", formData);

// * SHARE integration
// Note: Some of these only needs the LSS account ID, which is in the headers already, so there is no need for an extra ID parameter.
export const getShareAuthenticationStatus = () =>
	api.get("/account/SHARE/get_share_auth_status");
export const requestShareAuthCode = (formData) =>
	api.post("/account/SHARE/request_share_auth_code", formData);
export const cancelShareAuth = () =>
	api.post("/account/SHARE/cancel_share_auth");
export const unlinkWithShareBot = () => api.post("/account/SHARE/unlink_share");
