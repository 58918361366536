import { api } from "./index";

//!----------------------------//
//! --- profile api routes --- //
//!----------------------------//

// profile - social
export const getFriendsListPage = (userID, friendData) =>
	api.get(`/profile/${userID}/friends?friendspage=${friendData.friendspage}`);
export const getIncomingFriendRequestsPage = (userID, friendData) =>
	api.get(
		`/profile/${userID}/friends/incoming?inreqpage=${friendData.inreqpage}`
	);
export const getOutgoingFriendRequestsPage = (userID, friendData) =>
	api.get(
		`/profile/${userID}/friends/outgoing?outreqpage=${friendData.outreqpage}`
	);
export const getBlockListPage = (userID, blockData) =>
	api.get(`/profile/${userID}/blocklist?blockpage=${blockData.blockpage}`);
export const toggleFriend = (userID, friendData) =>
	api.patch(`/profile/${userID}/friend`, friendData);
export const toggleFollow = (userID) => api.patch(`/profile/${userID}/follow`);
export const toggleUserBlock = (userID) =>
	api.patch(`/profile/${userID}/block`);

// profile - comments
export const getProfileComment = (id) => api.get(`/profile/comment/${id}`);
export const getProfileComments = (id) => api.get(`/profile/${id}/comments`);
export const getProfileCommentsPage = (id, commentData) =>
	api.get(`/profile/${id}/comments?page=${commentData.page}`);

export const createProfileComment = (id, profileCommentData) =>
	api.post(`/profile/${id}/comment`, profileCommentData);
export const editProfileComment = (id, contentID, commentData) =>
	api.patch(
		`/profile/${id}/comments/${contentID}/edit?type=profile_comment`,
		commentData
	);
export const reactProfileComment = (commentID, profileCommentData) =>
	api.patch(
		`/profile/${profileCommentData?.origin}/comments/${commentID}/react`,
		profileCommentData
	);
export const deleteProfileComment = (contentID, deleteData) =>
	api.patch(
		`/profile/comments/${contentID}/delete?type=profile_comment&profile=${deleteData.profileID}`
	);
export const obliterateProfileComment = (contentID, deleteData) =>
	api.delete(
		`/profile/comments/${contentID}/obliterate?type=profile_comment&profile=${deleteData.profileID}`
	);
// profile - threads
export const getThreadReply = (id, commentID) =>
	api.get(`/profile/${id}/comments/${commentID}`);
export const getThreadReplyPage = (id, commentID, replyData) =>
	api.get(
		`/profile/${id}/comments/${commentID}?page=${replyData.page}&threadpage=${replyData.threadpage}`
	);
export const createProfileCommentReply = (profileID, commentID, replyData) =>
	api.post(`/profile/${profileID}/comments/${commentID}/create`, replyData);
export const editProfileCommentReply = (id, contentID, commentData) =>
	api.patch(
		`/profile/${id}/comments/replies/${contentID}/edit?type=profile_reply`,
		commentData
	);
export const deleteProfileReply = (replyID, commentID, profileID) =>
	api.patch(
		`/profile/${profileID}/comments/${commentID}/replies/${replyID}/delete?type=profile_reply`
	);
export const obliterateProfileReply = (replyID, commentID, profileID) =>
	api.delete(
		`/profile/${profileID}/comments/${commentID}/replies/${replyID}/obliterate?type=profile_reply`
	);
// profile - other
export const getProfile = (id) => api.get(`/profile/get/${id}`);
export const moderateUser = (id, moderationData) =>
	api.patch(`/profile/${id}/moderate`, moderationData);