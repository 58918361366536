import React from "react";
import MapStars from "../../Insertions/MapStars";
import DefaultThumbnail from "../../../assets/images/DefaultThumbnail.png";
import "./style.scss";

// constants
import { LevelConstants } from "../../../constants";

const ImageStarContainer = ({ level, gameProperties }) => {
	const rating = parseFloat(level?.rating) || 0;
	const game: number | undefined = Number.isInteger(level?.game)
		? level?.game
		: undefined;

	const gameObj = gameProperties?.find((prop) => prop?.internalID === game);

	return (
		<div className="image-star-container">
			<div className="star-rating">
				{level?.rating > 0 &&
				level?.status !== LevelConstants.Statuses.PRIVATE &&
				level?.status !== LevelConstants.Statuses.DELETED ? (
					<MapStars
						level={level}
						rate={null}
						top=""
						color={
							rating >= 4.9 && level?.rates?.length > 5
								? "#2cd40f"
								: ""
						}
						width={window.innerWidth < 1000 ? "6mm" : "7.5mm"}
						height={window.innerWidth < 1000 ? "10mm" : "11mm"}
					/>
				) : (
					<div
						className={`default-rate 
						${
							level?.status === LevelConstants.Statuses.PRIVATE ||
							level?.status === LevelConstants.Statuses.DELETED
								? "vanish"
								: ""
						}`}>
						This level has no rates yet
					</div>
				)}
			</div>
			{/* Set to default thumbnail if no thumbnail is present */}
			<div
				className={`${
					level?.status === LevelConstants.Statuses.FEATURED
						? "featured-thumbnail"
						: "level-thumbnail"
				}`}>
				<img
					className="level-thumbnail__thumb"
					alt="Level Thumbnail"
					src={level?.thumbnail || DefaultThumbnail}
					onError={(ThumbError) => {
						// @ts-ignore
						ThumbError.target.onerror = null;
						// @ts-ignore
						ThumbError.target.src = DefaultThumbnail;
					}}
				/>
			</div>
			{/* Featured overlay display */}
			<span
				className={`${
					level?.status !== LevelConstants.Statuses.FEATURED
						? "vanish"
						: "featured-thumbnail__overlay"
				}`}
			/>
			{/* Game icon inside of banner */}
			{game !== undefined && (
				<div
					className="game-icon"
					style={{ backgroundImage: `url(${gameObj?.cleanLogo})` }}
				/>
			)}
		</div>
	);
};

export default ImageStarContainer;
