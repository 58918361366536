import React from "react";

// constants
import { UserConstants } from "../constants";

// map the highest role and/or apply a color based upon it
export const mapHighestRole = (userRoles, mode) => {
	const excludedRoles = [
		UserConstants.Punishments.MUTED,
		UserConstants.Punishments.RATE_RESTRICTED,
	];
	const rolesRankMap = {
		[UserConstants.MainRoles.LEADER]: 9,
		[UserConstants.MainRoles.ADMIN]: 8,
		[UserConstants.MainRoles.DEVELOPER]: 7,
		[UserConstants.MainRoles.PARTNER]: 6,
		[UserConstants.MainRoles.MODERATOR]: 5,
		[UserConstants.MainRoles.MITTEN]: 4,
		[UserConstants.MainRoles.DONATOR_PLUS]: 3,
		[UserConstants.MainRoles.DONATOR]: 2,
		[UserConstants.MainRoles.BETA_TESTER]: 1,
		[UserConstants.Punishments.BANNED]: -1,
		[UserConstants.Punishments.MUTED]: -2,
		[UserConstants.Punishments.RATE_RESTRICTED]: -3,
		[UserConstants.Punishments.DEACTIVATED]: -4,
		[UserConstants.MainRoles.ARCHIVE_ACCOUNT]: -5,
	};

	const roleColors = {
		[UserConstants.MainRoles.LEADER]: "#ff1919",
		[UserConstants.MainRoles.ADMIN]: "#1cde02",
		[UserConstants.MainRoles.DEVELOPER]: "#f0e630",
		[UserConstants.MainRoles.PARTNER]: "#c93ff3",
		[UserConstants.MainRoles.MODERATOR]: "#ffa500",
		[UserConstants.MainRoles.MITTEN]: "#fac125",
		[UserConstants.MainRoles.DONATOR_PLUS]: "#31f7f1",
		[UserConstants.MainRoles.DONATOR]: "#31f7f1",
		[UserConstants.MainRoles.BETA_TESTER]: "#ffb3ff",
		[UserConstants.Punishments.BANNED]: "#9c9c9c",
	};

	const cardColors = {
		[UserConstants.MainRoles.LEADER]: "#ff191950",
		[UserConstants.MainRoles.ADMIN]: "#1cde0224",
		[UserConstants.MainRoles.DEVELOPER]: "#f0e63025",
		[UserConstants.MainRoles.PARTNER]: "#c93ff340",
		[UserConstants.MainRoles.MODERATOR]: "#ffa50040",
		[UserConstants.MainRoles.MITTEN]: "#fac12530",
		[UserConstants.MainRoles.DONATOR_PLUS]: "#31f7f140",
		[UserConstants.MainRoles.DONATOR]: "#31f7f140",
		[UserConstants.MainRoles.BETA_TESTER]: "#ffb3ff40",
		[UserConstants.Punishments.BANNED]: "#31f7f140",
	};

	if (userRoles && userRoles.length > 0) {
		const filteredRoles = userRoles?.filter(
			(role) => !excludedRoles.includes(role)
		);
		const filteredExistingRoles = filteredRoles?.filter((role) =>
			rolesRankMap?.hasOwnProperty(role)
		);

		if (filteredExistingRoles.length > 0) {
			const highestRole = filteredRoles.reduce((highest, role) => {
				return rolesRankMap[role] > rolesRankMap[highest]
					? role
					: highest;
			});

			if (mode === "color") return roleColors[highestRole]; // Return the color
			if (mode === "card") return cardColors[highestRole]; // Return the color
			if (mode === "role") return highestRole; // Return the role
		}
	}

	// darius.
	if (
		userRoles?.includes(UserConstants.MainRoles.ELDER_GOD) &&
		mode === "role"
	)
		return "Elder God";

	return mode === "color"
		? "#ffffff"
		: mode === "role"
		? UserConstants.MainRoles.MEMBER
		: mode === "card" && "rgba(0,0,0,0.4)"; // Return a default color or role if no roles found
};

// sorts an array of roles
export const sortRoles = (userRoles) => {
	const rolePriority = {
		[UserConstants.MainRoles.LEADER]: 1,
		[UserConstants.MainRoles.ADMIN]: 2,
		[UserConstants.MainRoles.DEVELOPER]: 3,
		[UserConstants.MainRoles.PARTNER]: 4,
		[UserConstants.MainRoles.MODERATOR]: 5,
		[UserConstants.MainRoles.MITTEN]: 6,
		[UserConstants.MainRoles.DONATOR_PLUS]: 7,
		[UserConstants.MainRoles.DONATOR]: 8,
		[UserConstants.MainRoles.BETA_TESTER]: 9,
		[UserConstants.Punishments.BANNED]: 10,
		[UserConstants.Punishments.MUTED]: 11,
		[UserConstants.Punishments.RATE_RESTRICTED]: 12,
	};
	const sortedRoles = userRoles?.sort(
		(a, b) => rolePriority[a] - rolePriority[b]
	);
	return sortedRoles;
};

// displayrole styles with fade-in animation
//! pass prop in the same manner as done on profile/hoverusercard component
export const displayRoleStyle = (props) => {
	let opacity;

	// use transition style
	if (props?.showContent === true || props?.showContent === false)
		opacity = { opacity: props.showContent ? "1" : "0" };

	// use loop value
	if (props?.loadValue !== undefined)
		opacity = { opacity: (props.loadValue - 50) / 50 };

	// return the styles
	return {
		...opacity,
		transition: "opacity 0.6s ease-in-out",
		paddingLeft: "6px",
		paddingRight: "6px",
		marginRight: "6px",
		position: "relative",
	};
};

export const punishmentColor = (punishments) => {
	const infractions = [
		UserConstants.Punishments.MUTED,
		UserConstants.Punishments.RATE_RESTRICTED,
		UserConstants.Punishments.BANNED,
		UserConstants.Punishments.WARNING,
	];
	// return if no punishments match up
	if (punishments.some((punishment) => !infractions.includes(punishment)))
		return <span>- Error -</span>;
	// mute & rate restrict
	if (punishments?.length > 1)
		return (
			<>
				<span className="gray">Muted</span> &{" "}
				<span className="gloamvitiation-orange">Rate Restricted</span>
			</>
		);
	// muted
	if (punishments?.includes(UserConstants.Punishments.MUTED))
		return <span className="gray">{punishments[0]}</span>;
	// banned
	if (punishments?.includes(UserConstants.Punishments.BANNED))
		return <span className="hydrawisp-red">{punishments[0]}</span>;
	// rate restricted
	if (punishments?.includes(UserConstants.Punishments.RATE_RESTRICTED))
		return <span className="gloamvitiation-orange">{punishments[0]}</span>;
	// warned
	if (punishments?.includes(UserConstants.Punishments.WARNING))
		return <span className="yellow">{punishments[0]}</span>;
};
