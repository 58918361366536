// imports
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import { useHistory, useLocation } from "react-router-dom";

import {
	getAnnouncementById,
	deleteAnnouncement,
	createAnnouncementReply,
	subscribeToThread,
	getAnnouncementReplyPage,
	editAnnouncementReply,
	deleteAnnouncementReply,
	obliterateAnnouncementReply,
	toggleAnnouncementLock,
} from "../../api";

// react-bootstrap components used
import {
	Container,
	Spinner,
	Modal,
	Button,
	OverlayTrigger,
	Tooltip,
} from "react-bootstrap";
import { UserContext } from "../../App";
import Pagination from "../Pagination/Pagination";
import { PollChart, processPoll, trimPollContent } from "./Polls";
import removeExcessiveBreaks from "../../functions/removeExcessiveBreaks";
import AnnouncementReact from "./AnnouncementReaction";
import ReadMore from "../Insertions/ReadMore";

// constants
import { UserConstants } from "../../constants";

const useQuery = () => {
	return new URLSearchParams(useLocation().search);
};

/**
 * Display a specific announcement.
 *
 * @returns
 */
const Announcement = (props) => {
	const announcementId = props.match.params.id;
	const currentUserData = useContext<any>(UserContext);

	// state info
	const [announcement, setAnnouncement] = useState<any>(null);
	const [hasDoneQuery, setHasDoneQuery] = useState<boolean>(false);
	const [announcementMessage, setAnnouncementMessage] = useState<string>("");

	const [announcementToDelete, setAnnouncementToDelete] =
		useState<string>("");
	const [deleteAnnouncementModalShow, setDeleteAnnouncementModalShow] =
		useState<boolean>(false);

	const [author, setAuthor] = useState<any>(null);
	// thread related states
	const [announcementModalShow, setAnnouncementModalShow] =
		useState<boolean>(false);
	const [announcementReply, setAnnouncementReply] = useState<any>(null);
	const [lockReply, setLockReply] = useState<boolean>(false);
	const [deleteAction, setDeleteAction] = useState<string>("");

	const [announcementReplies, setAnnouncementReplies] = useState<any[]>([]);
	const [controlAnnouncementReplies, setControlAnnouncementReplies] =
		useState<any[]>([]);
	const [replyAuthors, setReplyAuthors] = useState<any[]>([]);
	const [showReplies, setShowReplies] = useState<boolean>(false);
	const [repliesChanged, setRepliesChanged] = useState<boolean>(false);
	const [numberOfThreadPages, setNumberOfThreadPages] = useState<number>(0);
	const [prevThreadPage, setPrevThreadPage] = useState<number>(-1);

	// reusable constants
	const cantPostComments =
		!currentUserData?.isStaff &&
		(currentUserData?.mainRoles?.includes(
			UserConstants.Punishments.MUTED
		) ||
			!currentUserData?.verified ||
			!currentUserData);
	const history = useHistory();
	const query = useQuery();
	const threadpage =
		parseInt(query.get("threadpage") ?? "0") > 0
			? parseInt(query.get("threadpage") ?? "0")
			: 1;

	// set page title
	useEffect(() => {
		document.title = "Announcement - Level Share Square";
	}, []);

	// retrieve announcement info with ID from props
	useEffect(() => {
		if (!hasDoneQuery) {
			getAnnouncementById(announcementId).then((response) => {
				setAnnouncement(processPoll(response?.data?.announcement));
				setAuthor(response?.data?.author);
				setAnnouncementMessage(response?.data?.message);
			});
			setHasDoneQuery(true);
		}
	}, [hasDoneQuery, announcementId]);

	// fetch replies belonging to a comment if "targetannouncement" gets triggered
	useEffect(() => {
		if (prevThreadPage === threadpage) return;

		if (repliesChanged) {
			setShowReplies(false);
			getAnnouncementReplyPage(announcement._id, { threadpage }).then(
				(response) => {
					if (response?.data) {
						if (
							response?.data?.numberOfPages < threadpage &&
							response?.data?.numberOfPages !== 0
						) {
							query.set("threadpage", "1");
							history.push(`?${query.toString()}`);
							return setRepliesChanged(true);
						}
						// update authors, replies, etc.
						setAnnouncementReplies(response.data.replies);
						setControlAnnouncementReplies(response.data.replies);
						setReplyAuthors(
							currentUserData?._id &&
								response?.data?.authors?.length // conditional chaining
								? [...response?.data?.authors, currentUserData]
								: currentUserData?._id
								? [currentUserData]
								: response?.data?.authors
						);
						setNumberOfThreadPages(
							response.data.numberOfPages || 1
						);
						setShowReplies(true);
						setPrevThreadPage(threadpage);
					}
				}
			);

			setRepliesChanged(false);
		}
	}, [
		announcement,
		announcementModalShow,
		repliesChanged,
		threadpage,
		prevThreadPage,
		showReplies,
		currentUserData,
		query,
		history,
	]);

	// remove loading spinner if no announcement is found
	useEffect(() => {
		const spinner = document.getElementById("loading-spinner");
		if (spinner && announcementMessage) spinner.remove();
	}, [announcementMessage]);

	// switch from editing to non editing mode
	const switchEditState = (contentId) => {
		setAnnouncementReplies((prevReplies) =>
			prevReplies.map((reply) =>
				reply._id === contentId
					? { ...reply, editState: !reply.editState }
					: reply
			)
		);
	};

	// handle the locking of an announcement
	const handleAnnouncementLock = () => {
		// toast message
		toast.info(
			announcement?.locked
				? "Unlocking announcement..."
				: "Locking announcement..."
		);
		// api call
		toggleAnnouncementLock(announcement._id).then(() => {
			// update state
			setAnnouncement((prevState) => {
				return {
					...prevState,
					locked: !announcement?.locked,
				};
			});
		});
	};

	// deleting the announcement
	const deleteAnnouncementModal = () => {
		return (
			<Modal
				show={deleteAnnouncementModalShow}
				onHide={() => setDeleteAnnouncementModalShow(false)}
				size="sm"
				aria-labelledby="contained-modal-title-vcenter"
				className="popup-modal"
				centered>
				<span className="modal-fill">
					<Modal.Body className="modal-filter">
						<h4 style={{ textAlign: "center" }}>
							{deleteAnnouncementModalShow
								? `Are you sure you want to delete this ${
										deleteAction === ""
											? "announcement"
											: "reply"
								  }?`
								: "Closing..."}
						</h4>
					</Modal.Body>
					<Modal.Footer className="mx-auto modal-buttons modal-filter">
						<Button
							onClick={() => {
								if (
									["obliterate", "delete"].includes(
										deleteAction
									)
								)
									toast.info("Deleting reply...");
								else toast.info("Deleting announcement...");

								// reset values
								setDeleteAnnouncementModalShow(false);
								// select what to delete
								if (deleteAction === "delete") {
									deleteAnnouncementReply(
										announcementToDelete,
										announcement._id
									).then(() => {
										updateReplyCount("-");
										setAnnouncementReplies((prevState) =>
											prevState.map((reply) => {
												if (
													announcementToDelete ===
													reply._id
												)
													return {
														...reply,
														deleted: true,
													};
												return reply;
											})
										);
									});
								} else if (deleteAction === "obliterate") {
									obliterateAnnouncementReply(
										announcementToDelete,
										announcement._id
									).then(() =>
										setAnnouncementReplies((prevState) =>
											prevState.filter(
												(reply) =>
													reply._id !==
													announcementToDelete
											)
										)
									);
								} else
									deleteAnnouncement(
										announcementToDelete
									).then(() => history.push("/"));
								setDeleteAction("");
								setAnnouncementToDelete("");
							}}>
							Yes
						</Button>
						<Button
							onClick={() =>
								setDeleteAnnouncementModalShow(false)
							}>
							No
						</Button>
					</Modal.Footer>
				</span>
			</Modal>
		);
	};

	// update the amount of replies
	const updateReplyCount = (operation) => {
		setAnnouncement((prevAnnouncement) => {
			let updatedReplyCount;
			if (!announcement.replyCount) {
				updatedReplyCount =
					operation === "+"
						? (prevAnnouncement?.replies?.length || 0) + 1
						: (prevAnnouncement?.replies?.length || 0) - 1;
			} else {
				updatedReplyCount =
					operation === "+"
						? (prevAnnouncement?.replyCount || 0) + 1
						: (prevAnnouncement?.replyCount || 0) - 1;
			}
			return {
				...prevAnnouncement,
				replyCount: updatedReplyCount,
			};
		});
	};

	// thread for announcements
	const announcementThreadModal = () => {
		return (
			<Modal
				show={announcementModalShow}
				onHide={() => setAnnouncementModalShow(false)}
				className="comment-modal"
				size="xl"
				aria-labelledby="contained-modal-title-vcenter"
				centered>
				<div
					className="modal-fill"
					style={{
						width: "100%",
					}}>
					<Modal.Body className="thread-modal-body">
						<div
							className={`comment public-comment__main`}
							style={{
								backgroundColor: "rgb(255,255,255,0.1)",
							}}>
							<div>
								<div>
									<div
										style={{
											position: "absolute",
											top: "6px",
											right: "6px",
										}}>
										{/* display all icons*/}
										{currentUserData?.isAdmin && (
											<OverlayTrigger
												delay={{
													show: 210,
													hide: 0,
												}}
												placement="top"
												overlay={
													<Tooltip id="deleteAnnouncement">
														Delete announcement
													</Tooltip>
												}>
												<a
													onClick={() => {
														setAnnouncementToDelete(
															announcement._id
														);
														setAnnouncementModalShow(
															false
														);
														setDeleteAnnouncementModalShow(
															true
														);
													}}
													href={"#!"}
													className="material-symbols-outlined thread-icon">
													delete
												</a>
											</OverlayTrigger>
										)}
									</div>
									{/* author avatar */}
									<span
										className="comment__icon"
										style={{
											borderColor: author?.icon_outline,
											boxShadow: author?.icon_glow
												? `0 0 4px 2px ${author?.icon_glow}`
												: "none",
										}}>
										<img
											alt="Author Avatar"
											className="comment__icon__image"
											onError={(e) => {
												// @ts-ignore
												e.target.src =
													props.images?.defaultAvatar;
											}}
											src={
												author?.avatar
													? author?.avatar
													: props.images
															?.defaultAvatar
											}
										/>
									</span>
									{/* author name */}
									<span className="comment__commenter-name">
										<a
											title={author?.username}
											href={`/users/${announcement?.author}`}>
											{author?.username}
										</a>
									</span>
								</div>

								{/* Add the like/dislike button */}
								<AnnouncementReact
									announcement={announcement}
									setAnnouncement={setAnnouncement}
									single={true}
								/>
								{/* content + timestamp + title*/}
								<div className="comment__comment-body">
									<h4
										className="center-textoutput"
										style={{
											borderRadius: "12px",
											borderStyle: "solid",
											borderWidth: "2px",
											borderColor:
												"rgba(255,255,255, 0.4)",
											marginTop: "18px",
											padding: "28px",
										}}>
										<u>
											{announcement?.title ||
												"Untitled announcement"}
										</u>
									</h4>
									<div className="center-textoutput">
										<ReadMore
											content={
												trimPollContent(announcement) ||
												"No main content attached."
											}
											document={announcement}
											height={600}
											extendBBCode={true}
										/>
									</div>
									{announcement?._id &&
										announcement?.poll !== undefined && (
											<PollChart
												announcement={announcement}
												setAnnouncement={
													setAnnouncement
												}
												currentUserData={
													currentUserData
												}
												mode="single"
												isThread={true}
												showVote={true}
											/>
										)}
									<hr className="hr-margin" />
								</div>

								<div className="comment-footer__date">
									Posted on{" "}
									<b>
										{moment(announcement?.postDate).format(`
									MMM D, YYYY - h:mm A
								`)}
									</b>
								</div>
								<div className="comment-footer__date__extra">
									<b>
										{moment(announcement?.postDate).format(`
									MMM D
								`)}
									</b>
								</div>
							</div>
						</div>
						<hr />
						<AnnouncementThreadReplies
							announcementReplies={announcementReplies}
							controlAnnouncementReplies={
								controlAnnouncementReplies
							}
							setControlAnnouncementReplies={
								setControlAnnouncementReplies
							}
							setDeleteAction={setDeleteAction}
							setAnnouncementReplies={setAnnouncementReplies}
							images={props.images}
							showReplies={showReplies}
							setAnnouncementModalShow={setAnnouncementModalShow}
							announcement={announcement}
							threadpage={threadpage}
							setRepliesChanged={setRepliesChanged}
							replyAuthors={replyAuthors}
							setAnnouncementToDelete={setAnnouncementToDelete}
							setDeleteAnnouncementModalShow={
								setDeleteAnnouncementModalShow
							}
							setPrevThreadPage={setPrevThreadPage}
							switchEditState={switchEditState}
						/>
						{announcementReplies?.length > 0 ? (
							<Pagination
								setContentChanged={setRepliesChanged}
								threadpage={threadpage}
								numberOfThreadPages={numberOfThreadPages}
								showReplies={showReplies}
								type="reply"
							/>
						) : null}
					</Modal.Body>
					{(!cantPostComments && !announcement?.locked) ||
					currentUserData?.isStaff ? (
						<Modal.Footer
							className="mx-auto"
							style={{ margin: "0 auto" }}>
							<span style={{ margin: "0 auto" }}>
								<textarea
									// @ts-ignore
									type="text"
									placeholder="Write a reply to this announcement"
									className="form form-control thread-form"
									style={{ width: "90%" }}
									value={announcementReply || ""}
									onChange={(e) =>
										setAnnouncementReply(e.target.value)
									}
								/>
								<br />
								<div className="thread-footer">
									<Button
										className="btn-primary__special"
										style={{
											position: "relative",
											left: "0",
										}}
										disabled={lockReply}
										// send a reply to a comment in the thread
										onClick={() => {
											const announcementID =
												announcement?._id;
											if (
												announcementReply !== "" &&
												!currentUserData?.mainRoles?.includes(
													UserConstants.Punishments
														.MUTED
												)
											) {
												setLockReply(true);
												createAnnouncementReply(
													announcementID,
													{
														content:
															removeExcessiveBreaks(
																announcementReply
															),
													}
												)
													.then((response) => {
														setLockReply(false);
														if (response) {
															// add reply to replies array
															setAnnouncementReplies(
																(
																	announcementReplies
																) => [
																	...announcementReplies,
																	response
																		.data
																		.newAnnouncementReply,
																]
															);
															// add user to subscribers array
															if (
																!announcement?.subscribers?.includes(
																	currentUserData._id
																)
															) {
																setAnnouncement(
																	(
																		prevAnnouncement
																	) => {
																		// select the specific announcement
																		if (
																			!prevAnnouncement
																				?.subscribers
																				?.length
																		) {
																			prevAnnouncement.subscribers =
																				[];
																		}
																		prevAnnouncement.subscribers.push(
																			currentUserData._id
																		);
																		return {
																			...prevAnnouncement,
																		}; // Return the updated announcement
																	}
																);
															}
															// update other states
															setReplyAuthors(
																(
																	prevAuthors
																) => [
																	...prevAuthors,
																	currentUserData,
																]
															);
															setAnnouncementReply(
																""
															);
															setShowReplies(
																true
															);
															updateReplyCount(
																"+"
															);
														}
													})
													.catch(() =>
														setLockReply(false)
													);
											} else {
												currentUserData?.mainRoles?.includes(
													UserConstants.Punishments
														.MUTED
												)
													? toast.error(
															"You can't post comments while muted!"
													  )
													: toast.error(
															"New comments can't be empty!"
													  );
											}
										}}>
										Send&nbsp;
										{announcementReply?.length
											? `(${announcementReply?.length})`
											: null}
									</Button>{" "}
									<Button
										disabled={lockReply}
										onClick={() => {
											const type = "announcement";
											const force =
												announcement?.subscribers?.includes(
													currentUserData?._id
												)
													? "unsubscribe"
													: "subscribe";
											setLockReply(true);
											// backend request
											subscribeToThread(
												announcement?._id,
												force,
												type
											)
												.then(() => {
													setLockReply(false);
													setAnnouncement(
														(prevAnnouncement) => {
															// Add or remove currentUserData._id based on the value of "force"
															const updatedSubscribers =
																force ===
																"subscribe"
																	? [
																			...(prevAnnouncement?.subscribers ||
																				[]),
																			currentUserData._id,
																	  ] // Add to followers array
																	: prevAnnouncement?.subscribers?.filter(
																			(
																				id
																			) =>
																				id !==
																				currentUserData._id
																	  ); // Remove from followers array
															return {
																...(prevAnnouncement ||
																	[]),
																subscribers:
																	updatedSubscribers,
															};
														}
													);
												})
												.catch(() =>
													setLockReply(false)
												);
										}}
										style={{
											position: "relative",
											left: "0",
										}}>
										{announcement?.subscribers?.includes(
											currentUserData?._id
										)
											? "Unsubscribe"
											: "Subscribe"}
									</Button>{" "}
									<Button
										onClick={() =>
											setAnnouncementModalShow(false)
										}
										style={{
											position: "relative",
											left: "0",
										}}>
										Close
									</Button>
									{currentUserData?.isAdmin ? (
										<>
											{" "}
											<Button
												onClick={handleAnnouncementLock}
												className={`btn-primary${
													announcement?.locked
														? "__special"
														: "__danger"
												}`}
												style={{
													position: "relative",
													left: "0",
												}}>
												{announcement?.locked
													? "Unlock"
													: "Lock"}
											</Button>
										</>
									) : null}
								</div>
							</span>
						</Modal.Footer>
					) : (
						<Modal.Footer
							className="mx-auto"
							style={{ margin: "0 auto" }}>
							<span style={{ margin: "0 auto" }}>
								{currentUserData?._id &&
									!announcement?.locked && (
										<>
											<Button
												disabled={lockReply}
												onClick={() => {
													const type = "announcement";
													const force =
														announcement?.subscribers?.includes(
															currentUserData?._id
														)
															? "unsubscribe"
															: "subscribe";
													setLockReply(true);
													// backend request
													subscribeToThread(
														announcement?._id,
														force,
														type
													)
														.then(() => {
															setLockReply(false);
															setAnnouncement(
																(
																	prevAnnouncement
																) => {
																	// Add or remove currentUserData._id based on the value of "force"
																	const updatedFollowers =
																		force ===
																		"subscribe"
																			? [
																					...prevAnnouncement?.subscribers,
																					currentUserData._id,
																			  ] // Add to followers array
																			: prevAnnouncement?.subscribers?.filter(
																					(
																						id
																					) =>
																						id !==
																						currentUserData._id
																			  ); // Remove from followers array
																	return {
																		...prevAnnouncement,
																		subscribers:
																			updatedFollowers,
																	};
																}
															);
														})
														.catch(() =>
															setLockReply(false)
														);
												}}
												style={{
													position: "relative",
													left: "0",
												}}>
												{announcement?.subscribers?.includes(
													currentUserData?._id
												)
													? "Unsubscribe"
													: "Subscribe"}
											</Button>{" "}
										</>
									)}
								<Button
									onClick={() =>
										setAnnouncementModalShow(false)
									}
									style={{ position: "relative", left: "0" }}>
									Close
								</Button>
								{currentUserData?.isAdmin ? (
									<>
										{" "}
										<Button
											onClick={handleAnnouncementLock}
											className={`btn-primary${
												announcement?.locked
													? "__special"
													: "__danger"
											}`}
											style={{
												position: "relative",
												left: "0",
											}}>
											{announcement?.locked
												? "Unlock"
												: "Lock"}
										</Button>
									</>
								) : null}
							</span>
						</Modal.Footer>
					)}
				</div>
			</Modal>
		);
	};

	return (
		<Container className="members-header mt-4">
			{/* if there is no announcement found or the query is still in progress, show a loading spinner */}
			{!announcement || !author ? (
				<div className="container mt-4">
					<div className="row mx-auto">
						<div
							className="d-flex justify-content-center"
							id="loading-spinner">
							<Spinner animation="grow" variant="primary" />
						</div>

						{/* display "no announcement found" message if announcement is not found */}
						{announcementMessage && !announcement && (
							<div className="col-12">
								<div
									className="card"
									id="infoCard"
									style={{ textAlign: "center" }}>
									<div className="card-body" id="infoCard">
										<h2 className="card-title">
											{announcementMessage}
										</h2>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			) : (
				<>
					{/* display the announcement */}
					<div className="container mt-4">
						<div className="card">
							<div
								className="card-header"
								style={{
									overflow: "hidden",
									whiteSpace: "nowrap",
									textOverflow: "ellipsis",
								}}>
								<div
									style={{
										float: "left",
										height: "37px",
									}}>
									<span
										style={{
											borderRadius: "50%",
											borderWidth: "3px",
											borderStyle: "solid",
											borderColor: "white",
											position: "absolute",
											top: "5px",
											left: "8px",
											width: "44px",
											height: "44px",
											overflow: "hidden",
											display: "inline-block",
										}}>
										<img
											src={
												author?.avatar
													? author.avatar
													: props.images.defaultAvatar
											}
											onError={(e) => {
												// @ts-ignore
												e.target.src =
													props.images.defaultAvatar;
											}}
											alt="Author Avatar"
											style={{
												objectFit: "cover",
												objectPosition: "center",
												height: "100%",
												width: "100%",
											}}
										/>
									</span>
									<span
										className="align-middle"
										style={{
											position: "relative",
											left: "44px",
											top: "4px",
											display: "inline-block",
										}}>
										<a
											title={author?.username}
											href={`/users/${announcement?.author}`}>
											{author?.username}
										</a>
									</span>
								</div>
							</div>

							<div className="card-body">
								{/* all buttons */}
								<div className="thread-icon-container">
									<OverlayTrigger
										delay={{ show: 210, hide: 0 }}
										placement="top"
										overlay={
											<Tooltip id="replies">
												Replies
											</Tooltip>
										}
										popperConfig={{
											modifiers: [
												{
													name: "offset",
													options: {
														offset: [0, -2],
													},
												},
											],
										}}>
										<span className="align-middle thread-number">
											{/* replycount for comments */}
											{announcement.replyCount
												? announcement.replyCount -
												  (announcement?.deleted_replies
														?.length || 0)
												: announcement?.replies
														?.length -
														(announcement
															?.deleted_replies
															?.length || 0) || 0}
										</span>
									</OverlayTrigger>
									<OverlayTrigger
										delay={{ show: 210, hide: 0 }}
										placement="top"
										overlay={
											<Tooltip id="openThread">
												Open thread
											</Tooltip>
										}
										popperConfig={{
											modifiers: [
												{
													name: "offset",
													options: {
														offset: [0, 15],
													},
												},
											],
										}}>
										<span className="align-middle">
											<a
												onClick={() => {
													setAnnouncementModalShow(
														true
													);
												}}
												href={"#!"}
												className="material-symbols-outlined thread-icon blue">
												comment
											</a>
										</span>
									</OverlayTrigger>
									<OverlayTrigger
										delay={{ show: 210, hide: 0 }}
										placement="top"
										overlay={
											<Tooltip id="copyLink">
												Copy link
											</Tooltip>
										}
										popperConfig={{
											modifiers: [
												{
													name: "offset",
													options: {
														offset: [0, 15],
													},
												},
											],
										}}>
										{/* copy announcement link */}
										<span className="align-middle">
											<a
												href={`#!`}
												onClick={() => {
													toast.success(
														"Copied announcement link!"
													);
													navigator.clipboard.writeText(
														window.location.origin +
															window.location
																.pathname
													);
												}}>
												<span className="material-symbols-outlined thread-icon">
													content_copy
												</span>
											</a>
										</span>
									</OverlayTrigger>
									{/* button to delete an announcement */}
									{(currentUserData?.isAdmin ||
										currentUserData?._id ===
											announcement?.author) && (
										<>
											<OverlayTrigger
												delay={{ show: 210, hide: 0 }}
												placement="top"
												overlay={
													<Tooltip id="delete">
														Delete
													</Tooltip>
												}
												popperConfig={{
													modifiers: [
														{
															name: "offset",
															options: {
																offset: [0, 15],
															},
														},
													],
												}}>
												<span className="align-middle">
													<a
														href="#!"
														onClick={() => {
															setAnnouncementToDelete(
																announcement?._id
															);
															setDeleteAnnouncementModalShow(
																true
															);
														}}>
														<span className="material-symbols-outlined thread-icon">
															delete
														</span>
													</a>
												</span>
											</OverlayTrigger>
										</>
									)}
									<h3
										className="center-textoutput"
										style={{
											borderRadius: "12px",
											borderStyle: "solid",
											borderWidth: "2px",
											borderColor:
												"rgba(255,255,255, 0.4)",
											padding: "28px",
										}}>
										<u>
											{announcement?.title ||
												"Untitled announcement"}
										</u>
									</h3>
								</div>
								<div>
									{/* remove invalid html tags from content and display it */}
									<div className="center-textoutput">
										<ReadMore
											content={
												trimPollContent(announcement) ||
												"No main content attached."
											}
											document={announcement}
											height={600}
											extendBBCode={true}
										/>
									</div>
								</div>
								{announcement?._id &&
									announcement?.poll !== undefined && (
										<PollChart
											announcement={announcement}
											setAnnouncement={setAnnouncement}
											currentUserData={currentUserData}
											mode="single"
											showVote={true}
										/>
									)}
								<hr />

								<div className="comment-footer">
									<AnnouncementReact
										announcement={announcement}
										setAnnouncement={setAnnouncement}
										single={true}
									/>

									<span className="comment-footer__date">
										Posted on{" "}
										<b>
											{moment(
												announcement?.postDate
											).format(`MMM D, YYYY - h:mm A`)}
										</b>
									</span>
								</div>
							</div>
						</div>
					</div>
				</>
			)}
			{deleteAnnouncementModal()}
			{announcementThreadModal()}
		</Container>
	);
};

const AnnouncementThreadReplies = (props) => {
	const currentUserData = useContext<any>(UserContext);
	const [hasDoneQuery, setHasDoneQuery] = useState<boolean>(false);

	// initiate a fetch of the replies upon render
	useEffect(() => {
		if (!hasDoneQuery) {
			props.setPrevThreadPage(-1);
			props.setRepliesChanged(true);
			setHasDoneQuery(true);
		}
	}, [props, hasDoneQuery]);

	return (
		<>
			{props?.announcementReplies?.length ? (
				<>
					{props.announcementReplies?.map(
						(announcementReply, key) => {
							// define the reply author
							const replyAuthor = props?.replyAuthors?.find(
								(user) => user._id === announcementReply?.author
							);
							// define admin comment authors for perms
							const authorIsAdmin =
								replyAuthor?.mainRoles?.includes(
									UserConstants.MainRoles.ADMIN
								);

							return (
								<div key={announcementReply?._id}>
									{!announcementReply?.editState ? (
										<div
											style={{
												marginBottom: "14px",
												opacity: props.showReplies
													? 1
													: 0,
											}}
											className={`comment hover-colour thread-fade ${
												announcementReply.deleted
													? "deleted-comment"
													: "public-comment"
											} 
										${props.showReplies ? "fade-in-animation" : ""}`}>
											<div>
												<div
													style={{
														float: "right",
													}}>
													{/* display all icons */}
													{
														// edit button
														((currentUserData?.isStaff &&
															!authorIsAdmin) ||
															currentUserData?._id ===
																announcementReply?.author ||
															props.userIsAdmin) &&
															announcementReply?.deleted !==
																true && (
																<OverlayTrigger
																	delay={{
																		show: 210,
																		hide: 0,
																	}}
																	placement="top"
																	overlay={
																		<Tooltip id="editReply">
																			Edit
																			reply
																		</Tooltip>
																	}>
																	<a
																		onClick={() => {
																			props.switchEditState(
																				announcementReply?._id,
																				"reply"
																			);
																		}}
																		href={
																			"#!"
																		}
																		className="material-symbols-outlined thread-icon blue">
																		edit
																	</a>
																</OverlayTrigger>
															)
													}
													{
														// delete buttons
														(currentUserData?.isStaff &&
															!authorIsAdmin) ||
														announcementReply?.author ===
															currentUserData?._id ||
														currentUserData?.isAdmin ? (
															// delete reply if public or private
															announcementReply.deleted ===
															false ? (
																<OverlayTrigger
																	delay={{
																		show: 210,
																		hide: 0,
																	}}
																	placement="top"
																	overlay={
																		<Tooltip id="deleteReply">
																			Delete
																			reply
																		</Tooltip>
																	}>
																	<a
																		onClick={() => {
																			props.setAnnouncementModalShow(
																				false
																			);
																			props.setDeleteAction(
																				"delete"
																			);
																			props.setAnnouncementToDelete(
																				announcementReply?._id
																			);
																			props.setDeleteAnnouncementModalShow(
																				true
																			);
																		}}
																		href={
																			"#!"
																		}
																		className="material-symbols-outlined thread-icon">
																		delete
																	</a>
																</OverlayTrigger>
															) : (
																// OBLITERATE reply if admin
																<OverlayTrigger
																	delay={{
																		show: 210,
																		hide: 0,
																	}}
																	placement="top"
																	overlay={
																		<Tooltip id="obliterate">
																			OBLITERATE
																		</Tooltip>
																	}>
																	<a
																		onClick={() => {
																			props.setAnnouncementModalShow(
																				false
																			);
																			props.setAnnouncementToDelete(
																				announcementReply?._id
																			);
																			props.setDeleteAction(
																				"obliterate"
																			);
																			props.setDeleteAnnouncementModalShow(
																				true
																			);
																		}}
																		href={
																			"#!"
																		}
																		className={`material-symbols-outlined thread-icon ${
																			!currentUserData.isAdmin &&
																			"hidden"
																		}`}>
																		local_fire_department
																	</a>
																</OverlayTrigger>
															)
														) : null
													}
												</div>
												{/* author avatar */}
												<span
													className="comment__icon"
													style={{
														borderColor:
															replyAuthor?.icon_outline,
														boxShadow:
															replyAuthor?.icon_glow
																? `0 0 4px 2px ${replyAuthor?.icon_glow}`
																: "none",
													}}>
													<img
														alt="Author Avatar"
														className="comment__icon__image"
														onError={(e) => {
															// @ts-ignore
															e.target.src =
																props.images?.defaultAvatar;
														}}
														src={
															replyAuthor?.avatar
																? replyAuthor?.avatar
																: props.images
																		?.defaultAvatar
														}
													/>
												</span>
												{/* author name */}
												<span className="comment__commenter-name">
													<a
														title={
															replyAuthor?.username
														}
														href={`/users/${announcementReply?.author}`}>
														{replyAuthor?.username}
													</a>
												</span>
												<hr
													style={{
														margin: "4px 7px 20px 7px",
													}}
												/>
											</div>
											{/* comment + timestamp */}
											<div className="comment__comment-body">
												<ReadMore
													content={
														announcementReply?.content
													}
													document={announcementReply}
												/>
												<hr
													style={{
														marginBottom: "-16px",
													}}
												/>
											</div>
											<div className="comment__comment-timestamp">
												Posted on{" "}
												<b>
													{moment(
														announcementReply?.postDate
													).format(`
									MMM D, YYYY - h:mm A
								`)}
												</b>
											</div>
											<div className="comment__comment-timestamp__extra">
												<b>
													{moment(
														announcementReply?.postDate
													).format(`
									MMM D
								`)}
												</b>
											</div>
										</div>
									) : (
										<div>
											<div
												style={{
													marginBottom: "16px",
												}}>
												<span className="save-edit-comment">
													<OverlayTrigger
														delay={{
															show: 130,
															hide: 0,
														}}
														placement="top"
														overlay={
															<Tooltip id="cancel">
																Cancel
															</Tooltip>
														}>
														<a
															onClick={() => {
																props.switchEditState(
																	announcementReply?._id
																);
																toast.warn(
																	"Any changes made to this comment will be discarded upon (comment) page reload."
																);
															}}
															href={"#!"}
															className="material-symbols-outlined thread-icon blue"
															style={{
																paddingTop:
																	"3.5px",
																paddingLeft:
																	"2px",
															}}>
															close
														</a>
													</OverlayTrigger>
													<OverlayTrigger
														delay={{
															show: 130,
															hide: 0,
														}}
														placement="top"
														overlay={
															<Tooltip id="saveChanges">
																Save changes
															</Tooltip>
														}>
														<a
															onClick={() => {
																if (
																	announcementReply?.content !==
																	props
																		?.controlAnnouncementReplies[
																		key
																	]?.content
																) {
																	if (
																		announcementReply.content
																	) {
																		// switch the edit state back to normal
																		props.switchEditState(
																			announcementReply?._id
																		);
																		// edit the comment
																		editAnnouncementReply(
																			props
																				.announcement
																				?._id,
																			announcementReply?._id,
																			{
																				content:
																					announcementReply?.content,
																			}
																		).then(
																			() => {
																				// make sure the edit state remains false, add the "edited" tag
																				const updatedReplies =
																					props.announcementReplies.map(
																						(
																							reply,
																							index
																						) => {
																							if (
																								index ===
																								key
																							) {
																								return {
																									...announcementReply,
																									edited: true,
																									editState:
																										false,
																								};
																							}
																							return reply;
																						}
																					);
																				props.setAnnouncementReplies(
																					updatedReplies
																				);
																				// update state to account for new changes
																				props.setControlAnnouncementReplies(
																					(
																						prevState
																					) => {
																						return {
																							...prevState,
																							[key]: announcementReply,
																						};
																					}
																				);
																			}
																		);
																	} else {
																		toast.error(
																			"Comment can't be empty"
																		);
																	}
																} else {
																	toast.error(
																		"No changes were made"
																	);
																}
															}}
															href={"#!"}
															className="material-symbols-outlined thread-icon blue"
															style={{
																paddingTop:
																	"3.5px",
																paddingRight:
																	"3px",
															}}>
															save
														</a>
													</OverlayTrigger>
												</span>
												<textarea
													// @ts-ignore
													type="text"
													className="form form-control edit-comment__announcement"
													value={
														announcementReply?.content
													}
													onChange={(e) => {
														const updatedReplies = [
															...props.announcementReplies,
														];
														updatedReplies[
															key
														].content =
															e.target.value;
														props.setAnnouncementReplies(
															updatedReplies
														);
													}}></textarea>
											</div>
										</div>
									)}
								</div>
							);
						}
					)}
				</>
			) : null}
		</>
	);
};

export default Announcement;
