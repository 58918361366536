import { toast } from "react-toastify";

// togle the edit state of a comment on/off
export const switchEditState = (commentId, setComments) => {
	setComments((prevComments) =>
		prevComments.map((comment) =>
			comment._id === commentId
				? { ...comment, editState: !comment.editState }
				: comment
		)
	);
};

// handling like/dislike
export const handleReactComment = ({
	mode,
	comment,
	currentUserData,
	reactComment,
	setComments,
	origin,
}) => {
	// check if the mode is like or dislike
	if (["dislike", "like"].includes(mode) && comment) {
		// return a toast error if the author is the current user
		if (comment?.author === currentUserData?._id)
			return toast.error("You cannot react to your own comment!");
		// make the backend call
		const commentID = comment?._id;
		reactComment(commentID, {
			reaction: mode,
			origin: origin?._id,
		}).then(() => {
			setComments((prevState) =>
				prevState.map((prevComment) => {
					if (prevComment._id === comment._id) {
						let updatedComment = { ...prevComment }; // Create a copy of the previous state

						if (mode === "like") {
							if (
								comment?.user_likes?.includes(
									currentUserData?._id
								)
							) {
								// If the user already liked, remove the like
								updatedComment.user_likes =
									comment.user_likes.filter(
										(id) => id !== currentUserData?._id
									);
							} else {
								// If the user didn't like, remove dislike (if exists) and add like
								updatedComment.user_dislikes =
									comment.user_dislikes?.filter(
										(id) => id !== currentUserData?._id
									);
								updatedComment.user_likes.push(
									currentUserData?._id
								);
							}
						} else {
							// Assuming mode is "dislike"
							if (
								comment?.user_dislikes?.includes(
									currentUserData?._id
								)
							) {
								// If the user already disliked, remove the dislike
								updatedComment.user_dislikes =
									comment.user_dislikes.filter(
										(id) => id !== currentUserData?._id
									);
							} else {
								// If the user didn't dislike, remove like (if exists) and add dislike
								updatedComment.user_likes =
									comment.user_likes?.filter(
										(id) => id !== currentUserData?._id
									);
								updatedComment.user_dislikes.push(
									currentUserData?._id
								);
							}
						}
						return updatedComment; // Return the updated announcement
					}
					return prevComment;
				})
			);
		});
	}
};
