// removes excessive breaks at the start and end of any message
const removeExcessiveBreaks = (content) => {
	if (!content) {
		return null;
	}

	// Remove excessive breaks at the end
	let trimmedContent = content.trim();
	let lastCharacter = trimmedContent.slice(-4); // Check the last 4 characters

	while (lastCharacter === "<br>") {
		trimmedContent = trimmedContent.slice(0, -4); // Remove the last "<br>" tag
		lastCharacter = trimmedContent.slice(-4); // Update the lastCharacter value
	}

	// Remove excessive breaks at the beginning
	let firstCharacter = trimmedContent.slice(0, 4); // Check the first 4 characters

	while (firstCharacter === "<br>") {
		trimmedContent = trimmedContent.slice(4); // Remove the first "<br>" tag
		firstCharacter = trimmedContent.slice(0, 4); // Update the firstCharacter value
	}

	return trimmedContent;
};

export default removeExcessiveBreaks;
