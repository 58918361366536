//! download files
const downloadFile = (blob, level) => {
	// Create a download link
	const a = document.createElement("a");
	a.style.display = "none";
	// Set the Blob as the data source
	a.href = URL.createObjectURL(blob);
	// Set the desired file name for the download
	a.download = `${level?.name}.mb64`; // Change this to your desired file name
	// Add the link to the DOM and trigger a click to start the download
	document.body.appendChild(a);
	a.click();
	// Remove the link and revoke the Blob URL when done
	document.body.removeChild(a);
	URL.revokeObjectURL(a.href);
};

export default downloadFile;
