import React, { useContext } from "react";
import { useHistory } from "react-router-dom";

// Import UserContext that contains the latest user information from the database
import { UserContext } from "../../App";

// page styles
import "./style.scss";

/**
 * The footer that appears at the bottom of every page.
 *
 * @returns The footer for the site.
 */
const Footer = () => {
	// Obtain the current user data (from React UserContext), passed from the App component
	const currentUserData = useContext<any>(UserContext);
	// override href behaviour
	const history = useHistory();
	const pushHistory = (e) => {
		e.preventDefault();
		// uhhh... yea
		const fullUrl = e.target.href;
		const origin = e.target.host;
		// construct href
		const href = fullUrl.split(origin).slice(1).join("");
		// navigate
		history.push(href);
		window.scrollTo(0, 0);
	};

	return (
		<div style={{ overflowX: "hidden" }}>
			<footer className={`page-footer font-small blue pt-4`}>
				<div className="container-fluid text-center text-md-left">
					<div className="row">
						<div className="col-md-6 mt-md-0 mt-3">
							<h5 className="text-uppercase LSS-info">
								Level Share Square
							</h5>
							<p className="LSS-info">
								A community for level designers of games!
								<br />
								Level Share Square is not affiliated with any of
								the respective IPs of its games nor with any of
								its user-generated content.
								<br />
								<br />
								<span className="gray">
									&copy; {new Date().getFullYear()} Level
									Share Square
								</span>
							</p>
						</div>

						<div className="col-md-3 mb-md-0 mb-3">
							<h5 className="text-uppercase">Pages</h5>

							<ul className="list-unstyled">
								<li>
									<a
										onClick={(e) => pushHistory(e)}
										href="/levels">
										Browse Levels
									</a>
								</li>
								{/* show link to user level list if user is logged in  */}
								{currentUserData && (
									<li>
										<a
											onClick={(e) => pushHistory(e)}
											href={`/levels/user/${currentUserData?._id}`}>
											My Levels
										</a>
									</li>
								)}
								<li>
									<a
										onClick={(e) => pushHistory(e)}
										href="/games">
										Play Games
									</a>
								</li>
								{/* show link to profile if user is logged in */}
								{currentUserData ? (
									<li>
										<a
											onClick={(e) => pushHistory(e)}
											href={`/users/${currentUserData?._id}`}>
											My Profile
										</a>
									</li>
								) : (
									// otherwise, show link to login or register
									<li>
										<a
											onClick={(e) => pushHistory(e)}
											href={`/auth?url=${window.location.pathname}`}>
											Login / Register
										</a>
									</li>
								)}
							</ul>
						</div>

						<div className="col-md-3 mb-md-0 mb-3">
							<h5 className="text-uppercase">Support</h5>

							<ul className="list-unstyled">
								<li>
									<a
										onClick={(e) => pushHistory(e)}
										href="/tos">
										Terms of Service
									</a>
								</li>
								<li>
									<a
										onClick={(e) => pushHistory(e)}
										href="/faq">
										FAQ
									</a>
								</li>
								<li>
									<a
										onClick={(e) => pushHistory(e)}
										href="/guidelines">
										Guidelines
									</a>
								</li>
								<li>
									<a
										onClick={(e) => pushHistory(e)}
										href="/about">
										About Us
									</a>
								</li>
								<li>
									<a
										onClick={(e) => pushHistory(e)}
										href="/contact">
										Contact Us
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</footer>
		</div>
	);
};

export default Footer;
