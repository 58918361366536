// react imports
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import { requestPasswordReset } from "../../../api";
import axios from "axios";

// types
import { FormData } from "./types";

/**
 * Request password reset form.
 *
 * @param {*} props
 * @returns
 */
const ForgotPass = (props) => {
	// state info
	const [formData, setFormData] = useState<FormData>({
		username: "",
		email: "",
		verifiedCaptcha: false,
	});

	useEffect(() => {
		document.title = "Forgot Password - Level Share Square";
	}, []);

	// Boolean to prevent the user from making another password reset query if there is already one ongoing
	let canSendQuery = true;

	// ---------------------- //
	// Handle form submission //
	// ---------------------- //
	const handleSubmit = async (e) => {
		// prevent form from submitting as normal
		e.preventDefault();

		if (!canSendQuery)
			return toast.error("There is already an ongoing request going.");

		// check if forms were entered
		if (formData.username && formData.email && formData.verifiedCaptcha) {
			canSendQuery = false;
			requestPasswordReset({
				username: formData.username,
				email: formData.email,
			})
				.then((res) => {
					toast.success(res.data);
					window.setTimeout(function () {
						// Move to the next page in the process of resetting password
						window.location.replace("/resetpass");
					}, 3000);
				})
				.catch((error) => {
					toast.error(error.response.data);
					canSendQuery = true;
				});
		} else toast.error("Please fill in all the forms before proceeding.");
	};

	// handle changes in filling out form
	const handleChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	// This fires when the ReCAPTCHA has successfully been ticked.
	async function handleCaptchaUpdate(value) {
		// Communicate to the backend to perform a second check
		await axios
			.get("/api/recaptcha/", {
				params: { token: value },
			})
			.then((response) => {
				// If True is returned, ReCAPTCHA validation has succeeded, and the registration form can be submitted
				if (response.data === true) {
					setFormData({ ...formData, verifiedCaptcha: true });
				}
			});
	}

	return (
		<>
			<div className="container mt-4 align-mid">
				<div className="card mx-auto">
					<div className="card-header">
						<span
							className="align-text-middle"
							style={{
								fontSize: "36px",
								top: "9px",
							}}>
							Forgot Password
						</span>
						<p className="lead">
							It looks like you've forgotten your password. No
							worries! You can set up a new one after filling out
							this form.
						</p>
					</div>
					<div className="card-body">
						<form onSubmit={handleSubmit}>
							<div className="form-group">
								<label htmlFor="username">Username</label>
								<input
									type="text"
									className="form-control"
									id="username"
									name="username"
									maxLength={32}
									value={formData.username}
									onChange={handleChange}
								/>
							</div>
							<div className="form-group">
								<label htmlFor="email">Email</label>
								<input
									type="email"
									className="form-control"
									id="email"
									name="email"
									maxLength={100}
									value={formData.email}
									onChange={handleChange}
								/>
							</div>
							<div className="form-group">
								<label>
									<ReCAPTCHA
										sitekey="6LezV9ElAAAAAEm93SBxKkO6-ZFiKaewaqfTWHUt"
										onChange={handleCaptchaUpdate}
									/>
								</label>
							</div>
							<br />
							<button type="submit" className="btn btn-primary">
								Continue
							</button>
						</form>
					</div>
				</div>
				<br />
			</div>
		</>
	);
};

export default ForgotPass;
