import React, { useEffect, useRef, useState } from "react";
import BBCode from "@bbob/react/es/Component";
import BBCodeParser from "../../functions/applyBBCode";
import Linkify from "react-linkify";
import removeExcessiveBreaks from "../../functions/removeExcessiveBreaks";
import { AllowedBBCodeTags } from "../../constants";

const ReadMore = (props) => {
	const document = props?.document;
	const content = props?.content || props?.description;
	const maxHeight = props?.height || 300;
	const extendBBCode = props?.extendBBCode || false;
	const animationTime = props?.animationTime || 1200;
	const [limitHeight, setLimitHeight] = useState<boolean | null>(null);
	const [disabled, setDisabled] = useState<boolean>(false);
	const elementRef = useRef<any>(null);
	const [initialHeight, setInitialHeight] = useState<number>(999999);

	useEffect(() => {
		// Access the current property of the ref to get the DOM node
		const element = elementRef.current;
		// Check if the ref is assigned to an element
		if (element) {
			// Get the height of the element
			const height = element?.clientHeight;
			setInitialHeight(height);
			if (height - 50 > maxHeight) setLimitHeight(true);
			else setLimitHeight(null);
		}
	}, [maxHeight]);

	const handleClick = (e) => {
		e.preventDefault();
		// invert state
		setLimitHeight((prev) => {
			return !prev;
		});
		setDisabled(true);
		setTimeout(() => setDisabled(false), animationTime);
	};
	return (
		<div id={document?._id}>
			<div
				ref={elementRef}
				style={{
					transition: `max-height ${animationTime}ms ease-in-out`,
					maxHeight: limitHeight
						? maxHeight + "px"
						: initialHeight + "px", // force smooth animation using px
					overflow: "hidden",
					width: "100%",
					display: "inline-block", // properly render "edited"
				}}>
				<Linkify
					componentDecorator={(decoratedHref, decoratedText, key) => (
						<a
							href={decoratedHref}
							target="_blank" // open links in a new tab
							rel="noopener noreferrer"
							key={key}>
							{decoratedText}
						</a>
					)}>
					<BBCode
						plugins={[BBCodeParser()]}
						options={{
							onlyAllowTags: extendBBCode
								? AllowedBBCodeTags.BBCODE_EXTENDED_TAGS
								: AllowedBBCodeTags.BBCODE_GLOBAL_TAGS,
						}}>
						{removeExcessiveBreaks(content)}
					</BBCode>
				</Linkify>
				{document?.edited && (
					<span
						className="gray"
						style={{
							fontSize: "11px",
						}}>
						&nbsp;&nbsp;(Edited)
					</span>
				)}
			</div>

			{limitHeight !== null && (
				<div
					style={{
						width: "100%",
						display: "flex",
						justifyContent: "center",
					}}>
					<br />
					<button
						className="btn-primary"
						disabled={disabled}
						style={{
							borderRadius: "14px",
							padding: "2px 8px 2px 8px ",
						}}
						onClick={(e) => handleClick(e)}>
						{limitHeight ? "Read more..." : "Collapse"}
					</button>
				</div>
			)}
		</div>
	);
};

export default ReadMore;
