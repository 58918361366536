import presetHTML5 from "@bbob/preset-html5";

const tagAttr = (style) => {
	return {
		attrs: {
			style,
			className: style?.className || "",
			onClick: style?.onClick || null,
			message: style?.dataMessage || null,
		},
	};
};
const BBCodeParser = presetHTML5.extend((tags) => {
	return {
		...tags,
		b: (...args) => ({
			...tags.b(...args),
			...tagAttr({
				fontWeight: "bold",
			}),
		}),
		i: (...args) => ({
			...tags.i(...args),
			...tagAttr({
				fontStyle: "italic",
			}),
		}),
		u: (...args) => ({
			...tags.u(...args),
			...tagAttr({
				textDecoration: "underline",
			}),
		}),
		s: (...args) => ({
			...tags.s(...args),
			...tagAttr({
				textDecoration: "line-through",
			}),
		}),
		color: (attrs, ...children) => ({
			...tags.color(attrs, ...children),
			...tagAttr({
				// @ts-ignore
				color: Object?.values(Object?.values(attrs)?.[1])?.[0],
			}),
		}),
		colour: (attrs, ...children) => ({
			...tags.color(attrs, ...children),
			...tagAttr({
				// @ts-ignore
				color: Object?.values(Object?.values(attrs)?.[1])?.[0],
			}),
		}),
		tooltip: (attrs, ...children) => ({
			...tags.color(attrs, ...children),
			...tagAttr({
				className: "BBCode-tooltip",
				backgroundColor: "black",
				color: "white",
				// @ts-ignore
				dataMessage: Object?.values(Object?.values(attrs)?.[1])?.[0],
			}),
		}),
		spoiler: (attrs, ...children) => ({
			...tags.style(attrs, ...children),
			...tagAttr({
				borderRadius: "4px",
				cursor: "pointer",
				className: "unselectable spoiler",
				onClick: (e) => {
					e.target.classList.toggle("spoiler");
					e.target.classList.toggle("spoiler-active");
				},
			}),
		}),
		h1: (...args) => ({
			...tags.style(...args),
			...tagAttr({
				fontSize: "2em",
			}),
		}),
		h2: (...args) => ({
			...tags.style(...args),
			...tagAttr({
				fontSize: "1.5em",
			}),
		}),
		h3: (...args) => ({
			...tags.style(...args),
			...tagAttr({
				fontSize: "1.2em",
			}),
		}),
		vibrant: (attrs, ...children) => ({
			...tags.style(attrs, ...children),
			...tagAttr({
				className: "vibrant",
			}),
		}),
		img: (attrs, ...children) => {
			var src = attrs?.content?.[0]?.toString();
			if (src) attrs.content[0] = ` `;
			return {
				...tags.style(attrs, ...children),
				...tagAttr({
					backgroundImage: `url(${src})`,
					className: "BBCode-img",
				}),
			};
		},
	};
});

export default BBCodeParser;
