import React, {
	useState,
	useEffect,
	useContext,
	useRef,
	useCallback,
	lazy,
	Suspense,
} from "react";
import { useLocation, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";

// Import UserContext that contains the latest user information from the database
import { UserContext } from "../../App";

import {
	getAnnouncementsPage,
	createAnnouncement,
	editAnnouncement,
	deleteAnnouncement,
	getAnnouncementReplyPage,
	createAnnouncementReply,
	editAnnouncementReply,
	deleteAnnouncementReply,
	obliterateAnnouncementReply,
	subscribeToThread,
	getOnlineUsers,
	getHomepage,
	toggleAnnouncementLock,
} from "../../api";
import Pagination from "../Pagination/Pagination";

// files
import smcPreview from "./../../assets/images/SMCPreview.png";
import sm127Preview from "./../../assets/images/SM127Preview.webp";
import yfsPreview from "./../../assets/images/YFSPreview.png";

// page styles
import "./style.scss";

// react-bootstrap components used
import {
	Spinner,
	Modal,
	Button,
	Tooltip,
	OverlayTrigger,
} from "react-bootstrap";
import {
	PollChart,
	processPoll,
	trimPollContent,
} from "../Announcements/Polls";
import removeExcessiveBreaks from "../../functions/removeExcessiveBreaks";
import { mapHighestRole } from "../../functions/styleAppliers";
import AnnouncementReact from "../Announcements/AnnouncementReaction";
import ReadMore from "../Insertions/ReadMore";

// constants
import { UserConstants } from "../../constants";

// types
import { OnlineUsersCardPosition } from "./types";

// lazy loaded components
const HoverUserCard = lazy(() => import("../HoverUserCard/HoverUserCard"));

// define query for pagination
const useQuery = () => {
	return new URLSearchParams(useLocation().search);
};

// keep track of index of old hint in hints array
let oldHintIndex = -1;

/**
 * The home page of the site, appearing differently depending on the
 * user's session status.
 *
 * @returns The site's home page.
 */
const HomePage = (props) => {
	// store the latest user data from the UserContext component
	const currentUserData = useContext<any>(UserContext);

	// state info
	const [announcements, setAnnouncements] = useState<any[]>([]);
	const [controlAnnouncements, setControlAnnouncements] = useState<any[]>([]);
	const [announcementsChanged, setAnnouncementsChanged] =
		useState<boolean>(true);
	const [numberOfPages, setNumberOfPages] = useState<number>(0);
	const [previousPage, setPreviousPage] = useState<any>(null);
	const [isQuerying, setIsQuerying] = useState<boolean>(false);

	const [authors, setAuthors] = useState<any[]>([]);

	// homepage data
	const [allHints, setAllHints] = useState<any[]>([]);
	const [latestLSSYTVideo, setLatestLSSYTVideo] = useState<any>(null);
	const [xpMultiplier, setXpMultiplier] = useState<number>(1);
	const [shimflakeMultiplier, setShimflakeMultiplier] = useState<number>(1);

	const [lockAnnouncement, setLockAnnouncement] = useState<boolean>(false);

	const [announcementTitle, setAnnouncementTitle] = useState<string>(
		sessionStorage.getItem(`anntitle`) || "",
	);
	const [announcementContent, setAnnouncementContent] = useState<string>(
		sessionStorage.getItem(`announcement`) || "",
	);
	const [announcementMessage, setAnnouncementMessage] = useState<string>("");
	const [targetAnnouncement, setTargetAnnouncement] = useState<any>("");
	const [deleteAnnouncementModalShow, setDeleteAnnouncementModalShow] =
		useState<boolean>(false);
	const [announcementToDelete, setAnnouncementToDelete] =
		useState<string>("");
	const [deleteAction, setDeleteAction] = useState<string>("");

	const [announcementModalShow, setAnnouncementModalShow] =
		useState<boolean>(false);
	const [announcementReplies, setAnnouncementReplies] = useState<any[]>([]);
	const [controlAnnouncementReplies, setControlAnnouncementReplies] =
		useState<any[]>([]);
	const [showReplies, setShowReplies] = useState<boolean>(false);
	const [announcementThreadChanged, setAnnouncementThreadChanged] =
		useState<boolean>(false);
	const [numberOfThreadPages, setNumberOfThreadPages] = useState<number>(0);
	const [announcementReply, setAnnouncementReply] = useState<string>("");
	const [replyAuthors, setReplyAuthors] = useState<any[]>([]);

	const [onlineUsers, setOnlineUsers] = useState<any>([]);
	const [userCount, setUserCount] = useState<number>(0);
	const [allUsers, setAllUsers] = useState<boolean>(false);
	const [showCard, setShowCard] = useState<any>(null);

	// height syncing of who is online & random tip
	const [trackedHeight, setTrackedHeight] = useState<number>(0);
	const [onlineHeight, setOnlineHeight] = useState<number>(0);
	const [stickyButton, setStickyButton] = useState<boolean>(false);
	const heightTrackRef = useRef<any>(null);
	const heightApplyRef = useRef<any>(null);
	const onlineTrackRef = useRef<any>(null);
	const onlineApplyRef = useRef<any>(null);
	const announcementRef = useRef<any>(null);

	// scroll function
	const scrollToRef = (ref) => {
		window.scrollTo({
			top: ref.current.getBoundingClientRect().top + window.scrollY - 100,
			behavior: "smooth",
		});
	};

	// hook initializations
	const history = useHistory();
	const query = useQuery();

	// url parameters
	const page =
		parseInt(query.get("page") ?? "0") > 0
			? parseInt(query.get("page") ?? "0")
			: 1;
	const threadpage =
		parseInt(query.get("threadpage") ?? "0") > 0
			? parseInt(query.get("threadpage") ?? "0")
			: 1;

	// constants
	const cantPostComments =
		!currentUserData?.isStaff &&
		(currentUserData?.mainRoles?.includes(
			UserConstants.Punishments.MUTED,
		) ||
			!currentUserData?.verified ||
			!currentUserData);

	// preset template for polls
	const presetPollContent = `
		$poll{
			"title": "",
			"option1": "",
			"option2": "",
			"option3": "",
			};`;

	useEffect(() => {
		document.title = "Home - Level Share Square";
		if (currentUserData?._id && window.innerHeight <= 900) {
			window.addEventListener("scroll", handleScroll);
			handleScroll();
		}
	}, [currentUserData?._id]);

	//! cleanup function
	useEffect(() => {
		return () => {
			setHintChanged(false);
			setRandomHint(null);
			setAnnouncementsChanged(false);
			setAnnouncements([null]);
			setOnlineUsers(null);
		};
	}, []);

	// render a random hint
	const [randomHint, setRandomHint] = useState<any>("");
	const [hintChanged, setHintChanged] = useState<boolean>(true);

	// fetch all homepage data (e.g. hints, latest lss yt video)
	useEffect(() => {
		if (!allHints?.length)
			getHomepage({
				getYouTubeVideo: currentUserData?._id ? true : false,
			}).then((response) => {
				setXpMultiplier(response?.data?.xpMultiplier || 1);
				setShimflakeMultiplier(
					response?.data?.shimflakeMultiplier || 1,
				);
				if (currentUserData?._id) {
					setAllHints(response?.data?.hints);
					setLatestLSSYTVideo(response?.data?.latestLSSYTVideo);
				}
			});
	}, [allHints, currentUserData?._id]);

	// change the random hint
	useEffect(() => {
		if (hintChanged === true && currentUserData?._id && allHints?.length) {
			setHintChanged(false);
			// fetch the hints

			let randomIndex: number;

			// make sure generated hint is not the same as the old one, if one exists
			do randomIndex = Math.floor(Math.random() * allHints?.length);
			while (randomIndex === oldHintIndex);

			// set newly generated hint as old hint for next time
			oldHintIndex = randomIndex;

			// set a new state using the generated hint
			return setRandomHint(allHints[randomIndex]);
		}
	}, [randomHint, currentUserData?._id, hintChanged, allHints]);

	// retrieve announcements
	useEffect(() => {
		// return if page hasn't changed
		if (page === previousPage) return setAnnouncementsChanged(false);
		// checks if new announcements should be fetched
		if (announcementsChanged && !isQuerying) {
			setIsQuerying(true);
			getAnnouncementsPage({
				page,
			})
				.then((response) => {
					if (response?.data) {
						setPreviousPage(page);
						setAnnouncements(response.data.announcements);
						setAuthors(
							currentUserData?._id &&
								response?.data?.authors?.length
								? [...response?.data?.authors, currentUserData]
								: currentUserData?._id
									? [currentUserData]
									: response?.data?.authors,
						);
						setControlAnnouncements(response.data.announcements);
						setAnnouncementMessage(response.data.message);
						setNumberOfPages(response.data.numberOfPages);
					}
				})
				.finally(() => {
					setAnnouncementsChanged(false);
					setIsQuerying(false);
				});
		}
	}, [announcementsChanged, page, previousPage, isQuerying, currentUserData]);

	// fetch replies with authors belonging to an announcement if "targetannouncement" gets triggered
	useEffect(() => {
		if (targetAnnouncement !== "" && announcementThreadChanged) {
			const announcementID = targetAnnouncement?._id;
			setShowReplies(false);
			getAnnouncementReplyPage(announcementID, { threadpage }).then(
				(response) => {
					if (response?.data) {
						if (
							response?.data?.numberOfPages < threadpage &&
							response?.data?.numberOfPages !== 0
						) {
							query.set("threadpage", "1");
							history.push(`?${query.toString()}`);
							return setAnnouncementThreadChanged(true);
						}
						setAnnouncementReplies(response.data.replies);
						setReplyAuthors(
							currentUserData?._id &&
								response?.data?.authors?.length
								? [...response?.data?.authors, currentUserData]
								: currentUserData?._id
									? [currentUserData]
									: response?.data?.authors,
						);
						setControlAnnouncementReplies(response.data.replies);
						setNumberOfThreadPages(response.data.numberOfPages);
						setShowReplies(true);
					}
				},
			);

			setAnnouncementThreadChanged(false);
		}
	}, [
		targetAnnouncement,
		announcementModalShow,
		announcementThreadChanged,
		threadpage,
		page,
		showReplies,
		currentUserData,
		query,
		history,
	]);

	// function for getting online users
	const fetchOnlineUsers = useCallback(
		(i) => {
			const fetchAndRetry = () => {
				getOnlineUsers(allUsers).then((response) => {
					if (response.status === 200 && response?.data?.count > 1) {
						setOnlineUsers(response.data.onlineUsers);
						setUserCount(response?.data?.count);
					} else if (i < 1) {
						i++;
						// @ts-ignore
						setTimeout(fetchAndRetry(), 6000);
					} else if (response.status === 200) {
						setOnlineUsers(response.data.onlineUsers);
						setUserCount(response?.data?.count);
					} else setOnlineUsers(null);
				});
			};

			if (currentUserData?._id) {
				fetchAndRetry();
			}
		},
		[currentUserData?._id, allUsers],
	);

	// Upon page render, get online users and do so every minute
	useEffect(() => {
		let interval,
			i = 0;

		if (currentUserData?._id) {
			// also trigger shortly after render
			fetchOnlineUsers(i);
			// 60 seconds interval
			interval = setInterval(() => {
				fetchOnlineUsers(i);
			}, 60 * 1000);
		}

		return () => {
			clearInterval(interval);
		};
	}, [fetchOnlineUsers, currentUserData?._id, allUsers]);

	// sticky button for scrolling to announcements
	const handleScroll = () => {
		if (announcementRef.current) {
			// get position
			const refBottom =
				announcementRef.current.getBoundingClientRect().top +
				window.scrollY -
				500;
			// enable/disable the button
			if (refBottom >= window.scrollY) {
				setStickyButton(true);
			} else {
				setStickyButton(false);
			}
		}
	};

	// use ref to track height of elements
	useEffect(() => {
		if (
			(heightTrackRef?.current && heightApplyRef?.current) ||
			(hintChanged === true && currentUserData?._id)
		) {
			const currentHeight = heightTrackRef.current.offsetHeight;
			const titleHeight = onlineTrackRef.current.offsetHeight;
			setTrackedHeight(currentHeight);
			setOnlineHeight(titleHeight);
		}
	}, [hintChanged, currentUserData?._id]);

	// update height of secondary element by means of state update
	useEffect(() => {
		if (
			(heightApplyRef?.current || onlineApplyRef?.current) &&
			currentUserData?._id
		) {
			heightApplyRef.current.style.height = trackedHeight + "px";
			onlineApplyRef.current.style.height =
				trackedHeight - onlineHeight - 45 + "px";
		}
	}, [trackedHeight, onlineHeight, currentUserData?._id]);

	// handle the posting of an announcement
	const handlePostAnnouncement = (e) => {
		// prevent form from submitting as normal
		e.preventDefault();

		// post announcement if user has actually entered one
		if (announcementContent) {
			toast.info("Posting announcement...");
			history.push(props.location.pathname);

			setLockAnnouncement(true);
			createAnnouncement({
				content: removeExcessiveBreaks(announcementContent),
				title: removeExcessiveBreaks(announcementTitle),
			})
				.then((response) => {
					setAnnouncements((prevState) => [
						response.data.newAnnouncement,
						...prevState,
					]);
					setAnnouncementContent("");
					sessionStorage.removeItem("announcement");
					setAnnouncementTitle("");
					sessionStorage.removeItem("anntitle");
					setLockAnnouncement(false);
				})
				.catch(() => setLockAnnouncement(false));
		} else
			toast.error(
				"Please enter an announcement before trying to post it!",
			);
	};

	// confirmation dialog for deleting an announcement
	const deleteAnnouncementModal = () => {
		return (
			<Modal
				show={deleteAnnouncementModalShow}
				onHide={() => {
					setDeleteAnnouncementModalShow(false);
					setDeleteAction("");
				}}
				size="sm"
				aria-labelledby="contained-modal-title-vcenter"
				className="popup-modal"
				centered>
				<span className="modal-fill">
					<Modal.Body className="modal-filter">
						<h4 style={{ textAlign: "center" }}>
							{deleteAnnouncementModalShow
								? `Are you sure you want to ${
										deleteAction || "delete"
									} this ${
										deleteAction !== ""
											? "reply"
											: "announcement"
									}?`
								: "Closing..."}
						</h4>
					</Modal.Body>
					<Modal.Footer className="mx-auto modal-buttons modal-filter">
						<Button
							onClick={() => {
								if (deleteAction === "") {
									toast.info("Deleting announcement...");

									// handle request
									setDeleteAnnouncementModalShow(false);
									deleteAnnouncement(
										announcementToDelete,
									).then(() => {
										setAnnouncements((prevState) => {
											const newAnnouncements =
												prevState.filter(
													(announcement) =>
														announcement._id !==
														announcementToDelete,
												);
											return newAnnouncements;
										});
										setAnnouncementToDelete("");
									});
								} else {
									//! announcementToDelete contains a REPLY whereas targetAnnouncement contains an ANNOUNCEMENT
									if (deleteAction === "delete") {
										deleteAnnouncementReply(
											announcementToDelete,
											targetAnnouncement._id,
										).then((response) => {
											// update the state of the counter by adding "deleted replies" to the array
											setAnnouncements((prevState) => {
												return prevState.map(
													(announcement) => {
														if (
															announcement._id ===
															targetAnnouncement._id
														) {
															const updatedDeletedReplies =
																[
																	...announcement.deleted_replies,
																	response
																		.data
																		.announcementReply
																		._id,
																];
															return {
																...announcement,
																deleted_replies:
																	updatedDeletedReplies,
															};
														}
														return announcement;
													},
												);
											});
										});
									}
									if (deleteAction === "obliterate")
										obliterateAnnouncementReply(
											announcementToDelete,
											targetAnnouncement._id,
										);
									setDeleteAction("");
									setDeleteAnnouncementModalShow(false);
								}
							}}>
							Yes
						</Button>
						<Button
							onClick={() => {
								setDeleteAnnouncementModalShow(false);
								setDeleteAction("");
							}}>
							No
						</Button>
					</Modal.Footer>
				</span>
			</Modal>
		);
	};

	// handle the locking of an announcement
	const handleAnnouncementLock = (announcement) => {
		// toast message
		toast.info(
			announcement?.locked
				? "Unlocking announcement..."
				: "Locking announcement...",
		);
		// api call
		toggleAnnouncementLock(announcement._id).then(() => {
			// update state
			setAnnouncements((prevState) =>
				prevState.map((ann) => {
					// only update target announcement
					if (ann?._id === announcement?._id) {
						return {
							...ann,
							locked: !announcement?.locked,
						};
					}
					// don't update others
					return ann;
				}),
			);
		});
	};

	const announcementThreadModal = () => {
		// define the top announcement
		const announcement = processPoll(
			announcements.find(
				(comment) => comment?._id === targetAnnouncement?._id,
			),
		);

		// and the author
		const announcementAuthor = authors?.find(
			(user) => user._id === targetAnnouncement?.author,
		);

		return (
			<Modal
				show={announcementModalShow}
				onHide={() => setAnnouncementModalShow(false)}
				className="comment-modal"
				size="xl"
				aria-labelledby="contained-modal-title-vcenter"
				centered>
				<div
					className="modal-fill"
					style={{
						width: "100%",
					}}>
					<Modal.Body className="thread-modal-body">
						<div
							className={`comment public-comment__main`}
							style={{
								backgroundColor: "rgb(255,255,255,0.1)",
							}}>
							<div>
								<div>
									<div
										style={{
											position: "absolute",
											top: "6px",
											right: "6px",
										}}>
										{/* display all icons*/}
										{currentUserData?.isAdmin && (
											<OverlayTrigger
												delay={{
													show: 210,
													hide: 0,
												}}
												placement="top"
												overlay={
													<Tooltip id="deleteComment">
														Delete comment
													</Tooltip>
												}>
												<a
													onClick={() => {
														setAnnouncementToDelete(
															targetAnnouncement._id,
														);
														setAnnouncementModalShow(
															false,
														);
														setDeleteAnnouncementModalShow(
															true,
														);
													}}
													href={"#!"}
													className="material-symbols-outlined thread-icon">
													delete
												</a>
											</OverlayTrigger>
										)}
									</div>
									{/* author avatar */}
									<span
										className="comment__icon"
										style={{
											borderColor:
												announcementAuthor?.icon_outline,
											boxShadow:
												announcementAuthor?.icon_glow
													? `0 0 4px 2px ${announcementAuthor?.icon_glow}`
													: "none",
										}}>
										<img
											alt="Author Avatar"
											className="comment__icon__image"
											onError={(e) => {
												// @ts-ignore
												e.target.src =
													props.images?.defaultAvatar;
											}}
											src={
												announcementAuthor?.avatar
													? announcementAuthor?.avatar
													: props.images
															?.defaultAvatar
											}
										/>
									</span>
									{/* author name */}
									<span className="comment__commenter-name">
										<a
											title={announcementAuthor?.username}
											onClick={(e) => {
												e.preventDefault();
												history.push(
													`/users/${targetAnnouncement?.author}`,
												);
											}}
											href={`/users/${targetAnnouncement?.author}`}>
											{announcementAuthor?.username}
										</a>
									</span>
								</div>
								{/* Add the like/dislike button */}
								<AnnouncementReact
									announcement={announcement}
									setAnnouncement={setAnnouncements}
									single={false}
								/>
								{/* comment + timestamp */}
								<div className="comment__comment-body">
									<h4
										className="center-textoutput"
										style={{
											borderRadius: "12px",
											borderStyle: "solid",
											borderWidth: "2px",
											borderColor:
												"rgba(255,255,255, 0.4)",
											marginTop: "18px",
											padding: "28px",
										}}>
										<u>
											{announcement?.title ||
												"Untitled announcement"}
										</u>
									</h4>
									<div className="center-textoutput">
										<ReadMore
											content={
												trimPollContent(announcement) ||
												"No main content attached."
											}
											document={announcement}
											height={600}
											extendBBCode={true}
										/>
									</div>
									{announcement?._id &&
										announcement?.poll !== undefined && (
											<PollChart
												announcement={announcement}
												setAnnouncement={
													setAnnouncements
												}
												currentUserData={
													currentUserData
												}
												mode="multiple"
												isThread={true}
												showVote={true}
											/>
										)}
									<hr className="hr-margin" />
								</div>
								<div className="comment__comment-timestamp">
									Posted on{" "}
									<b>
										{moment(targetAnnouncement?.postDate)
											.format(`
									MMM D, YYYY - h:mm A
								`)}
									</b>
								</div>
							</div>
						</div>
						<hr />
						<AnnouncementThreadReplies
							announcementReplies={announcementReplies}
							setAnnouncementReplies={setAnnouncementReplies}
							controlAnnouncementReplies={
								controlAnnouncementReplies
							}
							setControlAnnouncementReplies={
								setControlAnnouncementReplies
							}
							images={props.images}
							showReplies={showReplies}
							setAnnouncementModalShow={setAnnouncementModalShow}
							targetAnnouncement={targetAnnouncement}
							switchEditState={switchEditState}
							page={page}
							threadpage={threadpage}
							setAnnouncementThreadChanged={
								setAnnouncementThreadChanged
							}
							replyAuthors={replyAuthors}
							setAnnouncementToDelete={setAnnouncementToDelete}
							setDeleteAction={setDeleteAction}
							setDeleteAnnouncementModalShow={
								setDeleteAnnouncementModalShow
							}
						/>
						{announcementReplies?.length > 0 ? (
							<Pagination
								setContentChanged={setAnnouncementThreadChanged}
								threadpage={threadpage}
								numberOfThreadPages={numberOfThreadPages}
								showReplies={showReplies}
								type="reply"
							/>
						) : null}
					</Modal.Body>
					{(!cantPostComments && !announcement?.locked) ||
					currentUserData?.isStaff ? (
						<Modal.Footer
							className="mx-auto"
							style={{ margin: "0 auto" }}>
							<span style={{ margin: "0 auto" }}>
								<textarea
									// @ts-ignore
									type="text"
									placeholder="Write a reply to this announcement"
									className="form form-control thread-form"
									style={{ width: "90%" }}
									maxLength={5000}
									value={announcementReply}
									onChange={(e) =>
										setAnnouncementReply(e.target.value)
									}
								/>
								<br />
								<div className="thread-footer">
									<Button
										className="btn-primary__special"
										style={{
											position: "relative",
											left: "0",
										}}
										disabled={lockAnnouncement}
										// send a reply to a comment in the thread
										onClick={() => {
											const announcementID =
												targetAnnouncement?._id;
											if (
												announcementReply !== "" &&
												!currentUserData?.mainRoles?.includes(
													UserConstants.Punishments
														.MUTED,
												)
											) {
												setLockAnnouncement(true);
												createAnnouncementReply(
													announcementID,
													{
														content:
															removeExcessiveBreaks(
																announcementReply,
															),
													},
												)
													.then((response) => {
														setLockAnnouncement(
															false,
														);
														if (response) {
															// add reply to replies array
															setAnnouncementReplies(
																(
																	announcementReplies,
																) => [
																	...announcementReplies,
																	response
																		.data
																		.newAnnouncementReply,
																],
															);
															// add user to subscribers array
															if (
																!targetAnnouncement.subscribers.includes(
																	currentUserData._id,
																)
															) {
																setAnnouncements(
																	(
																		prevAnnouncements,
																	) =>
																		prevAnnouncements.map(
																			(
																				prevAnnouncement,
																			) => {
																				// select the specific announcement
																				if (
																					targetAnnouncement._id ===
																					prevAnnouncement._id
																				) {
																					return {
																						...prevAnnouncement,
																						subscribers:
																							[
																								...prevAnnouncement?.subscribers,
																								currentUserData._id,
																							],
																					};
																				} else
																					return prevAnnouncement;
																			},
																		),
																);
															}
															// update other states
															setReplyAuthors(
																(
																	prevAuthors,
																) => [
																	...prevAuthors,
																	currentUserData,
																],
															);
															setAnnouncementReply(
																"",
															);
															setShowReplies(
																true,
															);
															incrementReplyCount(
																announcement._id,
															);
														}
													})
													.catch(() =>
														setLockAnnouncement(
															false,
														),
													);
											} else {
												currentUserData?.mainRoles?.includes(
													UserConstants.Punishments
														.MUTED,
												)
													? toast.error(
															"You can't post comments while muted!",
														)
													: toast.error(
															"New comments can't be empty!",
														);
											}
										}}>
										Send&nbsp;
										{announcementReply?.length
											? `(${announcementReply?.length})`
											: null}
									</Button>{" "}
									<Button
										disabled={lockAnnouncement}
										onClick={() => {
											const type = "announcement";
											const force =
												announcement?.subscribers?.includes(
													currentUserData?._id,
												)
													? "unsubscribe"
													: "subscribe";
											setLockAnnouncement(true);
											// backend request
											subscribeToThread(
												announcement?._id,
												force,
												type,
											)
												.then(() => {
													setLockAnnouncement(false);
													setAnnouncements(
														(prevAnnouncements) =>
															prevAnnouncements.map(
																(
																	prevAnnouncement,
																) => {
																	if (
																		announcement._id ===
																		prevAnnouncement._id
																	) {
																		// Add or remove currentUserData._id based on the value of "force"
																		const updatedSubscribers =
																			force ===
																			"subscribe"
																				? [
																						...(prevAnnouncement?.subscribers ||
																							[]),
																						currentUserData._id,
																					] // Add to followers array
																				: prevAnnouncement?.subscribers?.filter(
																						(
																							id,
																						) =>
																							id !==
																							currentUserData._id,
																					); // Remove from followers array
																		return {
																			...(prevAnnouncement ||
																				[]),
																			subscribers:
																				updatedSubscribers,
																		};
																	} else {
																		return prevAnnouncement;
																	}
																},
															),
													);
												})
												.catch(() =>
													setLockAnnouncement(false),
												);
										}}
										style={{
											position: "relative",
											left: "0",
										}}>
										{announcement?.subscribers?.includes(
											currentUserData?._id,
										)
											? "Unsubscribe"
											: "Subscribe"}
									</Button>{" "}
									<Button
										onClick={() =>
											setAnnouncementModalShow(false)
										}
										style={{
											position: "relative",
											left: "0",
										}}>
										Close
									</Button>
									{currentUserData?.isAdmin ? (
										<>
											{" "}
											<Button
												onClick={() =>
													handleAnnouncementLock(
														announcement,
													)
												}
												className={`btn-primary${
													announcement?.locked
														? "__special"
														: "__danger"
												}`}
												style={{
													position: "relative",
													left: "0",
												}}>
												{announcement?.locked
													? "Unlock"
													: "Lock"}
											</Button>
										</>
									) : null}
								</div>
							</span>
						</Modal.Footer>
					) : (
						<Modal.Footer
							className="mx-auto"
							style={{ margin: "0 auto" }}>
							<span style={{ margin: "0 auto" }}>
								{currentUserData?._id &&
									!announcement?.locked && (
										<>
											<Button
												disabled={lockAnnouncement}
												onClick={() => {
													const type = "announcement";
													const force =
														announcement?.subscribers?.includes(
															currentUserData?._id,
														)
															? "unsubscribe"
															: "subscribe";
													setLockAnnouncement(true);
													// backend request
													subscribeToThread(
														announcement?._id,
														force,
														type,
													)
														.then(() => {
															setLockAnnouncement(
																false,
															);
															setAnnouncements(
																(
																	prevAnnouncements,
																) =>
																	prevAnnouncements.map(
																		(
																			prevAnnouncement,
																		) => {
																			if (
																				announcement._id ===
																				prevAnnouncement._id
																			) {
																				// Add or remove currentUserData._id based on the value of "force"
																				const updatedFollowers =
																					force ===
																					"subscribe"
																						? [
																								...prevAnnouncement?.subscribers,
																								currentUserData._id,
																							] // Add to followers array
																						: prevAnnouncement?.subscribers?.filter(
																								(
																									id,
																								) =>
																									id !==
																									currentUserData._id,
																							); // Remove from followers array
																				return {
																					...prevAnnouncement,
																					subscribers:
																						updatedFollowers,
																				};
																			} else {
																				return prevAnnouncement;
																			}
																		},
																	),
															);
														})
														.catch(() =>
															setLockAnnouncement(
																false,
															),
														);
												}}
												style={{
													position: "relative",
													left: "0",
												}}>
												{announcement?.subscribers?.includes(
													currentUserData?._id,
												)
													? "Unsubscribe"
													: "Subscribe"}
											</Button>{" "}
										</>
									)}
								<Button
									onClick={() =>
										setAnnouncementModalShow(false)
									}
									style={{ position: "relative", left: "0" }}>
									Close
								</Button>
								{currentUserData?.isAdmin ? (
									<>
										{" "}
										<Button
											onClick={handleAnnouncementLock}
											className={`btn-primary${
												announcement?.locked
													? "__special"
													: "__danger"
											}`}
											style={{
												position: "relative",
												left: "0",
											}}>
											{announcement?.locked
												? "Unlock"
												: "Lock"}
										</Button>
									</>
								) : null}
							</span>
						</Modal.Footer>
					)}
				</div>
			</Modal>
		);
	};

	const incrementReplyCount = (announcementId) => {
		setAnnouncements((prevAnnouncements) => {
			return prevAnnouncements.map((announcement) => {
				if (announcement?._id === announcementId) {
					let updatedReplyCount;
					if (!announcement?.replyCount) {
						updatedReplyCount =
							(announcement?.replies?.length || 0) + 1;
					} else {
						updatedReplyCount = (announcement?.replyCount || 0) + 1;
					}
					return {
						...announcement,
						replyCount: updatedReplyCount,
					};
				}
				return announcement;
			});
		});
	};

	// switch the edit state of announcements
	const switchEditState = (contentId, type) => {
		if (type === "announcement") {
			setAnnouncements((prevAnnouncements) =>
				prevAnnouncements.map((announcement) =>
					announcement._id === contentId
						? {
								...announcement,
								editState: !announcement.editState,
							}
						: announcement,
				),
			);
		} else {
			setAnnouncementReplies((prevReplies) =>
				prevReplies.map((reply) =>
					reply._id === contentId
						? { ...reply, editState: !reply.editState }
						: reply,
				),
			);
		}
	};

	// ------------------------------------------------------ //
	// 						JSX BEGINS 						  //
	// ------------------------------------------------------ //

	return (
		<>
			{
				// button to scroll to announcements
				currentUserData?._id && (
					<div
						style={{
							position: "fixed",
							bottom: "16px",
							right: "16px",
							zIndex: "99",
							opacity: stickyButton ? "1" : "0",
							transition: "opacity 0.8s ease-in-out",
							// @ts-ignore
							Index: "100",
						}}>
						<button
							style={{
								borderStyle: "solid",
								borderWidth: "2px",
								borderColor: "white",
								color: "white",
								borderRadius: "4px",
								padding: "4px",
								backgroundColor: "#ffffff70",
								zIndex: "100",
							}}
							onClick={() => scrollToRef(announcementRef)}>
							Announcements ▼
						</button>
					</div>
				)
			}
			<div className="container mt-4">
				{(xpMultiplier !== 1 || shimflakeMultiplier !== 1) && (
					<span style={{ position: "relative", top: "-25px" }}>
						<hr />
						{xpMultiplier !== 1 && shimflakeMultiplier !== 1 ? (
							<h1 className="center-text">
								- Site wide{" "}
								<span className="yellow">
									{xpMultiplier}x experience
								</span>{" "}
								and{" "}
								<span className="shimflake">
									{shimflakeMultiplier}x shimflake
								</span>{" "}
								multiplier are active! -
							</h1>
						) : xpMultiplier !== 1 ? (
							<h1 className="center-text">
								- Site wide{" "}
								<span className="yellow">
									{xpMultiplier}x experience multiplier
								</span>{" "}
								is active! -
							</h1>
						) : (
							<h1 className="center-text">
								- Site wide{" "}
								<span className="shimflake">
									{shimflakeMultiplier}x shimflake multiplier
								</span>{" "}
								is active! -
							</h1>
						)}
						{!currentUserData?._id && (
							<h5 className="center-text">
								<a
									onClick={(e) => {
										e.preventDefault();
										history.push(
											`/auth?url=${window.location.pathname}`,
										);
									}}
									href={`/auth?url=${window.location.pathname}`}>
									Login or register
								</a>{" "}
								now to reap{" "}
								{xpMultiplier !== 1 && shimflakeMultiplier !== 1
									? "their"
									: "its"}{" "}
								benefits!
							</h5>
						)}
						<hr />
					</span>
				)}
				{/* if the user is logged in */}
				{currentUserData?._id ? (
					<div>
						<div className="row mx-auto">
							<div className="col-sm">
								<div
									className="jumbotron center-text welcome"
									// @ts-ignore
									height="100%">
									<h2 className="display-4">Welcome!</h2>
									<p className="lead">
										You are signed in as:
										<br />
										<a
											onClick={(e) => {
												e.preventDefault();
												history.push(
													`/users/${currentUserData?._id}`,
												);
											}}
											href={`/users/${currentUserData?._id}`}>
											{currentUserData?.username}
										</a>
									</p>
									{/* large screens */}
									{currentUserData?._id && (
										<div className="col-12 align-self-center d-none d-lg-block">
											<div className="mx-auto d-flex justify-content-center">
												{currentUserData?.verified ? (
													<a
														href="/levels/add"
														onClick={(e) => {
															e.preventDefault();
															history.push(
																"/levels/add",
															);
														}}>
														<button
															type="button"
															className="btn btn-lg btn-block btn-primary">
															Add{" "}
															{window.innerHeight <
																1200 &&
																"a"}{" "}
															Level
														</button>
													</a>
												) : (
													<a
														href="/verify"
														onClick={(e) => {
															e.preventDefault();
															history.push(
																"/verify",
															);
														}}>
														<button
															type="button"
															className="btn btn-lg btn-block btn-primary">
															Verify account
														</button>
													</a>
												)}
												<a
													href="https://discord.gg/GA48Y5TXwS"
													target="_blank"
													rel="noopener noreferrer">
													<button
														type="button"
														style={{
															marginLeft: "7px",
														}}
														className="btn btn-lg btn-block btn-discord"
														title="Join the LSS Discord to report bugs and give feedback!">
														LSS Discord
													</button>
												</a>
												<a
													href="https://bsky.app/profile/levelsharesquare.bsky.social"
													target="_blank"
													rel="noopener noreferrer">
													<button
														type="button"
														style={{
															marginLeft: "7px",
														}}
														className="btn btn-lg btn-block btn-primary__special"
														title="Check us out on bluesky!">
														LSS Bluesky
													</button>
												</a>
												<a
													href="/report?type=feedback"
													onClick={(e) => {
														e.preventDefault();
														history.push(
															"/report?type=feedback",
														);
													}}>
													<button
														type="button"
														style={{
															marginLeft: "7px",
														}}
														className="btn btn-lg btn-block btn-primary"
														title="Give us feedback or send in a bug report.">
														Give us feedback
													</button>
												</a>
											</div>
										</div>
									)}

									{/* mobile screens */}
									{currentUserData?._id && (
										<div className="col-12 align-self-center d-lg-none">
											<div
												className="mx-auto d-flex justify-content-center"
												style={{
													marginBottom: "10px",
												}}>
												{currentUserData?.verified ? (
													<a
														href="/levels/add"
														onClick={(e) => {
															e.preventDefault();
															history.push(
																`/levels/add`,
															);
														}}>
														<button
															type="button"
															className="btn btn-primary btn-lg btn-block">
															Add Level
														</button>
													</a>
												) : (
													<a
														href="/verify"
														onClick={(e) => {
															e.preventDefault();
															history.push(
																`/verify`,
															);
														}}>
														<button
															type="button"
															className="btn btn-primary btn-lg btn-block">
															Verify account
														</button>
													</a>
												)}
												<a
													href="https://bsky.app/profile/levelsharesquare.bsky.social"
													target="_blank"
													rel="noopener noreferrer">
													<button
														type="button"
														style={{
															marginLeft: "12px",
														}}
														className="btn btn-lg btn-block btn-primary__special"
														title="Check us out on bluesky!">
														LSS Bluesky
													</button>
												</a>
											</div>
											<a
												href="https://discord.gg/GA48Y5TXwS"
												target="_blank"
												rel="noopener noreferrer">
												<button
													type="button"
													className="btn btn-lg btn-block btn-discord"
													title="Join the LSS Discord to chat with us!">
													{window.innerWidth > 377
														? "LSS Discord"
														: "Discord"}
												</button>
											</a>
											<a
												href="/report?type=feedback"
												style={{
													marginLeft: "12px",
												}}
												onClick={(e) => {
													e.preventDefault();
													history.push(
														"/report?type=feedback",
													);
												}}>
												<button
													type="button"
													className="btn btn-primary btn-lg btn-block"
													title="Give us feedback or send in a bug report.">
													Feedback
												</button>
											</a>
										</div>
									)}
								</div>
							</div>
							<div
								style={{ height: "20px" }}
								className="d-lg-none"
							/>
							<div className="col-sm">
								<div
									className="jumbotron center-text"
									style={{ height: "100%" }}>
									{latestLSSYTVideo ? (
										<iframe
											height="100%"
											width="100%"
											src={latestLSSYTVideo}
											title="YouTube video player"
											allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
											allowFullScreen={true}
											style={{
												border: "2px solid white",
												borderRadius: "12px",
											}}
										/>
									) : (
										<h2>
											Loading latest LSS YouTube video...
										</h2>
									)}
								</div>
							</div>
						</div>
						<br />
						<div className="row mx-auto">
							<div className="col-sm">
								<div
									className="jumbotron center-text"
									style={{
										whiteSpace: "pre-wrap",
										wordBreak: "break-word",
									}}
									ref={heightApplyRef}>
									<div ref={onlineTrackRef}>
										<h2>
											Currently online users{" "}
											<span className="yellow">
												({userCount || "none"}
												&nbsp;found)
											</span>
										</h2>
										<hr />
									</div>
									<div
										ref={onlineApplyRef}
										style={{
											outline: "2px solid white",
											fontSize: "18px",
											overflowY: "auto",
											borderRadius: "8px",
											paddingLeft: "8px",
											paddingRight: "8px",
											wordBreak: "break-word",
										}}>
										{onlineUsers?.length ? (
											onlineUsers.map(
												(onlineUser, key) => (
													<span
														key={onlineUser?._id}
														style={{
															display:
																"inline-block",
														}}>
														<MapOnlineUsers
															user={onlineUser}
															index={key}
															maxIndex={
																onlineUsers.length
															}
															showCard={showCard}
															setShowCard={
																setShowCard
															}
															gameProperties={
																props.gameProperties
															}
														/>
													</span>
												),
											)
										) : onlineUsers === null ? (
											<span className="yellow">
												Somehow, nobody is online?
											</span>
										) : (
											<Spinner
												animation="grow"
												variant="primary"
												style={{ marginTop: "12px" }}
											/>
										)}
										{allUsers !== true && userCount > 25 ? (
											<>
												<hr style={{ margin: "4px" }} />
												<a
													href="#!"
													className="yellow"
													style={{ fontSize: "24px" }}
													onClick={(e) => {
														e.preventDefault();
														setAllUsers(true);
													}}>
													view all
												</a>
											</>
										) : (
											""
										)}
									</div>
								</div>
							</div>
							<div
								style={{ height: "20px" }}
								className="d-lg-none"
							/>
							<div className="col-sm">
								<div
									ref={heightTrackRef}
									className="jumbotron center-text welcome">
									<h2>Random hint</h2>
									<hr />
									<span
										className="yellow"
										style={{
											fontSize: "24px",
										}}>
										{randomHint || (
											<Spinner
												animation="grow"
												variant="primary"
											/>
										)}
									</span>
									<hr />
									<button
										onClick={() => setHintChanged(true)}
										className="btn btn-primary"
										style={{ marginTop: "4px" }}>
										<span
											className="material-icons"
											style={{
												position: "relative",
												top: "3px",
												left: "0",
											}}>
											refresh
										</span>
										<span
											style={{
												position: "relative",
												top: "-2px",
											}}>
											&nbsp;Get another!
										</span>
									</button>
								</div>
							</div>

							<h2
								ref={announcementRef}
								onClick={() => scrollToRef(announcementRef)}
								className="display-6 center-text"
								style={{ marginTop: "50px" }}>
								<span className="tags">Announcements</span>
							</h2>
						</div>
						{(currentUserData?.isAdmin ||
							currentUserData?.mainRoles?.includes(
								UserConstants.MainRoles.PARTNER,
							)) && (
							<>
								<br />
								<div className="row mx-auto">
									<div className="col-12 col-lg-12">
										<div className="card">
											<div className="card-header">
												<img
													src={
														props.images.Exclamation
													}
													alt="Exclamation"
													width="54"
													height="54"
												/>
												<span
													className="align-middle"
													style={{
														paddingLeft: "7px",
														fontSize: "24px",
													}}>
													Post an announcement:
												</span>
											</div>
											<div className="card-body">
												<form
													onSubmit={
														handlePostAnnouncement
													}>
													<div className="form-group">
														<label htmlFor="content">
															Set a title
														</label>
														<input
															className="form-control"
															style={{
																width: "100%",
																marginLeft: "0",
															}}
															maxLength={200}
															placeholder="What's your announcement about?"
															value={
																announcementTitle
															}
															onChange={(e) => {
																setAnnouncementTitle(
																	e.target
																		.value,
																);
																sessionStorage.setItem(
																	"anntitle",
																	e.target
																		.value,
																);
															}}
														/>
														<label htmlFor="content">
															What do you want to
															say?{" "}
															{announcementContent?.length ? (
																<span className="yellow">
																	(
																	{
																		announcementContent?.length
																	}
																	/15000)
																</span>
															) : null}
														</label>
														<textarea
															className="form-control"
															style={{
																resize: "none",
																width: "100%",
																marginLeft:
																	"unset",
															}}
															id="content"
															name="content"
															maxLength={15000}
															rows={7}
															placeholder="All members of the site will be able to see this post."
															value={
																announcementContent
															}
															onChange={(e) => {
																// value
																const value =
																	e.target
																		.value;
																// state
																setAnnouncementContent(
																	value,
																);
																// sessionstorage
																if (
																	value !== ""
																)
																	return sessionStorage.setItem(
																		"announcement",
																		value,
																	);
																sessionStorage.removeItem(
																	"announcement",
																);
															}}></textarea>
													</div>
													<br />

													<button
														type="submit"
														disabled={
															lockAnnouncement
														}
														className="btn btn-primary">
														Post announcement
													</button>
													<button
														className="btn btn-primary"
														style={{
															marginLeft: "5px",
														}}
														title="Up to 5 options max"
														onClick={(e) => {
															e.preventDefault();
															setAnnouncementContent(
																(prevState) => {
																	// define new state
																	const newState =
																		prevState +
																		presetPollContent;
																	// update local storage
																	sessionStorage.setItem(
																		"announcement",
																		newState,
																	);
																	// returns
																	return newState;
																},
															);
														}}>
														Add a poll
													</button>
												</form>
											</div>
										</div>
									</div>
								</div>
								<br />
							</>
						)}

						{/* if there are no announcements found, show a loading spinner */}
						{!announcements?.length || !authors?.length ? (
							// loading spinner
							<div className="container mt-4">
								<div className="row mx-auto">
									<div
										className="d-flex justify-content-center"
										id="loading-spinner">
										<Spinner
											animation="grow"
											variant="primary"
										/>
									</div>

									{/* display "no announcements found" message if none are found */}
									{announcementMessage &&
										!announcements?.length && (
											<div className="col-12">
												<div
													className="card"
													id="infoCard"
													style={{
														textAlign: "center",
													}}>
													<div
														className="card-body"
														id="infoCard">
														<h2 className="card-title">
															{
																announcementMessage
															}
														</h2>
													</div>
												</div>
											</div>
										)}
								</div>
							</div>
						) : (
							<>
								{/* site announcements */}
								<div className="container mt-4">
									<div className="row mx-auto">
										{/* display announcements if any are found, (continually updates with new announcements) */}
										{announcements?.map(
											(unprocessedAnnouncement, key) => {
												const announcement =
													processPoll(
														unprocessedAnnouncement,
													);

												// ease of use constant
												const announcementAuthor =
													authors?.find?.(
														(user) =>
															user._id ===
															announcement?.author,
													);

												return (
													<div
														key={announcement?._id}
														className="col-12 col-lg-12">
														{!announcement?.editState ? (
															<div className="card">
																<div
																	className="card-header"
																	style={{
																		overflow:
																			"hidden",
																		whiteSpace:
																			"nowrap",
																		textOverflow:
																			"ellipsis",
																	}}>
																	<div
																		style={{
																			float: "left",
																			height: "34px",
																		}}>
																		<span
																			style={{
																				borderRadius:
																					"50%",
																				borderWidth:
																					"3px",
																				borderStyle:
																					"solid",
																				position:
																					"absolute",
																				top: "3px",
																				left: "8px",
																				width: "44px",
																				height: "44px",
																				overflow:
																					"hidden",
																				display:
																					"inline-block",
																				borderColor:
																					announcementAuthor?.icon_outline,
																				boxShadow:
																					announcementAuthor?.icon_glow
																						? `0 0 4px 2px ${announcementAuthor?.icon_glow}`
																						: "none",
																			}}>
																			<img
																				src={
																					authors &&
																					authors[0]
																						?._id &&
																					announcementAuthor?.avatar
																						? announcementAuthor?.avatar
																						: props
																								.images
																								.defaultAvatar
																				}
																				onError={(
																					e,
																				) => {
																					// @ts-ignore
																					e.target.src =
																						props.images.defaultAvatar;
																				}}
																				alt="Author Avatar"
																				style={{
																					objectFit:
																						"cover",
																					objectPosition:
																						"center",
																					height: "100%",
																					width: "100%",
																				}}
																			/>
																		</span>
																		<span
																			className="align-middle"
																			style={{
																				position:
																					"relative",
																				left: "44px",
																				top: "3px",
																				display:
																					"inline-block",
																			}}>
																			<a
																				title={
																					authors &&
																					announcementAuthor?.username
																				}
																				onClick={(
																					e,
																				) => {
																					e.preventDefault();
																					history.push(
																						`/users/${announcement?.author}`,
																					);
																				}}
																				href={`/users/${announcement?.author}`}>
																				{authors &&
																					announcementAuthor?.username}
																			</a>
																		</span>
																	</div>
																</div>

																<div className="card-body">
																	{/* all buttons */}
																	<div className="thread-icon-container">
																		{/*//? all action related buttons*/}
																		<OverlayTrigger
																			delay={{
																				show: 210,
																				hide: 0,
																			}}
																			placement="top"
																			overlay={
																				<Tooltip id="replies">
																					Replies
																				</Tooltip>
																			}
																			popperConfig={{
																				modifiers:
																					[
																						{
																							name: "offset",
																							options:
																								{
																									offset: [
																										0,
																										-2,
																									],
																								},
																						},
																					],
																			}}>
																			<span className="align-middle thread-number">
																				{/* replycount for comments */}
																				{announcement?.replyCount
																					? announcement?.replyCount -
																						(announcement
																							?.deleted_replies
																							?.length ||
																							0)
																					: announcement
																							?.replies
																							?.length -
																							(announcement
																								?.deleted_replies
																								?.length ||
																								0) ||
																						0}
																			</span>
																		</OverlayTrigger>
																		<OverlayTrigger
																			delay={{
																				show: 210,
																				hide: 0,
																			}}
																			placement="top"
																			overlay={
																				<Tooltip id="openThread">
																					Open
																					thread
																				</Tooltip>
																			}
																			popperConfig={{
																				modifiers:
																					[
																						{
																							name: "offset",
																							options:
																								{
																									offset: [
																										0,
																										15,
																									],
																								},
																						},
																					],
																			}}>
																			<span className="align-middle">
																				<a
																					onClick={() => {
																						setAnnouncementModalShow(
																							true,
																						);
																						setTargetAnnouncement(
																							announcement,
																						);
																					}}
																					href={
																						"#!"
																					}
																					className="material-symbols-outlined thread-icon blue">
																					comment
																				</a>
																			</span>
																		</OverlayTrigger>
																		<OverlayTrigger
																			delay={{
																				show: 210,
																				hide: 0,
																			}}
																			placement="top"
																			overlay={
																				<Tooltip id="permalink">
																					Permalink
																				</Tooltip>
																			}
																			popperConfig={{
																				modifiers:
																					[
																						{
																							name: "offset",
																							options:
																								{
																									offset: [
																										0,
																										15,
																									],
																								},
																						},
																					],
																			}}>
																			{/* Link to announcement */}
																			<span className="align-middle">
																				<a
																					onClick={(
																						e,
																					) => {
																						e.preventDefault();
																						history.push(
																							`/announcements/${announcement?._id}`,
																						);
																					}}
																					href={`/announcements/${announcement._id}`}>
																					<span className="material-symbols-outlined thread-icon">
																						link
																					</span>
																				</a>
																			</span>
																		</OverlayTrigger>
																		{/* Button to delete an announcement */}
																		{(currentUserData.isAdmin ||
																			currentUserData?._id ===
																				announcement?.author) && (
																			<>
																				<OverlayTrigger
																					delay={{
																						show: 210,
																						hide: 0,
																					}}
																					placement="top"
																					overlay={
																						<Tooltip id="editAnnouncement">
																							Edit
																							announcement
																						</Tooltip>
																					}
																					popperConfig={{
																						modifiers:
																							[
																								{
																									name: "offset",
																									options:
																										{
																											offset: [
																												0,
																												15,
																											],
																										},
																								},
																							],
																					}}>
																					<span className="align-middle">
																						<a
																							onClick={() => {
																								switchEditState(
																									announcement?._id,
																									"announcement",
																								);
																							}}
																							href={
																								"#!"
																							}
																							className="material-symbols-outlined thread-icon">
																							edit
																						</a>
																					</span>
																				</OverlayTrigger>
																				<OverlayTrigger
																					delay={{
																						show: 210,
																						hide: 0,
																					}}
																					placement="top"
																					overlay={
																						<Tooltip id="delete">
																							Delete
																						</Tooltip>
																					}
																					popperConfig={{
																						modifiers:
																							[
																								{
																									name: "offset",
																									options:
																										{
																											offset: [
																												0,
																												15,
																											],
																										},
																								},
																							],
																					}}>
																					<span className="align-middle">
																						<a
																							href="#!"
																							onClick={() => {
																								setAnnouncementToDelete(
																									announcement?._id,
																								);
																								setDeleteAnnouncementModalShow(
																									true,
																								);
																							}}>
																							<span className="material-symbols-outlined thread-icon">
																								delete
																							</span>
																						</a>
																					</span>
																				</OverlayTrigger>
																			</>
																		)}

																		<h4
																			className="center-textoutput"
																			style={{
																				borderRadius:
																					"12px",
																				borderStyle:
																					"solid",
																				borderWidth:
																					"2px",
																				borderColor:
																					"rgba(255,255,255, 0.4)",
																				marginTop:
																					"4px",
																				padding:
																					"24px",
																			}}>
																			<u>
																				{announcement?.title ||
																					"Untitled announcement"}
																			</u>
																		</h4>
																	</div>
																	<div>
																		{/* remove invalid html tags from content and display it */}
																		<div className="center-textoutput">
																			<ReadMore
																				content={
																					trimPollContent(
																						announcement,
																					) ||
																					"No main content attached."
																				}
																				document={
																					announcement
																				}
																				height={
																					600
																				}
																				extendBBCode={
																					true
																				}
																			/>
																		</div>
																	</div>
																	{announcement?._id &&
																		announcement?.poll !==
																			undefined && (
																			<PollChart
																				announcement={
																					announcement
																				}
																				setAnnouncement={
																					setAnnouncements
																				}
																				currentUserData={
																					currentUserData
																				}
																				mode="multiple"
																				showVote={
																					true
																				}
																			/>
																		)}
																	<hr />
																	<div className="comment-footer">
																		<AnnouncementReact
																			announcement={
																				announcement
																			}
																			setAnnouncement={
																				setAnnouncements
																			}
																			single={
																				false
																			}
																		/>

																		<span className="comment-footer__date">
																			Posted
																			on{" "}
																			<b>
																				{moment(
																					announcement?.postDate,
																				).format(
																					`MMM D, YYYY - h:mm A`,
																				)}
																			</b>
																		</span>
																		<span className="comment-footer__date__extra">
																			<b>
																				{moment(
																					announcement?.postDate,
																				).format(
																					`MMM D`,
																				)}
																			</b>
																		</span>
																	</div>
																</div>
															</div>
														) : (
															<div>
																<div
																	style={{
																		marginBottom:
																			"16px",
																	}}>
																	<span className="save-edit-comment">
																		<OverlayTrigger
																			delay={{
																				show: 130,
																				hide: 0,
																			}}
																			placement="top"
																			overlay={
																				<Tooltip id="cancel">
																					Cancel
																				</Tooltip>
																			}>
																			<a
																				onClick={() => {
																					switchEditState(
																						announcement?._id,
																						"announcement",
																					);
																					toast.warn(
																						"Any changes made to this comment will be discarded upon (comment) page reload.",
																					);
																				}}
																				href={
																					"#!"
																				}
																				className="material-symbols-outlined thread-icon blue"
																				style={{
																					paddingTop:
																						"3.5px",
																					paddingLeft:
																						"2px",
																				}}>
																				close
																			</a>
																		</OverlayTrigger>
																		<OverlayTrigger
																			delay={{
																				show: 130,
																				hide: 0,
																			}}
																			placement="top"
																			overlay={
																				<Tooltip id="saveChanges">
																					Save
																					changes
																				</Tooltip>
																			}>
																			<a
																				onClick={() => {
																					if (
																						announcement.content !==
																						controlAnnouncements[
																							key
																						]
																							.content
																					) {
																						if (
																							announcement.content
																						) {
																							// set the edit state to false
																							switchEditState(
																								announcement?._id,
																								"announcement",
																							);
																							// edit the announcement
																							editAnnouncement(
																								announcement?._id,
																								{
																									content:
																										announcement?.content,
																								},
																							).then(
																								(
																									response,
																								) => {
																									if (
																										response
																									)
																										setControlAnnouncements(
																											(
																												prevState,
																											) => {
																												return {
																													...prevState,
																													[key]: announcements[
																														key
																													],
																												};
																											},
																										);
																								},
																							);
																						} else {
																							toast.error(
																								"Comment can't be empty",
																							);
																						}
																					} else {
																						toast.error(
																							"No changes were made",
																						);
																					}
																				}}
																				href={
																					"#!"
																				}
																				className="material-symbols-outlined thread-icon blue"
																				style={{
																					paddingTop:
																						"3.5px",
																					paddingRight:
																						"3px",
																				}}>
																				save
																			</a>
																		</OverlayTrigger>
																	</span>
																	<textarea
																		// @ts-ignore
																		type="text"
																		title="edit announcement"
																		placeholder=""
																		maxLength={
																			10000
																		}
																		className="form form-control edit-announcement"
																		value={
																			announcement?.content
																		}
																		onChange={(
																			e,
																		) => {
																			const updatedAnnouncements =
																				[
																					...announcements,
																				];
																			updatedAnnouncements[
																				key
																			].content =
																				e.target.value;
																			setAnnouncements(
																				updatedAnnouncements,
																			);
																		}}></textarea>
																</div>
															</div>
														)}
														<br />
													</div>
												);
											},
										)}
										{deleteAnnouncementModal()}
										{announcementThreadModal()}
									</div>
								</div>

								{announcements?.length !== 0 && (
									<div className="MuiPagination-root">
										<Pagination
											setContentChanged={
												setAnnouncementsChanged
											}
											page={page}
											numberOfPages={numberOfPages}
											type="comment"
											siblingCount={3}
										/>
									</div>
								)}
							</>
						)}
					</div>
				) : (
					// if the user is not logged in
					<>
						<div className="row">
							{!isQuerying &&
							announcements.length === 0 ? null : (
								<h1
									className="display-6 center-text"
									style={{ marginBottom: "30px" }}>
									<span className="tags">
										Latest announcement
									</span>
								</h1>
							)}
							{/* latest announcement */}
							{announcements.length !== 0 ? (
								<LatestAnnouncement
									authors={authors}
									announcement={processPoll(announcements[0])}
									setAnnouncements={setAnnouncements}
									defaultAvatar={props.images.defaultAvatar}
								/>
							) : isQuerying ? (
								<div
									style={{
										width: "100%",
										display: "flex",
										justifyContent: "center",
									}}>
									<Spinner
										animation="grow"
										variant="primary"
									/>
								</div>
							) : null}
						</div>
						<hr />
						<div className="row">
							<div className="col-6 align-self-center mobile-home align-middle">
								<div className="jumbotron mobile-height">
									{/* text panel, first row*/}
									<h2 className="display-4">Create</h2>
									<p className="lead">
										Create custom levels in games with
										advanced level creation tools.
									</p>
									<hr />
									<div>
										<div
											style={{
												margin: "0 auto",
												width: "max-content",
											}}>
											<button
												type="button"
												onClick={() =>
													window.open(
														"https://www.youtube.com/watch?v=_ehkwIzdIRA&lc=UgwQ3no-YnMjVoTbIvN4AaABAg",
													)
												}
												className="btn btn-lg btn-block btn-primary__special"
												title="Not sure yet about joining? Let us show you what there is to offer!">
												Watch the trailer
											</button>
										</div>
										<br />
										<div
											style={{
												margin: "0 auto",
												width: "max-content",
												marginTop: "-12px",
											}}>
											<button
												onClick={() =>
													window.open(
														"https://bsky.app/profile/levelsharesquare.bsky.social",
													)
												}
												type="button"
												style={{ margin: "0 auto" }}
												className="btn btn-lg btn-block btn-primary"
												title="Keep track of updates, announcements, teasers and more, no account required!">
												Keep track of our Bluesky!
											</button>
										</div>
									</div>
								</div>
							</div>
							{/* SMC preview image */}
							<div className="col-6 align-self-center d-none d-lg-block">
								<div className="preview">
									<img
										alt="SMC Preview"
										className="preview__image"
										src={smcPreview}
									/>
								</div>
							</div>
						</div>
						{/* spacers */}
						<br />
						<hr className="d-none d-lg-block" />
						<br className="d-none d-lg-block" />

						<div className="row">
							{/* YFS preview image, second row */}
							<div className="col-6 align-self-center d-none d-lg-block">
								<div className="preview">
									<img
										alt="SM127 Preview"
										className="preview__image"
										src={yfsPreview}
									/>
								</div>
							</div>
							{/* text panel */}
							<div className="col-6 align-self-center mobile-home align-middle">
								<div className="jumbotron mobile-height">
									<h2 className="display-4">Save</h2>
									<p className="lead">
										Save your level codes to the site
										without need for verification.
									</p>
									<hr />
									<div
										style={{
											height: "128px",
											display: "flex",
											alignItems:
												"center" /* Vertically center items */,
											justifyContent: "center",
										}}>
										<a
											href="https://discord.gg/GA48Y5TXwS"
											target="_blank"
											rel="noopener noreferrer">
											<button
												type="button"
												className="btn btn-lg btn-block btn-discord"
												title="Join our Discord server by clicking here!">
												Check out our Discord!
											</button>
										</a>
									</div>
								</div>
							</div>
						</div>

						{/* spacers */}
						<br />
						<hr className="d-none d-lg-block" />
						<br className="d-none d-lg-block" />

						<div className="row">
							<div className="col-6 align-self-center mobile-home align-middle">
								<div className="jumbotron mobile-height">
									{/* text panel, third row */}
									<h2 className="display-4">Share</h2>
									<p className="lead">
										Share your custom levels with a vast
										community of passionate creators.
									</p>
									<hr />
									<a
										onClick={(e) => {
											e.preventDefault();
											history.push(
												`/auth?url=${window.location.pathname}`,
											);
										}}
										href={`/auth?url=${window.location.pathname}`}>
										Login or register
									</a>{" "}
									to get started! Or start browsing{" "}
									<a
										href="/levels"
										onClick={(e) => {
											e.preventDefault();
											history.push(`/levels`);
										}}>
										here
									</a>
									!
								</div>
							</div>
							{/* SM127 preview image */}
							<div className="col-6 align-self-center d-none d-lg-block">
								<div className="preview">
									<img
										alt="SMC Preview"
										className="preview__image"
										src={sm127Preview}
									/>
								</div>
							</div>
						</div>
					</>
				)}
			</div>
		</>
	);
};

const AnnouncementThreadReplies = (props) => {
	const currentUserData = useContext<any>(UserContext);
	const [hasDoneQuery, setHasDoneQuery] = useState<boolean>(false);
	const history = useHistory();

	useEffect(() => {
		if (!hasDoneQuery) {
			props.setAnnouncementThreadChanged(true);
			setHasDoneQuery(true);
		}
	}, [props, hasDoneQuery]);

	return (
		<>
			{props?.announcementReplies?.length !== 0 ? (
				<>
					{props.announcementReplies?.map(
						(announcementReply, key) => {
							// define the reply author
							const replyAuthor = props.replyAuthors?.find(
								(user) =>
									user._id === announcementReply?.author,
							);
							// define admin comment authors for perms
							const authorIsAdmin =
								replyAuthor?.mainRoles?.includes(
									UserConstants.MainRoles.ADMIN,
								);

							return (
								<div key={announcementReply?._id}>
									{!announcementReply?.editState ? (
										<div
											style={{
												marginBottom: "14px",
												opacity: props.showReplies
													? 1
													: 0,
											}}
											className={`comment hover-colour thread-fade ${
												announcementReply.deleted
													? "deleted-comment"
													: "public-comment"
											} 
										${props.showReplies ? "fade-in-animation" : ""}`}>
											<div>
												<div
													style={{
														position: "absolute",
														top: "6px",
														right: "6px",
													}}>
													{/* display all icons */}
													{
														// edit button
														((currentUserData?.isStaff &&
															!authorIsAdmin) ||
															currentUserData?._id ===
																announcementReply?.author ||
															props.userIsAdmin) &&
															announcementReply?.deleted !==
																true && (
																<OverlayTrigger
																	delay={{
																		show: 210,
																		hide: 0,
																	}}
																	placement="top"
																	overlay={
																		<Tooltip id="editReply">
																			Edit
																			reply
																		</Tooltip>
																	}>
																	<a
																		onClick={() => {
																			props.switchEditState(
																				announcementReply?._id,
																				"reply",
																			);
																		}}
																		href={
																			"#!"
																		}
																		className="material-symbols-outlined thread-icon">
																		edit
																	</a>
																</OverlayTrigger>
															)
													}
													{
														// delete buttons
														(currentUserData?.isStaff &&
															!authorIsAdmin) ||
														announcementReply?.author ===
															currentUserData?._id ||
														currentUserData?.isAdmin ? (
															// delete reply if public or private
															announcementReply.deleted ===
															false ? (
																<OverlayTrigger
																	delay={{
																		show: 210,
																		hide: 0,
																	}}
																	placement="top"
																	overlay={
																		<Tooltip id="deleteReply">
																			Delete
																			reply
																		</Tooltip>
																	}>
																	<a
																		onClick={() => {
																			props.setAnnouncementModalShow(
																				false,
																			);
																			props.setAnnouncementToDelete(
																				announcementReply?._id,
																			);
																			props.setDeleteAction(
																				"delete",
																			);
																			props.setDeleteAnnouncementModalShow(
																				true,
																			);
																		}}
																		href={
																			"#!"
																		}
																		className="material-symbols-outlined thread-icon">
																		delete
																	</a>
																</OverlayTrigger>
															) : (
																// OBLITERATE reply if admin
																<OverlayTrigger
																	delay={{
																		show: 210,
																		hide: 0,
																	}}
																	placement="top"
																	overlay={
																		<Tooltip id="obliterate">
																			OBLITERATE
																		</Tooltip>
																	}>
																	<a
																		onClick={() => {
																			props.setAnnouncementModalShow(
																				false,
																			);
																			props.setAnnouncementToDelete(
																				announcementReply?._id,
																			);
																			props.setDeleteAction(
																				"obliterate",
																			);
																			props.setDeleteAnnouncementModalShow(
																				true,
																			);
																		}}
																		href={
																			"#!"
																		}
																		className={`material-symbols-outlined thread-icon ${
																			!currentUserData.isAdmin &&
																			"hidden"
																		}`}>
																		local_fire_department
																	</a>
																</OverlayTrigger>
															)
														) : null
													}
												</div>
												{/* author avatar */}
												<span
													className="comment__icon"
													style={{
														borderColor:
															replyAuthor?.icon_outline,
														boxShadow:
															replyAuthor?.icon_glow
																? `0 0 4px 2px ${replyAuthor?.icon_glow}`
																: "none",
													}}>
													<img
														alt="Author Avatar"
														className="comment__icon__image"
														onError={(e) => {
															// @ts-ignore
															e.target.src =
																props.images?.defaultAvatar;
														}}
														src={
															replyAuthor?.avatar
																? replyAuthor?.avatar
																: props.images
																		?.defaultAvatar
														}
													/>
												</span>
												{/* author name */}
												<span className="comment__commenter-name">
													<a
														title={
															replyAuthor?.username
														}
														onClick={(e) => {
															e.preventDefault();
															history.push(
																`/users/${announcementReply?.author}`,
															);
														}}
														href={`/users/${announcementReply?.author}`}>
														{replyAuthor?.username}
													</a>
												</span>
												<hr
													style={{
														margin: "4px 7px 20px 7px",
													}}
												/>
											</div>

											{/* comment + timestamp */}
											<div className="comment__comment-body">
												<ReadMore
													content={
														announcementReply?.content
													}
													document={announcementReply}
												/>

												<hr
													style={{
														marginBottom: "-16px",
													}}
												/>
											</div>
											<div className="comment__comment-timestamp">
												Posted on{" "}
												<b>
													{moment(
														announcementReply?.postDate,
													).format(`
									MMM D, YYYY - h:mm A
								`)}
												</b>
											</div>
										</div>
									) : (
										<div>
											<div
												style={{
													marginBottom: "16px",
												}}>
												<span className="save-edit-comment">
													<OverlayTrigger
														delay={{
															show: 130,
															hide: 0,
														}}
														placement="top"
														overlay={
															<Tooltip id="cancel">
																Cancel
															</Tooltip>
														}>
														<a
															onClick={() => {
																props.switchEditState(
																	announcementReply?._id,
																	"reply",
																);
																toast.warn(
																	"Any changes made to this comment will be discarded upon (comment) page reload.",
																);
															}}
															href={"#!"}
															className="material-symbols-outlined thread-icon blue"
															style={{
																paddingTop:
																	"3.5px",
																paddingLeft:
																	"2px",
															}}>
															close
														</a>
													</OverlayTrigger>
													<OverlayTrigger
														delay={{
															show: 130,
															hide: 0,
														}}
														placement="top"
														overlay={
															<Tooltip id="saveChanges">
																Save changes
															</Tooltip>
														}>
														<a
															onClick={() => {
																if (
																	announcementReply?.content !==
																	props
																		?.controlAnnouncementReplies[
																		key
																	]?.content
																) {
																	if (
																		announcementReply.content
																	) {
																		// switch the edit state back to normal
																		props.switchEditState(
																			announcementReply?._id,
																			"reply",
																		);
																		// edit the comment
																		editAnnouncementReply(
																			props
																				.targetAnnouncement
																				?._id,
																			announcementReply?._id,
																			{
																				content:
																					announcementReply?.content,
																			},
																		).then(
																			() => {
																				// make sure the edit state remains false, add the "edited" tag
																				const updatedReplies =
																					props.announcementReplies.map(
																						(
																							reply,
																							index,
																						) => {
																							if (
																								index ===
																								key
																							) {
																								return {
																									...announcementReply,
																									edited: true,
																									editState:
																										false,
																								};
																							}
																							return reply;
																						},
																					);
																				props.setAnnouncementReplies(
																					updatedReplies,
																				);
																				// update state to account for new changes
																				props.setControlAnnouncementReplies(
																					(
																						prevState,
																					) => {
																						return {
																							...prevState,
																							[key]: announcementReply,
																						};
																					},
																				);
																			},
																		);
																	} else {
																		toast.error(
																			"Comment can't be empty",
																		);
																	}
																} else {
																	toast.error(
																		"No changes were made",
																	);
																}
															}}
															href={"#!"}
															className="material-symbols-outlined thread-icon blue"
															style={{
																paddingTop:
																	"3.5px",
																paddingRight:
																	"3px",
															}}>
															save
														</a>
													</OverlayTrigger>
												</span>
												<textarea
													// @ts-ignore
													type="text"
													title="edit reply"
													placeholder=""
													className="form form-control edit-comment__announcement"
													value={
														announcementReply?.content
													}
													onChange={(e) => {
														const updatedReplies = [
															...props.announcementReplies,
														];
														updatedReplies[
															key
														].content =
															e.target.value;
														props.setAnnouncementReplies(
															updatedReplies,
														);
													}}></textarea>
											</div>
										</div>
									)}
								</div>
							);
						},
					)}
				</>
			) : null}
		</>
	);
};

const LatestAnnouncement = (props) => {
	// find the author in the authors object-array wich was fetched in the parent JSX element, using the author id of the fetched announcement
	const author = props?.authors?.find(
		(author) => author?._id === props?.announcement?.author,
	);
	const history = useHistory();

	// render a singular announcement
	return author !== "" ? (
		<div className="container mt-4" style={{ marginBottom: "15px" }}>
			<div className="card">
				<div
					className="card-header"
					style={{
						overflow: "hidden",
						whiteSpace: "nowrap",
						textOverflow: "ellipsis",
					}}>
					<div
						style={{
							float: "left",
							height: "37px",
						}}>
						<span
							style={{
								borderRadius: "50%",
								borderWidth: "3px",
								borderStyle: "solid",
								position: "absolute",
								top: "5px",
								left: "8px",
								width: "44px",
								height: "44px",
								overflow: "hidden",
								display: "inline-block",
								borderColor: author?.icon_outline,
								boxShadow: author?.icon_glow
									? `0 0 4px 2px ${author?.icon_glow}`
									: "none",
							}}>
							<img
								src={
									author?.avatar
										? author?.avatar
										: props?.defaultAvatar
								}
								onError={(e) => {
									// @ts-ignore
									e.target.src = props?.defaultAvatar;
								}}
								alt="Author Avatar"
								style={{
									objectFit: "cover",
									objectPosition: "center",
									height: "100%",
									width: "100%",
								}}
							/>
						</span>
						<span
							className="align-middle"
							style={{
								position: "relative",
								left: "44px",
								top: "4px",
								display: "inline-block",
							}}>
							<a
								title={author?.username}
								onClick={(e) => {
									e.preventDefault();
									history.push(
										`/users/${props?.announcement?.author}`,
									);
								}}
								href={`/users/${props?.announcement?.author}`}>
								{author?.username}
							</a>
						</span>
					</div>
				</div>

				<div className="card-body">
					<h4
						className="center-textoutput"
						style={{
							borderRadius: "12px",
							borderStyle: "solid",
							borderWidth: "2px",
							borderColor: "rgba(255,255,255, 0.4)",
							marginTop: "4px",
							padding: "28px",
						}}>
						<u>
							{props.announcement?.title ||
								"Untitled announcement"}
						</u>
					</h4>
					<span>
						<div className="center-textoutput">
							<ReadMore
								content={
									trimPollContent(props?.announcement) ||
									"No main content attached."
								}
								document={props?.announcement}
								height={600}
								extendBBCode={true}
							/>
						</div>
					</span>
					{props?.announcement?._id &&
						props?.announcement?.poll !== undefined && (
							<PollChart
								announcement={props.announcement}
								setAnnouncement={props.setAnnouncements}
								currentUserData={null}
								mode="multiple"
								showVote={false}
							/>
						)}
					<hr className="hr-margin" />

					<div className="comment-footer">
						<AnnouncementReact
							announcement={props.announcement}
							setAnnouncement={props.setAnnouncements}
							single={false}
						/>
						<span className="comment-footer__date">
							Posted on{" "}
							<b>
								{moment(props.announcement?.postDate).format(
									`MMM D, YYYY - h:mm A`,
								)}
							</b>
						</span>
					</div>
					<div className="comment-footer">
						<span className="comment-footer__date__extra">
							<b>
								{moment(props.announcement?.postDate).format(
									`MMM D`,
								)}
							</b>
						</span>
					</div>
				</div>
			</div>
		</div>
	) : null;
};

// JSX component that maps all users who are currently online
const MapOnlineUsers = ({
	user,
	index,
	maxIndex,
	showCard,
	setShowCard,
	gameProperties,
}) => {
	const [cardPosition, setCardPosition] = useState<OnlineUsersCardPosition>({
		x: 0,
		y: 0,
	});
	const [render, setRender] = useState<boolean>(user?._id !== undefined);
	const history = useHistory();

	// cleanup for rendering
	useEffect(() => {
		return () => setRender(false);
	}, []);

	// filter out no-space breaks
	const username = user?.username?.replace(/ /g, "\u00A0");

	// clicking the username
	const handleClick = (e) => {
		e.preventDefault();
		e.stopPropagation();
		// check if the user is on a desktop device
		if (
			(window.innerWidth > 550 && window.innerHeight > 550) ||
			showCard === index
		) {
			return history.push(`/users/${user.user_id}`);
		}
		// function to trigger when on pc
		if (showCard === null) {
			setCardPosition({ x: e.clientX, y: e.clientY });
			return setShowCard(index);
			// redirect if the user has already clicked
		}
	};

	// hovering the username
	const handleHover = (e, show) => {
		e.preventDefault();
		e.stopPropagation();

		// don't fire if on mobile
		if (window.innerWidth <= 550 || window.innerHeight <= 550) return;

		// show card
		if (show === true) {
			setCardPosition({ x: e.clientX, y: e.clientY });
			return setShowCard(index);
		}

		// hide card
		if (show === false) {
			return setShowCard(null);
		}
	};

	return render ? (
		<>
			<span>
				<a
					href={`/users/${user.user_id}`}
					// hover events
					onClick={(e) => {
						handleClick(e);
					}}
					onMouseEnter={(e) => handleHover(e, true)}
					onMouseLeave={(e) => handleHover(e, false)}>
					<b
						style={{
							color:
								showCard === index
									? "lightblue"
									: mapHighestRole(user?.mainRoles, "color"),
							transition: "color 0.4s ease-in-out",
						}}>
						{username}
					</b>
				</a>
				{index !== maxIndex - 1 && <span>,&nbsp;</span>}
			</span>
			{showCard === index ? (
				<Suspense
					fallback={
						<div className="d-flex justify-content-center">
							<Spinner animation="grow" variant="primary" />
						</div>
					}>
					<HoverUserCard
						user={user}
						cardPosition={cardPosition}
						setShowCard={setShowCard}
						setCardPosition={setCardPosition}
						gameProperties={gameProperties}
					/>
				</Suspense>
			) : null}
		</>
	) : null;
};

export default HomePage;
