import React from "react";

const StarHalf = ({ width, height, color }) =>
	// render red
	color === "#c70700" ? (
		<svg
			width={width}
			height={height}
			viewBox="0 0 180 245"
			version="1.1"
			id="svg5"
			// @ts-ignore
			docname="star_half.svg"
			inkscapeversion="1.2.2 (732a01da63, 2022-12-09)"
			xmlns="http://www.w3.org/2000/svg">
			{/* @ts-ignore */}
			<namedview
				id="namedview7"
				pagecolor="#ffffff"
				bordercolor="#000000"
				borderopacity="0.25"
				showpageshadow="2"
				pageopacity="0.0"
				pagecheckerboard="0"
				deskcolor="#d1d1d1"
				documentunits="mm"
				showgrid="true"
				zoom="0.72515432"
				cx="37.922962"
				cy="612.28346"
				windowwidth="1920"
				windowheight="1001"
				windowx="-9"
				windowy="-9"
				windowmaximized="1"
				currentlayer="layer1">
				{/* @ts-ignore */}
				<grid type="xygrid" id="grid767" />
				{/* @ts-ignore */}
			</namedview>
			<defs id="defs2">
				{/* @ts-ignore */}
				<linearGradient collect="always" id="linearGradient5454">
					<stop
						style={{ stopColor: "#c70700", stopOpacity: "1" }}
						offset="0"
						id="stop5449"
					/>
					<stop
						style={{ stopColor: "#c70700", stopOpacity: "0" }}
						offset="1"
						id="stop5451"
					/>
				</linearGradient>
				<linearGradient
					// @ts-ignore
					collect="always"
					href="#linearGradient5454"
					id="linearGradient5457"
					x1="102.99294"
					y1="104.24699"
					x2="31.650038"
					y2="104.24699"
					gradientUnits="userSpaceOnUse"
				/>
			</defs>
			{/* @ts-ignore */}
			<g label="Laag 1" groupmode="layer" id="layer1">
				<path
					type="star"
					style={{
						opacity: 1,
						fill: "#241c1c",
						stroke: "#ffffff",
						strokeWidth: 6,
						strokeLinecap: "round",
						strokeLinejoin: "round",
						strokeMiterlimit: 20,
						strokeDasharray: "none",
						strokeDashoffset: 0,
						strokeOpacity: 1,
						paintOrder: "normal",
					}}
					id="path2825"
					// @ts-ignore
					flatsided="false"
					sides="5"
					cx="57.283787"
					cy="95.959457"
					r1="77.375358"
					r2="50.199165"
					arg1="1.5707963"
					arg2="2.1991148"
					rounded="0.22"
					randomized="0"
					d="m 57.283789,173.33482 c -10.370777,0 -21.116194,-30.66759 -29.506329,-36.76338 -8.390135,-6.09579 -40.877265,-6.83848 -44.082012,-16.70168 C -19.509298,110.00657 6.3367967,90.310259 9.5415426,80.447065 12.746289,70.58387 3.4135559,39.457269 11.80369,33.361479 c 8.390134,-6.09579 35.109318,12.398813 45.480094,12.398813 10.370777,0 37.089961,-18.494605 45.480096,-12.398816 8.39013,6.095789 -0.9426,37.222389 2.26215,47.085583 3.20475,9.863195 29.05084,29.559501 25.8461,39.422701 -3.20475,9.86319 -35.691878,10.60588 -44.082012,16.70167 -8.390135,6.09579 -19.135552,36.76339 -29.506329,36.76339 z"
					transform="matrix(1,0,0,-1,39.770259,243.36485)"
					transform-center-x="-2.3247776e-06"
					transform-center-y="-7.3331445"
					label="path2825"
				/>
				<path
					id="path2825-3"
					style={{
						fill: "url(#linearGradient5457)",
						stroke: "none",
						strokeWidth: "6.00009",
						strokeLinecap: "round",
						strokeLinejoin: "bevel",
						strokeDasharray: "none",
						strokeDashoffset: "0",
						strokeOpacity: "1",
						paintOrder: "normal",
					}}
					transform-center-x="1.663172e-06"
					transform-center-y="-4.6311016"
					d="M 56.195501 188.89493 C 56.560154 188.97063 56.922801 189.01031 57.283802 189.01031 C 68.749916 189.01031 82.153187 149.69677 91.429468 142.95715 C 100.70575 136.21754 142.23668 135.6187 145.7799 124.71378 C 149.32313 113.80886 116.07592 88.912565 112.5327 78.007643 C 108.98947 67.102721 121.25419 27.419948 111.97791 20.680336 C 102.70163 13.940724 68.749916 37.867317 57.283802 37.867318 C 56.94145 37.867318 56.576688 37.843063 56.195501 37.801856 L 56.195501 188.89493 z "
					transform="matrix(-0.63153135,0,0,-0.63153135,133.3649,206.4862)"
				/>
			</g>
		</svg>
	) : color === "#2cd40f" ? (
		<svg
			width={width}
			height={height}
			viewBox="0 0 180 245"
			version="1.1"
			id="svg5"
			// @ts-ignore
			docname="star_half.svg"
			inkscapeversion="1.2.2 (732a01da63, 2022-12-09)"
			xmlns="http://www.w3.org/2000/svg">
			{/* @ts-ignore */}
			<namedview
				id="namedview7"
				pagecolor="#ffffff"
				bordercolor="#000000"
				borderopacity="0.25"
				showpageshadow="2"
				pageopacity="0.0"
				pagecheckerboard="0"
				deskcolor="#d1d1d1"
				documentunits="mm"
				showgrid="true"
				zoom="0.72515432"
				cx="37.922962"
				cy="612.28346"
				windowwidth="1920"
				windowheight="1001"
				windowx="-9"
				windowy="-9"
				windowmaximized="1"
				currentlayer="layer1">
				{/* @ts-ignore */}
				<grid type="xygrid" id="grid767" />
				{/* @ts-ignore */}
			</namedview>
			<defs id="defs2">
				{/* @ts-ignore */}
				<linearGradient collect="always" id="linearGradient5453">
					<stop
						style={{ stopColor: "#2cd40f", stopOpacity: "1" }}
						offset="0"
						id="stop5449"
					/>
					<stop
						style={{ stopColor: "#2cd40f", stopOpacity: "0" }}
						offset="1"
						id="stop5451"
					/>
				</linearGradient>
				<linearGradient
					// @ts-ignore
					collect="always"
					href="#linearGradient5453"
					id="linearGradient5455"
					x1="102.99294"
					y1="104.24699"
					x2="31.650038"
					y2="104.24699"
					gradientUnits="userSpaceOnUse"
				/>
			</defs>
			{/* @ts-ignore */}
			<g label="Laag 1" groupmode="layer" id="layer1">
				<path
					type="star"
					style={{
						opacity: 1,
						fill: "#241c1c",
						stroke: "#ffffff",
						strokeWidth: 6,
						strokeLinecap: "round",
						strokeLinejoin: "round",
						strokeMiterlimit: 20,
						strokeDasharray: "none",
						strokeDashoffset: 0,
						strokeOpacity: 1,
						paintOrder: "normal",
					}}
					id="path2825"
					// @ts-ignore
					flatsided="false"
					sides="5"
					cx="57.283787"
					cy="95.959457"
					r1="77.375358"
					r2="50.199165"
					arg1="1.5707963"
					arg2="2.1991148"
					rounded="0.22"
					randomized="0"
					d="m 57.283789,173.33482 c -10.370777,0 -21.116194,-30.66759 -29.506329,-36.76338 -8.390135,-6.09579 -40.877265,-6.83848 -44.082012,-16.70168 C -19.509298,110.00657 6.3367967,90.310259 9.5415426,80.447065 12.746289,70.58387 3.4135559,39.457269 11.80369,33.361479 c 8.390134,-6.09579 35.109318,12.398813 45.480094,12.398813 10.370777,0 37.089961,-18.494605 45.480096,-12.398816 8.39013,6.095789 -0.9426,37.222389 2.26215,47.085583 3.20475,9.863195 29.05084,29.559501 25.8461,39.422701 -3.20475,9.86319 -35.691878,10.60588 -44.082012,16.70167 -8.390135,6.09579 -19.135552,36.76339 -29.506329,36.76339 z"
					transform="matrix(1,0,0,-1,39.770259,243.36485)"
					transform-center-x="-2.3247776e-06"
					transform-center-y="-7.3331445"
					label="path2825"
				/>
				<path
					id="path2825-3"
					style={{
						fill: "url(#linearGradient5455)",
						stroke: "none",
						strokeWidth: "6.00009",
						strokeLinecap: "round",
						strokeLinejoin: "bevel",
						strokeDasharray: "none",
						strokeDashoffset: "0",
						strokeOpacity: "1",
						paintOrder: "normal",
					}}
					transform-center-x="1.663172e-06"
					transform-center-y="-4.6311016"
					d="M 56.195501 188.89493 C 56.560154 188.97063 56.922801 189.01031 57.283802 189.01031 C 68.749916 189.01031 82.153187 149.69677 91.429468 142.95715 C 100.70575 136.21754 142.23668 135.6187 145.7799 124.71378 C 149.32313 113.80886 116.07592 88.912565 112.5327 78.007643 C 108.98947 67.102721 121.25419 27.419948 111.97791 20.680336 C 102.70163 13.940724 68.749916 37.867317 57.283802 37.867318 C 56.94145 37.867318 56.576688 37.843063 56.195501 37.801856 L 56.195501 188.89493 z "
					transform="matrix(-0.63153135,0,0,-0.63153135,133.3649,206.4862)"
				/>
			</g>
		</svg>
	) : (
		<svg
			width={width}
			height={height}
			viewBox="0 0 180 245"
			version="1.1"
			id="svg5"
			// @ts-ignore
			docname="star_half.svg"
			inkscapeversion="1.2.2 (732a01da63, 2022-12-09)"
			xmlns="http://www.w3.org/2000/svg">
			{/* @ts-ignore */}
			<namedview
				id="namedview7"
				pagecolor="#ffffff"
				bordercolor="#000000"
				borderopacity="0.25"
				showpageshadow="2"
				pageopacity="0.0"
				pagecheckerboard="0"
				deskcolor="#d1d1d1"
				documentunits="mm"
				showgrid="true"
				zoom="0.72515432"
				cx="37.922962"
				cy="612.28346"
				windowwidth="1920"
				windowheight="1001"
				windowx="-9"
				windowy="-9"
				windowmaximized="1"
				currentlayer="layer1">
				{/* @ts-ignore */}
				<grid type="xygrid" id="grid767" />
				{/* @ts-ignore */}
			</namedview>
			<defs id="defs2">
				{/* @ts-ignore */}
				<linearGradient collect="always" id="linearGradient5453">
					<stop
						style={{ stopColor: "#ffcc00", stopOpacity: "1" }}
						offset="0"
						id="stop5449"
					/>
					<stop
						style={{ stopColor: "#ffcc00", stopOpacity: "0" }}
						offset="1"
						id="stop5451"
					/>
				</linearGradient>
				<linearGradient
					// @ts-ignore
					collect="always"
					href="#linearGradient5453"
					id="linearGradient5455"
					x1="102.99294"
					y1="104.24699"
					x2="31.650038"
					y2="104.24699"
					gradientUnits="userSpaceOnUse"
				/>
			</defs>
			{/* @ts-ignore */}
			<g label="Laag 1" groupmode="layer" id="layer1">
				<path
					type="star"
					style={{
						opacity: 1,
						fill: "#241c1c",
						stroke: "#ffffff",
						strokeWidth: 6,
						strokeLinecap: "round",
						strokeLinejoin: "round",
						strokeMiterlimit: 20,
						strokeDasharray: "none",
						strokeDashoffset: 0,
						strokeOpacity: 1,
						paintOrder: "normal",
					}}
					id="path2825"
					// @ts-ignore
					flatsided="false"
					sides="5"
					cx="57.283787"
					cy="95.959457"
					r1="77.375358"
					r2="50.199165"
					arg1="1.5707963"
					arg2="2.1991148"
					rounded="0.22"
					randomized="0"
					d="m 57.283789,173.33482 c -10.370777,0 -21.116194,-30.66759 -29.506329,-36.76338 -8.390135,-6.09579 -40.877265,-6.83848 -44.082012,-16.70168 C -19.509298,110.00657 6.3367967,90.310259 9.5415426,80.447065 12.746289,70.58387 3.4135559,39.457269 11.80369,33.361479 c 8.390134,-6.09579 35.109318,12.398813 45.480094,12.398813 10.370777,0 37.089961,-18.494605 45.480096,-12.398816 8.39013,6.095789 -0.9426,37.222389 2.26215,47.085583 3.20475,9.863195 29.05084,29.559501 25.8461,39.422701 -3.20475,9.86319 -35.691878,10.60588 -44.082012,16.70167 -8.390135,6.09579 -19.135552,36.76339 -29.506329,36.76339 z"
					transform="matrix(1,0,0,-1,39.770259,243.36485)"
					transform-center-x="-2.3247776e-06"
					transform-center-y="-7.3331445"
					label="path2825"
				/>
				<path
					id="path2825-3"
					style={{
						fill: "url(#linearGradient5455)",
						stroke: "none",
						strokeWidth: "6.00009",
						strokeLinecap: "round",
						strokeLinejoin: "bevel",
						strokeDasharray: "none",
						strokeDashoffset: "0",
						strokeOpacity: "1",
						paintOrder: "normal",
					}}
					transform-center-x="1.663172e-06"
					transform-center-y="-4.6311016"
					d="M 56.195501 188.89493 C 56.560154 188.97063 56.922801 189.01031 57.283802 189.01031 C 68.749916 189.01031 82.153187 149.69677 91.429468 142.95715 C 100.70575 136.21754 142.23668 135.6187 145.7799 124.71378 C 149.32313 113.80886 116.07592 88.912565 112.5327 78.007643 C 108.98947 67.102721 121.25419 27.419948 111.97791 20.680336 C 102.70163 13.940724 68.749916 37.867317 57.283802 37.867318 C 56.94145 37.867318 56.576688 37.843063 56.195501 37.801856 L 56.195501 188.89493 z "
					transform="matrix(-0.63153135,0,0,-0.63153135,133.3649,206.4862)"
				/>
			</g>
		</svg>
	);

export default StarHalf;
