import { api } from "./index";

//!---------------------------------//
//! --- notification api routes --- //
//!---------------------------------//

// --- notifications --- //
export const getNotifications = (notificationData) =>
	api.patch(`/notifications/get`, notificationData);
export const markNotificationAsRead = (notification) =>
	api.patch(`/notifications/hasread/${notification}`);
export const dismissNotification = (notification) =>
	api.delete(`/notifications/dismiss/${notification}`);
export const subscribeToThread = (id, force, type) =>
	api.patch(`/notifications/thread/${force}/${id}?type=${type}`);

//! leader only
export const notifyMaintenance = () => api.post("/notifications/maintenance");
