// imports
import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";

// page styles
import "./style.scss";

import {
	displayRoleStyle,
	mapHighestRole,
	sortRoles,
} from "../../../functions/styleAppliers";
import defaultAvatar from "../../../assets/images/DefaultAvatar.webp";

const UserWidget = (props) => {
	const [barWidth, setBarWidth] = useState<number>(0);
	const [displayCopySymbol, setDisplayCopySymbol] = useState<boolean>(false);

	useEffect(() => {
		if (props?.showDisplayRoles === true && barWidth === 0)
			for (let i = 0; i <= 350; i++) {
				setBarWidth(i / 3.5);
			}
	}, [props?.showDisplayRoles, barWidth]);

	const userData = props.user;
	const username = userData?.username;
	const rank = parseInt(userData?.rank);
	const nextRankFraction =
		Math.max(userData?.experience, 0) / userData?.nextRankExperience;

	return (
		<div className="profile__upper__banner__widget">
			<div
				className="profile__upper__banner__widget__progress"
				title={`${Math.round(userData?.experience * 100) / 100}/${
					userData?.nextRankExperience
				} exp until next rank, ${
					Math.round(userData?.totalExperience * 100) / 100
				} total exp.`}>
				<div
					className="profile__upper__banner__widget__progress__bar"
					style={{
						width: nextRankFraction * barWidth + "%",
					}}
				/>
				<span className="profile__upper__banner__widget__progress__username">
					{username}
					<div style={{ mixBlendMode: "screen" }} className="yellow">
						Rank {rank}
					</div>
				</span>
			</div>
			<div
				className="profile__upper__banner__widget__roles"
				style={{
					opacity: props?.showDisplayRoles ? 1 : 0,
					transition: "opacity 0.6s ease-in-out",
					justifyContent:
						userData?.mainRoles?.length <= 2 ? "left" : "center",
				}}>
				{!userData?.verified ? (
					<span>
						<span
							className="main-roles"
							style={{
								...displayRoleStyle({
									loadValue: barWidth,
								}),
							}}>
							Unverified
						</span>
					</span>
				) : userData?.mainRoles?.length <= 0 ? (
					<span>
						<span
							className="main-roles"
							style={{
								...displayRoleStyle({
									loadValue: barWidth,
								}),
							}}>
							Member
						</span>
					</span>
				) : (
					sortRoles(userData?.mainRoles)
						?.slice(0, 6)
						.map((role, key) => (
							<span key={key}>
								<span
									className="main-roles"
									style={{
										...displayRoleStyle({
											loadValue: barWidth,
										}),
										color: mapHighestRole([role], "color"),
										borderColor: mapHighestRole(
											[role],
											"color"
										),
										boxShadow: `0 0 1px 1px ${mapHighestRole(
											[role],
											"color"
										)}`,
									}}>
									{role}
								</span>
							</span>
						))
				)}
			</div>
			<span
				className="profile__upper__banner__widget__icon"
				style={{
					borderColor: userData?.icon_outline,
					boxShadow: userData?.icon_glow
						? `0 0 4px 2px ${userData?.icon_glow}`
						: "none",
				}}>
				<img
					alt="User Avatar"
					className="profile__upper__banner__widget__icon__image clickable"
					style={{ pointerEvents: "auto" }}
					onClick={() => {
						toast.success("Copied user ID!");
						navigator.clipboard.writeText(userData?._id);
					}}
					onMouseEnter={() => setDisplayCopySymbol(true)}
					onMouseLeave={() => setDisplayCopySymbol(false)}
					onError={(e) => {
						// @ts-ignore
						e.target.src = defaultAvatar;
					}}
					src={userData?.avatar ? userData?.avatar : defaultAvatar}
				/>
				<div className="profile__upper__banner__widget__icon__image">
					<span
						className="material-icons avatar-icon__link"
						style={{
							opacity: displayCopySymbol ? "1" : "0",
							pointerEvents: "none",
						}}>
						content_copy
					</span>
				</div>
			</span>
		</div>
	);
};

export default UserWidget;
