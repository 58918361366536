import React from "react";
import ReactDOM from "react-dom";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";

import App from "./App";

// Make sure this is disabled during production - if needed during development, just comment this out for the moment.
disableReactDevTools();

ReactDOM.render(<App />, document.getElementById("root"));
