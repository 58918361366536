// react imports
import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import axios from "axios";

// user context import
import { UserContext } from "../../../App";

// types
import { FormData } from "./types";

/**
 * Request password reset form.
 *
 * @param {*} props
 * @returns
 */
const ResetPass = () => {
	const currentUserData = useContext<any>(UserContext);

	// state info
	const [formData, setFormData] = useState<FormData>({
		codeOrOldPass: "",
		newPassword: "",
		newPasswordConfirm: "",
	});

	useEffect(() => {
		document.title = "Reset Password - Level Share Square";
	}, []);

	// Boolean to prevent the user from making another password update query if there is already one ongoing
	let canSendQuery = true;

	// ---------------------- //
	// Handle form submission //
	// ---------------------- //
	const handleSubmit = async (e) => {
		// prevent form from submitting as normal
		e.preventDefault();

		if (!canSendQuery)
			return toast.error("There is already an ongoing request going.");

		// check if forms were entered
		if (
			formData.codeOrOldPass &&
			formData.newPassword &&
			formData.newPasswordConfirm
		) {
			if (formData.newPassword !== formData.newPasswordConfirm)
				return toast.error(
					"New password and confirm new password fields do not match."
				);

			/* -------------------------------------------------- */
			/*            WHEN THE USER IS LOGGED OUT             */
			/* Make an API call using the OTP (one-time password) */
			/*     route, without needing the current password    */
			/* -------------------------------------------------- */

			if (!currentUserData) {
				canSendQuery = false;

				await axios
					.post("/api/account/security/update_password_otp", {
						codeOrOldPass: formData.codeOrOldPass,
						newPassword: formData.newPassword,
						newPasswordConfirm: formData.newPasswordConfirm,
					})
					.then((res) => {
						toast.success(res.data);
						window.setTimeout(function () {
							// Move to the next page in the process of resetting password
							window.location.replace("/auth");
						}, 3000);
					})
					.catch((error) => {
						toast.error(error.response.data);
						canSendQuery = true;
					});
				/* -------------------------------------------------- */
				/*            WHEN THE USER IS LOGGED IN              */
				/* -------------------------------------------------- */
			} else {
				canSendQuery = false;

				await axios
					.post("/api/account/security/update_password", {
						userID: currentUserData._id,
						oldPassword: formData.codeOrOldPass,
						newPassword: formData.newPassword,
						newPasswordConfirm: formData.newPasswordConfirm,
					})
					.then((res) => {
						toast.success(res.data);
						window.setTimeout(function () {
							// Move to the next page in the process of resetting password
							// window.location.replace('/auth');
						}, 3000);
					})
					.catch((error) => {
						toast.error(error.response.data);
						canSendQuery = true;
					});
			}
		} else toast.error("Please fill in all the forms before proceeding.");
	};

	// handle changes in filling out form
	const handleChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	return (
		<>
			<div className="container mt-4 align-mid">
				<div className="card mx-auto">
					<div className="card-header">
						<span
							className="align-text-middle"
							style={{
								fontSize: "36px",
								top: "9px",
							}}>
							{currentUserData
								? "Update password"
								: "Reset Password"}
						</span>
						<p className="lead">
							{currentUserData
								? "Fill out the forms below to update your password."
								: "Your account recovery code should be sent to your email"}
						</p>
					</div>
					<div className="card-body">
						<form onSubmit={handleSubmit}>
							{/* Current password (when logged in) OR Recovery code (when logged out) */}
							<div className="form-group">
								<label
									htmlFor="username"
									className="label-adjust">
									{currentUserData
										? "Current Password"
										: "Recovery code"}
								</label>
								<input
									type="text"
									className="form-control"
									id="codeOrOldPass"
									name="codeOrOldPass"
									maxLength={32}
									value={formData.codeOrOldPass}
									onChange={handleChange}
								/>
							</div>
							<hr className="my-4" />
							{/* New password */}
							<div className="form-group">
								<label
									htmlFor="password"
									className="label-adjust">
									New Password
								</label>
								<input
									type="password"
									className="form-control"
									id="password"
									name="newPassword"
									maxLength={255}
									value={formData.newPassword}
									onChange={handleChange}
								/>
							</div>
							{/* Confirm new password */}
							<div className="form-group">
								<label
									htmlFor="passwordConfirm"
									className="label-adjust">
									Confirm New Password
								</label>
								<input
									type="password"
									className="form-control"
									id="passwordConfirm"
									name="newPasswordConfirm"
									maxLength={255}
									value={formData.newPasswordConfirm}
									onChange={handleChange}
								/>
							</div>
							<br />
							<button type="submit" className="btn btn-primary">
								Continue
							</button>
						</form>
					</div>
				</div>
				<br />
			</div>
		</>
	);
};

export default ResetPass;
