import { api } from "./index";

//!----------------------------------//
//! --- level comment api routes --- //
//!----------------------------------//

// level comments
export const getLevelComment = (id) => api.get(`/levels/comment/${id}`);
export const getLevelCommentsPage = (id, levelCommentsData) =>
	api.get(
		`/levels/${id}/comments?page=${levelCommentsData.page}&sort=${levelCommentsData?.sort}&type=${levelCommentsData?.type}`,
	);
export const getLevelCommentsSet = (id) =>
	api.get(`/levels/comments/users/${id}`);

export const createLevelComment = (id, levelCommentData) =>
	api.post(`/levels/${id}/comment`, levelCommentData);
export const editLevelComment = (id, contentID, commentData) =>
	api.patch(
		`/levels/${id}/comments/${contentID}/edit?type=level_comment`,
		commentData,
	);
export const deleteLevelComment = (levelID, commentID) =>
	api.patch(
		`/levels/${levelID}/comments/${commentID}/delete?type=level_comment`,
	);
export const obliterateLevelComment = (levelID, commentID) =>
	api.delete(
		`/levels/${levelID}/comments/${commentID}/obliterate?type=level_comment`,
	);
export const reactLevelComment = (commentID, levelCommentData) =>
	api.patch(
		`/levels/${levelCommentData.origin}/comments/${commentID}/react`,
		levelCommentData,
	);

// level comments - threads
export const getLevelCommentReply = (id, commentID) =>
	api.get(`/levels/${id}/comments/${commentID}`);
export const getLevelCommentReplyPage = (id, commentID, replyData) =>
	api.get(
		`/levels/${id}/comments/${commentID}?page=${replyData.page}&threadpage=${replyData.threadpage}`,
	);
export const createLevelCommentReply = (levelID, commentID, replyData) =>
	api.post(`/levels/${levelID}/comments/${commentID}/create`, replyData);
export const editLevelCommentReply = (levelID, contentID, commentData) =>
	api.patch(
		`/levels/${levelID}/comments/replies/${contentID}/edit?type=level_reply`,
		commentData,
	);
export const deleteLevelCommentReply = (replyID, commentID, levelID) =>
	api.patch(
		`/levels/${levelID}/comments/${commentID}/replies/${replyID}/delete?type=level_reply`,
	);
export const obliterateLevelCommentReply = (replyID, commentID, levelID) =>
	api.delete(
		`/levels/${levelID}/comments/${commentID}/replies/${replyID}/obliterate?type=level_reply`,
	);
