import React, { useEffect } from "react";

/**
 * Page containing community guidelines.
 *
 * @returns The site's guidelines page.
 */
const Guidelines = (props) => {
	useEffect(() => {
		document.title = "Guidelines - Level Share Square";
	}, []);

	return (
		<div className="container mt-4">
			<div className="col-12 col-lg-9 mx-auto">
				{/* Rules page introduction */}
				<div className="card">
					<div className="card-header">
						<img
							src={props.images.Exclamation}
							alt="Exclamation"
							width="48"
							height="48"
						/>
						<span
							className="align-middle"
							style={{ paddingLeft: "7px", fontSize: "36px" }}>
							Important info
						</span>
					</div>
					<div className="card-body">
						<p>
							We aim for people to have a good time here on Level
							Share Square! Please read and respect these rules
							carefully in order to make the community a welcoming
							place.
						</p>
						<p>
							If you want to report a member that you think has
							violated the site's guidelines, please use the{" "}
							<a href="/report">report form</a>. If you want to
							report something a member has posted, such as a
							level or review, you can also find report buttons
							located on these pages that will autofill the report
							form.
						</p>
						<p>
							Note: level/rate disputes should always be handled
							using the report form. If you sense something is
							serious and needs urgent attention by a staff
							member, you can contact a staff member on the{" "}
							<a href="https://discord.gg/GA48Y5TXwS">
								LSS Discord
							</a>
							.
						</p>
						<p>
							For more info on about how staff deals with reports,
							check out our <a href="/faq">FAQ page</a>, or{" "}
							<a href="/contact">ask us</a> your question if you
							can't find the answer.
						</p>
					</div>
				</div>
				<br />

				{/* Rules & guidelines list */}
				<div className="card">
					<div className="card-header">
						<span
							className="align-middle"
							style={{ paddingLeft: "7px", fontSize: "36px" }}>
							Rules & Guidelines
						</span>
					</div>
					<div className="card-body">
						<h2>1. Be respectful towards each other</h2>
						<p>
							This means we don't allow any form of harassment,
							discrimination, blatant trolling, disrespect based
							upon status, rioting or any other form of trying to
							cause drama/chaos. Extensive religious/political
							topics are also prohibited on the site.
						</p>
						<p>
							This includes bullying or directing hate at someone
							inside a level (e.g. using a Message Block or Toad).
						</p>

						<h2>2. No raiding / spamming</h2>
						<p>
							Examples of spamming include (but not limited to):
							repeatedly and purposefully posting spam levels,
							posting comments unrelated to the level on level
							pages, or spamming the report form.
						</p>

						<h2>3. Be appropriate / no swearing</h2>
						<p>
							Please refrain from using
							NSFW/graphic/jumpscare/heavily flashing content on
							our site, this includes content found inside levels
							(e.g. obscene custom backgrounds and/or music).
						</p>

						<h2>4. Only publish levels of our supported games</h2>
						<p>
							Posting levels in their beta versions or with any
							other type of beta content is prohibited as well,
							unless stated otherwise.
						</p>

						<h2>5. Don't cheat</h2>
						<p>
							Do not exploit site/game exploits and/or bugs, nor
							are you allowed to cheat on any
							contest/speedrun/event by any means. One active LSS
							account per person will be enforced in regard to
							this rule.
						</p>

						<h2>
							6. Rate fairly, criticism should be constructive
						</h2>
						<p>
							Please always try to rate without bias, rating a
							member's level lower because of personal grudges is
							not allowed.
						</p>

						<h2>7. Respect staff's decisions</h2>
						<p>
							Don't evade punishments nor harass staff for any
							moderative/administrative actions. You can contact
							staff management or submit a ticket if you think a
							staff member is abusing their power. Please check
							our <a href="/faq">FAQ page</a> or{" "}
							<a href="/contact">contact page</a> if you have any
							questions/concerns.
						</p>

						<h2>8. Age of registered users must be 13 or older.</h2>
						<p>
							Since the site doesn't have any proper tools for
							verification of parental consent, all users must be
							13 years of age or older in order to create a Level
							Share Square account. Any users who are found out
							bypassing this rule shall be permanently banned.
						</p>

						<h2>9. No plagiarism</h2>
						<p>
							Don't steal levels of anyone, conditions apply
							towards reuploads granted you have permission.
							Remakes of official Nintendo levels aren't allowed
							either, unless your level is transformative in its
							gameplay in opposition to its original.
						</p>
					</div>
				</div>
				<br />

				{/* External rules information */}
				<div className="card">
					<div className="card-header">
						<span
							className="align-middle"
							style={{ paddingLeft: "7px", fontSize: "36px" }}>
							Recommended guidelines
						</span>
					</div>
					<div className="card-body">
						<p>
							These aren't necessarily "rules," but more so tips
							on how you could participate in the community. We
							recommend you give them a read!
						</p>

						<h2>Respect & learn from low rates</h2>
						<p>
							Receiving a low rate on your level is very likely
							not the result you were hoping for. However, you
							should try to think about why people may have given
							your level a low rate. Sometimes people will state
							their reasoning in the comments.
						</p>
						<p>
							A core part of level designing is that it's a lot of
							trial and error - sometimes a concept works, and
							sometimes it just doesn't or isn't executed well
							enough, and that's okay! Like levels in games,
							learning level design is also a trip from start to
							finish with many obstacles.
						</p>

						<h2>Try to be helpful & constructive in comments</h2>
						<p>
							If you see a member that has repeatedly posted
							lowly-rated levels and appears to be struggling at
							trying to improve their levels, feel free to help
							them out by posting a comment on their level!
						</p>

						<p>
							Describe what is wrong with the level and what could
							be done to improve it. Remember though, keep it
							civil.
						</p>
					</div>
				</div>
				<br />
			</div>
		</div>
	);
};

export default Guidelines;
