import React, { useEffect, useRef } from "react";

/**
 * Terms of Service page used on the site.
 *
 * @returns The site's ToS page.
 */
const ToS = (props) => {
	useEffect(() => {
		document.title = "Terms of Service - Level Share Square";
	}, []);

	// section 1 refs
	const section1Ref = useRef(null);
	const section1_1Ref = useRef(null);
	const section1_2Ref = useRef(null);
	const section1_3Ref = useRef(null);
	const section1_4Ref = useRef(null);
	// section 2 refs
	const section2Ref = useRef(null);
	const section2_1Ref = useRef(null);
	const section2_2Ref = useRef(null);
	const section2_3Ref = useRef(null);
	// section 3 refs
	const section3Ref = useRef(null);
	// section 4 refs
	const section4Ref = useRef(null);
	// section 5 refs
	const section5Ref = useRef(null);
	// section 6 refs
	const section6Ref = useRef(null);
	const section6_1Ref = useRef(null);
	const section6_2Ref = useRef(null);
	const section6_3Ref = useRef(null);
	const section6_4Ref = useRef(null);
	const section6_5Ref = useRef(null);
	const section6_6Ref = useRef(null);
	// section 7 refs
	const section7Ref = useRef(null);
	// section 8 refs
	const section8Ref = useRef(null);
	// section 9 refs
	const section9Ref = useRef(null);

	// function used to scroll to a certain position above a given reference
	const scrollToRef = (ref) => {
		window.scrollTo({
			top: ref.current.getBoundingClientRect().top + window.scrollY - 100,
			behavior: "smooth",
		});
	};

	return (
		<div className="container mt-4">
			<div className="col-12 col-lg-9 mx-auto">
				{/* Top card, containing the ToS header */}
				<div className="card">
					<div className="card-header">
						<img
							src={props.images.Exclamation}
							alt="Exclamation"
							width="48"
							height="48"
						/>
						<span
							className="align-middle"
							style={{ paddingLeft: "7px", fontSize: "36px" }}>
							Terms of Service
						</span>
					</div>
					<div className="card-body">
						<strong>Last updated 20th of August, 2023</strong>
						<br />
						<br />
						<p>
							We are Level Share Square, a non-profit
							website/platform aimed at submitting user made
							content ("levels") in a various selection of level
							editors.
						</p>

						<p>
							You agree that by accessing the Services, you have
							read, understood, and agreed to be bound by all of
							these Legal Terms. If you do not agree with our
							Terms of Service, we suggest you refrain from using
							the website.
						</p>
					</div>
				</div>
				<br />
				<br />

				{/* Table of contents card */}
				<div className="card">
					<div className="card-header">
						<span
							className="align-middle"
							style={{ paddingLeft: "7px", fontSize: "36px" }}>
							Table of Contents
						</span>
					</div>
					<div className="card-body">
						<ul className="toc_list">
							<li>
								<a
									href="#!"
									onClick={() => scrollToRef(section1Ref)}>
									1. Your use of our Services
								</a>
								<ul>
									<li>
										<a
											href="#!"
											onClick={() =>
												scrollToRef(section1_1Ref)
											}>
											1.1 Your submissions and
											contributions
										</a>
									</li>
									<li>
										<a
											href="#!"
											onClick={() =>
												scrollToRef(section1_2Ref)
											}>
											1.2 Content Found Inside Levels
										</a>
									</li>
									<li>
										<a
											href="#!"
											onClick={() =>
												scrollToRef(section1_3Ref)
											}>
											1.3 Profiles
										</a>
									</li>
									<li>
										<a
											href="#!"
											onClick={() =>
												scrollToRef(section1_4Ref)
											}>
											1.4 Content Moderation
										</a>
									</li>
								</ul>
							</li>

							<li>
								<a
									href="#!"
									onClick={() => scrollToRef(section2Ref)}>
									2. Registrations
								</a>
								<ul>
									<li>
										<a
											href="#!"
											onClick={() =>
												scrollToRef(section2_1Ref)
											}>
											2.1 New registrations
										</a>
									</li>
									<li>
										<a
											href="#!"
											onClick={() =>
												scrollToRef(section2_2Ref)
											}>
											2.2 Account safety
										</a>
									</li>
									<li>
										<a
											href="#!"
											onClick={() =>
												scrollToRef(section2_3Ref)
											}>
											2.3 Usernames
										</a>
									</li>
								</ul>
							</li>

							<li>
								<a
									href="#!"
									onClick={() => scrollToRef(section3Ref)}>
									3. Platform Safety
								</a>
							</li>
							<li>
								<a
									href="#!"
									onClick={() => scrollToRef(section4Ref)}>
									4. Third-Party Services
								</a>
							</li>
							<li>
								<a
									href="#!"
									onClick={() => scrollToRef(section5Ref)}>
									5. Service Management
								</a>
							</li>
							<li>
								<a
									href="#!"
									onClick={() => scrollToRef(section6Ref)}>
									6. Privacy Policy
								</a>
								<ul>
									<li>
										<a
											href="#!"
											onClick={() =>
												scrollToRef(section6_1Ref)
											}>
											6.1 General note
										</a>
									</li>
									<li>
										<a
											href="#!"
											onClick={() =>
												scrollToRef(section6_2Ref)
											}>
											6.2 Data We Collect
										</a>
									</li>
									<li>
										<a
											href="#!"
											onClick={() =>
												scrollToRef(section6_3Ref)
											}>
											6.3 How We Use Your Data
										</a>
									</li>
									<li>
										<a
											href="#!"
											onClick={() =>
												scrollToRef(section6_4Ref)
											}>
											6.4 Requesting data deletion
										</a>
									</li>
									<li>
										<a
											href="#!"
											onClick={() =>
												scrollToRef(section6_5Ref)
											}>
											6.5 Maintaining data
										</a>
									</li>
									<li>
										<a
											href="#!"
											onClick={() =>
												scrollToRef(section6_6Ref)
											}>
											6.6 Third-Party Privacy Policies
										</a>
									</li>
								</ul>
							</li>
							<li>
								<a
									href="#!"
									onClick={() => scrollToRef(section7Ref)}>
									7. Terms and Terminations
								</a>
							</li>
							<li>
								<a
									href="#!"
									onClick={() => scrollToRef(section8Ref)}>
									8. Modifications and Interruptions
								</a>
							</li>
							<li>
								<a
									href="#!"
									onClick={() => scrollToRef(section9Ref)}>
									9. Contact
								</a>
							</li>
						</ul>
					</div>
				</div>
				<br />
				<br />

				{/* Card containing the lengthy part of the ToS */}
				<div className="card">
					<div
						className="card-body"
						style={{ borderRadius: "inherit" }}>
						<br />
						<h2 ref={section1Ref}>1. Your use of our Services</h2>

						<br />
						<b ref={section1_1Ref}>
							1.1 Your submissions and contributions
						</b>

						<p>
							When using our platform, please be aware of our{" "}
							<a href="/guidelines">community guidelines</a> to
							see what is prohibited on our platform. Continuous
							violations on your behalf will result in your access
							to the platform being revoked. Depending on the
							severity of the prohibited act, we serve the right
							to revoke your access instantly without prior
							warning.
						</p>

						<p>
							Submissions: By submitting any form of 'user-made
							content', whether they be levels, comments, or
							profile updates, you agree to not involve yourself
							into prohibited activities, as outlined by our
							guidelines.
						</p>

						<p>
							You understand that your Contributions
							('submissions') may be viewable by other users of
							the services, and possibly through third-party
							websites.
						</p>

						<p>
							When you post Contributions to our services, anybody
							with access to your Contributions has the right to
							interact with them, leave feedback, and share the
							content.
						</p>

						<p>
							Note that you are responsible for what you submit:
							do not publish any Contribution that is harmful
							towards another user or group, including hateful
							language, harassment, defamatory behavior or
							threats. Do not submit illegal, sexually explicit,
							deceitful, abusive, violent, shocking, or otherwise
							non-safe for work content to our platform.
						</p>

						<p>
							Do not submit content that contains personal
							information that can be used to identify you,
							someone else, or a group of people - this includes
							full real life names, home addresses, personal
							photos, IDs, or any confidential information.
						</p>

						<br />
						<b ref={section1_2Ref}>
							1.2 Content Found Inside Levels
						</b>

						<p>
							Any of the prohibited content mentioned in{" "}
							<a
								href="#!"
								onClick={() => scrollToRef(section1_1Ref)}>
								Section 1.1
							</a>{" "}
							also applies to content that can be found in your
							Contributions, in particular user-submitted
							'Levels'. This includes, but is not limited to:
						</p>

						<ul>
							<li>
								Images used as backgrounds 'Custom Backgrounds';
							</li>
							<li>Audio, known as 'Custom Music';</li>
							<li>
								Any form of written text, such as 'NPC
								dialogue'.
							</li>
						</ul>

						<br />
						<b ref={section1_3Ref}>1.3 Profiles</b>

						<p>
							You agree that you do not put personal information
							on your profile that could be used to identify you,
							someone else, or a group of people. All of the
							prohibited content mentioned in{" "}
							<a
								href="#!"
								onClick={() => scrollToRef(section1_1Ref)}>
								Section 1.1
							</a>{" "}
							also applies to user profiles. If you see someone
							that has prohibited content on their profile, you
							have the full right to contact a moderator about the
							situation.
						</p>

						<br />
						<b ref={section1_4Ref}>1.4 Content Moderation</b>

						<p>
							We reserve the right to remove any 'user-made
							content' that we believe violates our guidelines
							without prior notice.
						</p>

						<p>
							Depending on the severity of an offense, you may
							receive (temporary) penalties on your account, such
							as the inability to submit more 'user-made content'
							for a set period of time.
						</p>

						<br />
						<h2 ref={section2Ref}>2. Registrations</h2>

						<br />
						<b ref={section2_1Ref}>2.1 New registrations</b>

						<p>
							To access certain features on our platform, you are
							required to register and set up an account. You may
							only have one account on our services. If you are
							detected to have more than one account, they are
							subject to being removed, with an additional penalty
							on your 'main' account. You may only create an
							account if you are above the age of 13, as we do not
							have the tools as of now to support users below the
							age of COPPA compliance (Children's Online Privacy
							Protection Act).
						</p>

						<p>
							By registering an account on our platform, you agree
							to our Terms of Service, and that we store the
							information that you have provided to us on
							registration.
						</p>

						<br />
						<b ref={section2_2Ref}>2.2 Account safety</b>

						<p>
							<strong>Keep your password confidential</strong>.
							For improved security, we enforce passwords to be at
							least 6 characters long. We also recommend the
							following:
						</p>
						<ul>
							<li>
								Use both lowercase and uppercase characters in
								passwords. They're case-sensitive.
							</li>
							<li>
								Include a combination of letters, numbers and
								characters for a secure password.
							</li>
							<li>
								Use a different password than what you use on
								other services.
							</li>
						</ul>

						<br />
						<b ref={section2_3Ref}>2.3 Usernames</b>

						<p>
							We strongly recommend using a made-up username. Do
							not use full real-world names that could be used to
							identify you. If you wish to include your first
							name, please be aware of the risks involved.
						</p>

						<p>
							We reserve the right to remove or change a username
							if we are in the belief that it is inappropriate,
							used to impersonate, or contains too much personal
							information.
						</p>

						<br />
						<h2 ref={section3Ref}>3. Platform Safety</h2>
						<br />

						<p>
							To ensure a safe environment for all users of our
							site, registered or not, we require you to agree not
							to:
						</p>

						<u>
							<li>
								Trick, mislead or blackmail users, especially in
								any attempt to acquire sensitive account
								information such as user passwords.
							</li>
							<li>
								Abuse loopholes found on the site to perform
								malicious activity or anything that is against
								the guidelines. We ask that loopholes be
								submitted using our{" "}
								<a href="/contact">Contact</a> page.
							</li>
							<li>
								Harm the services using any external tools,
								whether it be exploits or making excessive
								requests.
							</li>
							<li>
								Use any information obtained from the Services
								in order to harass, abuse, or harm another
								person.
							</li>
							<li>
								Make improper use of our support services or
								submit false reports of abuse or misconduct.
							</li>
							<li>
								Upload or transmit (or attempt to upload or to
								transmit) viruses, Trojan horses, or other
								material that could harm the digital experience
								of our site and/or its end-users.
							</li>
							<li>
								Execute (automated) scripts on the website that
								intend to harm or cause the website to slow
								down.
							</li>
							<li>
								Attempt to impersonate another user or person,
								or use an almost identical username of another
								user.
							</li>
							<li>
								Harass, annoy, intimidate, or threaten any of
								our staff team and/or site members.
							</li>
							<li>
								Attempt to bypass any measures of the website
								designed to prevent or restrict access, or any
								portion thereof.
							</li>
							<li>
								Misuse the platform in a way it is not designed
								for.
							</li>
						</u>

						<br />
						<h2 ref={section4Ref}>4. Third-Party Services</h2>

						<br />
						<b id="section4.1">4.1 General note</b>

						<p>
							The Services may contain (or you may be sent to via
							our website) links to other websites ('Third-Party
							Websites').
						</p>

						<p>
							We are not responsible for any Third-Party Websites
							accessed through the Services or any Third-Party
							Content posted on, available through, or accessible
							from the Services, including the content, accuracy,
							offensiveness, opinions, reliability, privacy
							practices, or other policies of or contained in the
							Third-Party Websites or the Third-Party Content.
							Inclusion of, linking to, or permitting the use of
							any Third-Party Websites or any Third-Party Content
							does not imply approval or endorsement thereof by
							us. If you decide to leave the Services and access
							the Third-Party Websites or to use or install any
							Third-Party Content, you do so at your own risk, and
							you should be aware our Terms of Service does not
							apply on those sites. You should review the
							applicable terms and policies, including privacy and
							data gathering practices, of any website to which
							you navigate from the Services or relating to any
							applications you use or install from the Services.
							You agree and acknowledge that we do not endorse the
							products or services offered on Third-Party
							Websites.
						</p>

						<br />
						<b id="section4.2">4.2 Advertisements</b>

						<p>
							We allow advertisers to display their advertisements
							and other information on select areas of our
							website. We have no other relationship with
							advertisers other than providing the advertisement
							space on aforementioned pages.
						</p>

						<p>
							We aim to not use obtrusive advertisements. If you
							do see one, please feel free to make us aware.
						</p>

						<p>
							Please note that advertisements displayed on our
							website are NOT intended to gain profit, as they are
							purely to cover the costs of hosting our Service
							(the 'Level Share Square' website, the database
							costs, its servers, and any other external
							functionality that is part of our Service, such as
							the S.H.A.R.E. Discord bot).
						</p>

						<br />
						<h2 ref={section5Ref}>5. Service Management</h2>
						<br />

						<p>
							We reserve the right to monitor the Services for
							violations of these terms and take appropriate
							action against anyone who, in our belief, violates
							our terms, which includes our guidelines. We may
							revoke the ability to access our Services (or part
							thereof) from any user, remove 'levels' and any
							other 'user-made content' that in any way violate
							our terms or hinder our service on a technical
							level, and manage the Services in a manner to
							facilitate the proper functioning of the Services.
						</p>

						<br />
						<h2 ref={section6Ref}>6. Privacy Policy</h2>

						<br />
						<b ref={section6_1Ref}>6.1 General note</b>

						<p>
							We care a lot about data privacy and security. We
							again would like to remind you to avoid using and/or
							sharing personal data in any parts of the Services;
							this website is intended for level sharing first and
							foremost, and will not be used to obtain or build a
							collection of data about our members outside of our
							necessities for site functionality, administration
							and/or moderation.
						</p>

						<br />
						<b ref={section6_2Ref}>6.2 Data We Collect</b>

						<p>
							We collect and store the following data about our
							users:
						</p>

						<ul>
							<li>
								Usernames: these can be chosen by the user on
								registration, and are visible to anyone.
							</li>
							<li>
								Emails: used for account support and
								verification. They are only visible to Site
								Administrators.
							</li>
							<li>Hashed passwords</li>
							<li>
								'User-made content': content that is submitted
								by the user, provided to us, and then stored on
								our servers.
							</li>
							<li>
								IP-addresses: only visible to Site
								Administrators, and will require some portions
								of the site to function properly. In case of
								situations involving illegal content, medical
								emergencies or any other situation involving the
								law, this info may be used to contact the user's
								local authorities upon last resort.
							</li>
						</ul>

						<br />
						<b ref={section6_3Ref}>6.3 How We Use Your Data</b>

						<p>
							Our full set of Services (the 'Level Share Square'
							website, as well as its other services, such as
							S.H.A.R.E.), store user information, primarily
							'self-submitted user content', solely for functional
							reasons, and to provide the user with a place to
							store their Contributions.
						</p>

						<p>
							We do not sell or send any of your data to
							third-party services.
						</p>

						<br />
						<b ref={section6_4Ref}>6.4 Requesting data deletion</b>

						<p>
							You may request that all of your user data be
							deleted. We aim to abide by such requests. Please
							get in touch with us via our{" "}
							<a href="/contact">Contact</a> page.
						</p>

						<p>
							Please note that after your data has been deleted,
							you lose access to your account, as it no longer
							exists.
						</p>

						<br />
						<b ref={section6_5Ref}>6.5 Maintaining data</b>

						<p>
							We will maintain certain data that you submit to the
							Services for the purpose of providing functionality
							to the Services. Although we perform backups of
							data, you are ultimately responsible for keeping
							your content safe in the event that we lose your
							submitted content.
						</p>

						<br />
						<b ref={section6_6Ref}>
							6.6 Third-Party Privacy Policies
						</b>

						<p>
							When you navigate to another website not owned by us
							('Third-Party Websites'), you will no longer be
							subject to the Privacy Policy listed on this page,
							but of that of the third party. Please be aware that
							these other services may collect more types of data,
							and use them in different ways.
						</p>

						<br />
						<h2 ref={section7Ref}>7. Terms and Terminations</h2>
						<br />

						<p>
							These terms, including our guidelines, shall remain
							in full force and effect while you use the Services.
							We reserve the right to deny access to our Services,
							without prior notice, to ensure the Services remain
							a safe place for all users, and to facilitate the
							proper working functionality of the website. We have
							the right to ban certain IP addresses from using our
							Services.
						</p>

						<p>
							If we terminate or suspend your account for any
							reason, you are prohibited from registering and
							creating a new account under any name, whether it be
							your own, a fake or impersonated name, or a new name
							altogether.
						</p>

						<br />
						<h2 ref={section8Ref}>
							8. Modifications and Interruptions
						</h2>
						<br />

						<p>
							We may add to, update, or remove contents or
							features of the Services at any time or for any
							reason without notice.
						</p>

						<p>
							We cannot guarantee the Services will be available
							at all times. We may experience hardware, software,
							or other problems or need to perform maintenance
							related to the Services, resulting in interruptions,
							delays, or errors. We may change, revise, update,
							suspend, discontinue, or otherwise modify the
							Services at any time or for any reason without
							notice to you. We are not accountable for any loss
							of content or inconvenience caused by your inability
							to access or use the Services during any downtime or
							discontinuance of the Services.
						</p>

						<br />
						<h2 ref={section9Ref}>9. Contact</h2>
						<br />

						<p>
							If you'd like to contact us, please visit our{" "}
							<a href="/contact">Contact</a> page. Please note we
							may not always be able to answer you in a timely
							manner.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ToS;
