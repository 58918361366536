import React, { useState } from "react";
import defaultAvatar from "../../assets/images/DefaultAvatar.webp";
import { useHistory } from "react-router-dom";
import "./style.scss";

const UsernameAvatarDisplay = (props) => {
	const user = props.user;
	const setCardPosition = props.setCardPosition;
	const showCard = props.showCard;
	const setShowCard = props.setShowCard;
	const setHoverCardUser = props.setHoverCardUser;
	const [active, setActive] = useState(false);
	const history = useHistory();

	// clicking the username
	const handleAuthorClick = (e) => {
		e.preventDefault();
		e.stopPropagation();
		// check if the user is on a desktop device
		if (
			(window.innerWidth > 750 && window.innerHeight > 550) ||
			(showCard === user?._id && active === true)
		) {
			return history.push(`/users/${user?._id}`);
		}

		// function to trigger when on mobile
		setCardPosition({ x: e.clientX, y: e.clientY });
		setActive(true);
		return setShowCard(user?._id);
	};

	// hovering the username
	const handleHover = (e, show) => {
		e.preventDefault();
		e.stopPropagation();

		// set the user of the hover card
		setHoverCardUser(user);

		// don't fire if on mobile
		if (window.innerWidth <= 550 || window.innerHeight <= 550) return;

		// show card
		if (show === true) {
			setCardPosition({ x: e.clientX, y: e.clientY });
			return setShowCard(user?._id);
		}

		// hide card
		if (show === false) {
			return setShowCard(null);
		}
	};

	return (
		<a
			href={`/users/${user?._id}`}
			onMouseEnter={(e) => handleHover(e, true)}
			onMouseLeave={(e) => handleHover(e, false)}
			onClick={(e) => handleAuthorClick(e)}
			className="username-avatar-display">
			<span
				className="icon-avatar"
				style={{
					borderColor: user?.icon_outline,
					boxShadow: user?.icon_glow
						? `0 0 3px 1px ${user?.icon_glow}`
						: "none",
				}}>
				<img
					alt="Author Avatar"
					className="icon-avatar__image"
					onError={(e) => {
						// @ts-ignore
						e.target.src = defaultAvatar;
					}}
					src={user?.avatar ? user?.avatar : defaultAvatar}
				/>
			</span>
			{user?.username}&nbsp;{" "}
		</a>
	);
};

export default UsernameAvatarDisplay;
