import { api } from "./index";

//!--------------------------//
//! --- event api routes --- //
//!--------------------------//

// --- events --- //
export const getEvents = (page) => api.get("/events/get?page=" + page);
export const getEvent = (id) => api.get("/events/" + id + "/get");
export const getEventSubmissions = () => api.get("/events/submissions/get");
export const createEventSubmission = (submissionContent) =>
	api.post("/events/submissions/create", submissionContent);
