import React, { useState } from "react";
import "./style.scss";
import { voteOnPoll } from "../../api";
import { toast } from "react-toastify";
import removeExcessiveBreaks from "../../functions/removeExcessiveBreaks";

// process the content of an announcement into a poll
export const processPoll = (announcement) => {
	// dont execute if a poll is already present
	if (announcement?.poll || !announcement?.content) return announcement;
	// define variables
	const content = announcement?.content;
	let contentMatch, sanitizedContent, parsedContent;
	// first, remove any excessive breaks
	removeExcessiveBreaks(content);
	// look for a match with...
	//?		$poll{};
	contentMatch = content?.match(/\$poll([^;]*)/s);
	// if theres no match, return the announcement
	if (!contentMatch) return announcement;
	// replace any \n with empty space

	content?.replace(/\$poll{[^;]*};/s, "");
	sanitizedContent = contentMatch[1]
		?.replace(/[\n\t]/g, "")
		?.replace(/,}/g, "}");
	// check once more
	if (!sanitizedContent) return announcement;
	// parse a JSON of the returned object
	try {
		parsedContent = JSON.parse(sanitizedContent);
	} catch {
		return announcement;
	}
	// check if the options are present
	if (
		!Object.keys(parsedContent).some(
			(key) => key.includes("title") || key.includes("option")
		)
	)
		return announcement;

	// prepare for object merge
	parsedContent.poll = parsedContent.title;
	delete parsedContent.title;

	// merge objects and return it
	return { ...announcement, ...parsedContent };
};

export const trimPollContent = (announcement) => {
	// dont execute if a poll is already present
	if (!announcement?.content) return undefined;
	// define variables
	const content = announcement?.content;
	let contentMatch;
	// first, remove any excessive breaks
	removeExcessiveBreaks(content);
	// look for a match with...
	//?		$poll{};
	contentMatch = content?.match(/\$poll([^;]*)/s);
	// if theres no match, return the announcement
	if (!contentMatch) return announcement?.content;
	// replace any \n with empty space

	const updatedContent = content?.replace(/\$poll{[^;]*};/s, "");
	return updatedContent;
};

// return a poll
export const PollChart = (props) => {
	// state handler for checked options
	const [selectedOptions, setSelectedOptions] = useState<any[]>([]);
	const [lockSubmit, setLockSubmit] = useState<boolean>(false);

	// get the total amount of votes on the poll
	const amountOfVotes = () => {
		let voteCount = 0;
		Object.keys(props?.announcement)
			.filter((object) => object?.includes("votes"))
			.forEach((votesKey) => {
				const votes = props?.announcement[votesKey]?.length;
				voteCount += votes || 0;
			});
		return voteCount;
	};

	// make a backend call for voting
	const handleVote = (e) => {
		e.preventDefault();
		// submitting the form requires to have selected something
		if (selectedOptions?.length === 0)
			return toast.error("Please select an option first.");

		if (props.currentUserData === null)
			return toast.error("You must log in to do that!");

		setLockSubmit(true);
		// backend request
		if (props.mode === "single")
			// ... for setannouncement (one)
			return voteOnPoll(props?.announcement?._id, {
				...props?.announcement,
				selectedOptions,
			})
				.then((response) => {
					props.setAnnouncement((prevState) => {
						return {
							...prevState,
							...response?.data?.updatedVotes,
						};
					});
					// reset values
					setSelectedOptions([]);
					setLockSubmit(false);
				})
				.catch(() => setLockSubmit(false));
		// backend call
		if (props.mode === "multiple")
			// ...for setAnnouncements (more)
			return voteOnPoll(props?.announcement?._id, {
				...props?.announcement,
				selectedOptions,
			})
				.then((response) => {
					props.setAnnouncement((prevState) => {
						return prevState.map((prevAnnouncement) => {
							if (
								prevAnnouncement._id === props.announcement._id
							) {
								return {
									...prevAnnouncement,
									...response?.data?.updatedVotes,
								};
							}
							return prevAnnouncement;
						});
					});
					// reset values
					setSelectedOptions([]);
					setLockSubmit(false);
				})
				.catch(() => setLockSubmit(false));
	};

	return (
		<>
			<hr />
			<div>
				<h4 className="center-textoutput">
					<b>{props?.announcement?.poll}</b>
				</h4>
				<form onSubmit={(e) => handleVote(e)}>
					{
						//map all options present in the object
						Object.keys(props?.announcement)
							.filter((object) => object?.includes("option"))
							.map((option, key) => {
								// used for getting the votes attached to each option
								const votes = `votes${key + 1}`;

								if (key > 4) return null;

								return (
									<div
										key={key}
										className="row"
										style={{ margin: "0 8px 0 8px" }}>
										<div
											className="poll-bar"
											style={{
												overflow: "hidden",
												color: props.announcement[
													votes
												].includes(
													props?.currentUserData?._id
												)
													? "yellow"
													: "white",
											}}>
											<div
												style={{
													// adjust offset
													left: props.isThread
														? "16px"
														: "24px",
													width: `calc(${
														!props?.announcement[
															votes
														]?.length
															? 0
															: (props
																	?.announcement[
																	votes
															  ]?.length /
																	amountOfVotes()) *
															  100
													}% - ${
														// adjust width based upon thread/regular
														props.isThread
															? "32px"
															: "48px"
													})`,
												}}
												className="bar poll-backbar"
											/>
											<span
												style={{
													paddingRight: "8px",
													paddingLeft: "4px",
												}}>
												<label>
													{props?.showVote ===
														true && (
														<input
															type="checkbox"
															className="form-check-input"
															value={votes}
															checked={selectedOptions?.includes(
																votes
															)}
															onChange={(e) => {
																const isChecked =
																	e.target
																		.checked;
																if (isChecked) {
																	// Add the option to selectedOptions
																	setSelectedOptions(
																		(
																			prevSelectedOptions
																		) => [
																			...prevSelectedOptions,
																			votes,
																		]
																	);
																} else {
																	// Remove the option from selectedOptions
																	setSelectedOptions(
																		(
																			prevSelectedOptions
																		) =>
																			prevSelectedOptions.filter(
																				(
																					selectedOption
																				) =>
																					selectedOption !==
																					votes
																			)
																	);
																}
															}}
														/>
													)}
												</label>
											</span>
											<span
												style={
													props?.showVote === true
														? {}
														: {
																position:
																	"relative",
																top: "10px",
														  }
												}>
												{props?.announcement[option]}
											</span>
											<span
												style={{
													float: "right",
													zIndex: "10",
												}}>
												{props?.announcement[votes]
													?.length || 0}
												&nbsp; (
												{!props?.announcement[votes]
													?.length
													? 0
													: (
															(props
																?.announcement[
																votes
															]?.length /
																amountOfVotes()) *
															100
													  ).toFixed(2)}
												%)
											</span>
										</div>
									</div>
								);
							})
					}
					{props?.showVote && (
						<div style={{ width: "max-content", margin: "0 auto" }}>
							<button
								// @ts-ignore
								href="#!"
								type="submit"
								disabled={lockSubmit}
								className="btn btn-primary btn-lg">
								Vote
							</button>
						</div>
					)}
				</form>
			</div>
		</>
	);
};
