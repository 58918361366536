import { api } from "./index";

//!--------------------------------//
//! --- general app api routes --- //
//!--------------------------------//

// --- homepage --- //
export const getHomepage = (homepageData) =>
	api.get(`/app/homepage/get?youtube=${homepageData.getYouTubeVideo}`);

// - passive checks - //
export const pingServer = (mode, hidden) =>
	api.post(`/app/intervals/${mode}?hidden=${hidden}`);
