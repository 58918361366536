import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toast } from "react-toastify";
import { switchEditState } from "../../functions/commentHelpers";

// display buttons JSX component, used for primarily comments
const DisplayButtons = ({
	elementsToDisplay, // must be passed in as array
	comment,
	currentUserData,
	setThreadModalShow,
	setTargetComment,
	handleDeletion,
	authorIsAdmin,
	type, // must be passed in to properly set a thread or comment
	setComments,
}) => {
	const userIsStaff = currentUserData?.isStaff;
	const userIsAdmin = currentUserData?.isAdmin;
	const modifiers = {
		modifiers: [
			{
				name: "offset",
				options: {
					offset: [0, 16],
				},
			},
		],
	};

	const showThread = elementsToDisplay?.includes("thread");
	const showEdit = elementsToDisplay?.includes("edit");
	const showDelete = elementsToDisplay?.includes("delete");
	const showObliterate = elementsToDisplay?.includes("obliterate");
	const showCopy = elementsToDisplay?.includes("copy");
	// const showLink = elementsToDisplay?.includes("link");

	// compatability with replies
	if (comment?.deleted === true) comment.visibility = "deleted";

	return (
		<div
			className="thread-icon-container"
			style={{ position: "relative", right: "4px" }}>
			{showThread ? (
				<>
					<OverlayTrigger
						delay={{ show: 210, hide: 0 }}
						placement="top"
						overlay={<Tooltip id="replies">Replies</Tooltip>}
						popperConfig={modifiers}>
						<span className="align-middle thread-number">
							{/* replycount for comments */}
							{comment.replyCount
								? comment.replyCount -
								  (comment?.deleted_replies?.length || 0)
								: comment?.replies?.length -
										(comment?.deleted_replies?.length ||
											0) || 0}
						</span>
					</OverlayTrigger>
					<OverlayTrigger
						delay={{ show: 210, hide: 0 }}
						placement="top"
						overlay={<Tooltip id="openThread">Open thread</Tooltip>}
						popperConfig={modifiers}>
						<span className="align-middle">
							<a
								onClick={() => {
									setThreadModalShow(true);
									setTargetComment(comment);
								}}
								href={"#!"}
								className="material-symbols-outlined thread-icon blue">
								comment
							</a>
						</span>
					</OverlayTrigger>
				</>
			) : null}
			{showCopy ? (
				<OverlayTrigger
					delay={{ show: 210, hide: 0 }}
					placement="top"
					overlay={<Tooltip id="copyLink">Copy link</Tooltip>}
					popperConfig={modifiers}>
					{/* copy comment link */}
					<span className="align-middle">
						<a
							href={`#!`}
							onClick={() => {
								toast.success("Copied announcement link!");
								navigator.clipboard.writeText(
									window.location.origin +
										window.location.pathname
								);
							}}>
							<span className="material-symbols-outlined thread-icon">
								content_copy
							</span>
						</a>
					</span>
				</OverlayTrigger>
			) : null}
			{
				// edit button
				((userIsStaff && !authorIsAdmin) ||
					currentUserData?._id === comment?.author ||
					userIsAdmin) &&
					showEdit &&
					comment?.visibility !== "deleted" && (
						<OverlayTrigger
							delay={{
								show: 210,
								hide: 0,
							}}
							placement="top"
							popperConfig={modifiers}
							overlay={
								<Tooltip id="editComment">Edit comment</Tooltip>
							}>
							<span className="align-middle">
								<a
									href="#!"
									onClick={() => {
										switchEditState(
											comment?._id,
											setComments
										);
									}}>
									<span className="material-symbols-outlined thread-icon">
										edit
									</span>
								</a>
							</span>
						</OverlayTrigger>
					)
			}
			{/* button to delete a comment */}
			{(currentUserData?.isStaff ||
				currentUserData?._id === comment?.author) &&
			comment?.visibility !== "deleted" &&
			showDelete ? (
				<>
					<OverlayTrigger
						delay={{ show: 210, hide: 0 }}
						placement="top"
						overlay={<Tooltip id="delete">Delete</Tooltip>}
						popperConfig={modifiers}>
						<span className="align-middle">
							<a
								href="#!"
								onClick={(e) => {
									handleDeletion(e, `delete${type}`, comment);
									if (setThreadModalShow)
										setThreadModalShow(false);
								}}>
								<span className="material-symbols-outlined thread-icon">
									delete
								</span>
							</a>
						</span>
					</OverlayTrigger>
				</>
			) : currentUserData?.isAdmin &&
			  comment?.visibility === "deleted" &&
			  showObliterate ? (
				<>
					<OverlayTrigger
						delay={{ show: 210, hide: 0 }}
						placement="top"
						overlay={<Tooltip id="obliterate">OBLITERATE</Tooltip>}
						popperConfig={modifiers}>
						<span className="align-middle">
							<a
								href="#!"
								onClick={(e) => {
									handleDeletion(
										e,
										`obliterate${type}`,
										comment
									);
									if (setThreadModalShow)
										setThreadModalShow(false);
								}}>
								<span className="material-symbols-outlined thread-icon hydrawisp-red">
									local_fire_department
								</span>
							</a>
						</span>
					</OverlayTrigger>
				</>
			) : null}
		</div>
	);
};

export default DisplayButtons;
