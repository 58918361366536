import { api } from "./index";

//!---------------------------------//
//! --- announcement api routes --- //
//!---------------------------------//

// --- announcements --- //
export const fetchAnnouncements = () => api.get(`/announcements/get`);
export const getAnnouncementById = (id) => api.get(`/announcements/${id}/get`);
export const createAnnouncement = (announcementData) =>
	api.post(`/announcements/post`, announcementData);
export const editAnnouncement = (contentID, announcementData) =>
	api.patch(
		`/announcements/${contentID}/edit?type=announcement`,
		announcementData,
	);
export const deleteAnnouncement = (contentID) =>
	api.delete(`/announcements/${contentID}/delete?type=announcement`);
export const reactAnnouncement = (announcementID, mode) =>
	api.patch(`/announcements/${announcementID}/react/${mode}`);
export const voteOnPoll = (announcementID, announcementData) =>
	api.patch(`/announcements/${announcementID}/vote`, announcementData);
export const getAnnouncementsPage = (announcementsData) =>
	api.get(`/announcements/get?page=${announcementsData.page}`);
export const toggleAnnouncementLock = (contentID) =>
	api.patch(`/announcements/${contentID}/lock?type=announcement`);

// --- announcements:  threads --- //
export const getAnnouncementReply = (announcementID) =>
	api.get(`/announcements/${announcementID}/replies`);
export const getAnnouncementReplyPage = (announcementID, replyData) =>
	api.get(
		`/announcements/${announcementID}/replies?threadpage=${replyData.threadpage}`,
	);
export const createAnnouncementReply = (announcementID, replyData) =>
	api.post(`/announcements/${announcementID}/replies/create`, replyData);
export const editAnnouncementReply = (announcementID, contentID, replyData) =>
	api.patch(
		`/announcements/${announcementID}/replies/${contentID}/edit?type=announcement_reply`,
		replyData,
	);
export const deleteAnnouncementReply = (replyID, announcementID) =>
	api.patch(
		`/announcements/${announcementID}/replies/${replyID}/delete?type=announcement_reply`,
	);
export const obliterateAnnouncementReply = (replyID, announcementID) =>
	api.delete(
		`/announcements/${announcementID}/replies/${replyID}/obliterate`,
	);
