// react imports
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";

// constants
import { LocalStorageConstants } from "../../constants";

// page styles
import "./style.scss";

// components
import { register, login } from "../../api";

// types
import { FormData } from "./types";

/**
 * Registration/login form.
 *
 * @param {*} props
 * @returns
 */
const Auth = (props) => {
	// state info
	const [lockAction, setLockAction] = useState<boolean>(false);
	const [regStatus, setRegStatus] = useState<boolean>(false);
	const [formData, setFormData] = useState<FormData>({
		username: "",
		email: "",
		password: "",
		passwordConfirm: "",
		verifiedCaptcha: false, // Only checked for when registering, not logging in.
	});

	// hook initializations
	const history = useHistory();

	useEffect(() => {
		document.title = `${
			regStatus ? "Register" : "Login"
		} - Level Share Square`;
	}, [regStatus]);

	// ---------------------- //
	// Handle form submission //
	// ---------------------- //
	const handleSubmit = (e) => {
		// prevent form from submitting as normal
		e.preventDefault();

		if (lockAction === true) return;
		setLockAction(true);
		// if this is a registration form, check all fields
		if (regStatus) {
			if (
				formData.username &&
				formData.email &&
				formData.password &&
				formData.passwordConfirm &&
				formData.verifiedCaptcha
			)
				return register(formData)
					.then((response) => {
						// set token in local storage
						localStorage.setItem(
							LocalStorageConstants.Sessions.LOGIN_NAME,
							response.data.token
						);

						// redirect to verification page
						history.push("/verify");
						localStorage.setItem(
							LocalStorageConstants.Toasts.MESSAGE,
							"Welcome to a new fantasy!"
						);
						window.location.reload();
					})
					.finally(() => setLockAction(false));
		} else {
			// otherwise only check the ones needed to login
			if (formData.email && formData.password)
				return login(formData)
					.then((response) => {
						localStorage.setItem(
							LocalStorageConstants.Sessions.LOGIN_NAME,
							response.data.token
						);
						// redirect to previous page if one exists
						const redirectUrl =
							new URLSearchParams(window.location.search).get(
								"url"
							) ?? "";
						if (
							["/auth", "/resetpass", "/pagenotfound"].includes(
								redirectUrl
							)
						) {
							history.push("/");
						} else if (redirectUrl && redirectUrl.startsWith("/")) {
							history.push(redirectUrl);
						} else {
							history.push("/");
						}

						// set toast message in local storage, executed at bottom of page
						// for every toast message upon page load.
						localStorage.setItem(
							LocalStorageConstants.Toasts.MESSAGE,
							"Your journey continues!"
						);
						window.location.reload();
					})
					.finally(() => setLockAction(false));
			setLockAction(false);
			toast.error(
				"Please properly fill in all the required fields before submitting!"
			);
		}
	};

	// handle changes in filling out form
	const handleChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	// This fires when the ReCAPTCHA has successfully been ticked.
	async function handleCaptchaUpdate(value) {
		// Communicate to the backend to perform a second check
		await axios
			.get("/api/recaptcha/", {
				params: { token: value },
			})
			.then((response) => {
				// If True is returned, ReCAPTCHA validation has succeeded, and the registration form can be submitted
				if (response.data === true) {
					setFormData({ ...formData, verifiedCaptcha: true });
				}
			});
	}

	return (
		<>
			{/* large screens */}
			<div className="container mt-4 d-none d-lg-block align-mid">
				{/* only show header if this is a registration page */}
				{regStatus && (
					<>
						<div className="alert alert-secondary">
							<p className="lead">
								Create an account to start posting levels!
							</p>
							<hr className="my-4" />
							<img
								src={props.images.Exclamation}
								alt="Exclamation"
								className="exclamation"
							/>
							<span className="align-text-middle">
								Before registering, be sure to read our{" "}
								<a href="/tos">Terms of Service</a>.
							</span>
						</div>
						<br />
					</>
				)}

				<div className="card mx-auto">
					<div className="card-header">
						<img
							src={props.images.LSSLogoTransparent}
							width="70"
							height="60"
							alt="LSS Logo"
						/>
						<span
							className="align-text-middle"
							style={{
								fontSize: "36px",
								position: "relative",
								top: "9px",
							}}>
							{regStatus ? "Register" : "Login"}
						</span>
					</div>
					<div className="card-body">
						<form onSubmit={handleSubmit}>
							{/* only ask for username if user is registering */}
							{regStatus && (
								<div className="form-group">
									<label
										htmlFor="username"
										className="label-adjust">
										Username
									</label>
									<input
										type="text"
										className="form-control"
										id="username"
										name="username"
										maxLength={32}
										value={formData.username}
										onChange={handleChange}
									/>
								</div>
							)}
							<div className="form-group">
								<label htmlFor="email" className="label-adjust">
									Email
								</label>
								<input
									type="email"
									className="form-control"
									id="email"
									name="email"
									maxLength={100}
									value={formData.email}
									onChange={handleChange}
								/>
							</div>

							<div className="form-group">
								<label
									htmlFor="password"
									className="label-adjust">
									Password
								</label>
								<input
									type="password"
									className="form-control"
									id="password"
									name="password"
									maxLength={255}
									value={formData.password}
									onChange={handleChange}
								/>
								{!regStatus && (
									<label>
										<a href="/forgotpass">
											Forgot password?
										</a>
									</label>
								)}
							</div>
							{/* only ask for password confirmation & ReCAPTCHA if user is registering */}
							{regStatus && (
								<div className="form-group">
									<label
										htmlFor="passwordConfirm"
										className="label-adjust">
										Confirm Password
									</label>
									<input
										type="password"
										className="form-control"
										id="passwordConfirm"
										name="passwordConfirm"
										maxLength={255}
										value={formData.passwordConfirm}
										onChange={handleChange}
									/>
									<label>
										<ReCAPTCHA
											sitekey="6LezV9ElAAAAAEm93SBxKkO6-ZFiKaewaqfTWHUt"
											onChange={handleCaptchaUpdate}
										/>
									</label>
								</div>
							)}
							<br />
							<button
								type="submit"
								disabled={lockAction}
								className="btn btn-primary">
								{regStatus ? "Register" : "Login"}
							</button>
							<br />
							<br />
							{regStatus ? (
								<span className="align-text-middle">
									Already have an account?{" "}
									<a
										href="#!"
										onClick={() => setRegStatus(false)}>
										Login
									</a>
								</span>
							) : (
								<span className="align-text-middle">
									Don't have an account yet?{" "}
									<a
										href="#!"
										onClick={() => setRegStatus(true)}>
										Register
									</a>
								</span>
							)}
						</form>
					</div>
				</div>
				<br />
			</div>

			{/* small screens */}
			<div className="container mt-12 d-lg-none content-adjust align-mid">
				{/* only show header if this is a registration page */}
				{regStatus && (
					<>
						<div className="alert alert-secondary">
							<p className="lead">
								Create an account to start posting levels!
							</p>
							<hr className="my-4" />

							<img
								src={props.images.Exclamation}
								alt="Exclamation"
								className="exclamation"
							/>
							<span
								className="align-text-middle"
								style={{ fontSize: "16px" }}>
								Before registering, read our{" "}
								<a href="/tos">Terms of Service</a>.
							</span>
						</div>
						<br />
					</>
				)}

				<div className="card mx-auto">
					<div className="card-header">
						<div>
							<img
								src={props.images.LSSLogoTransparent}
								width="70"
								height="60"
								alt="LSS Logo"
							/>
							<span
								className="align-text-middle"
								style={{
									fontSize: "36px",
									position: "relative",
									top: "9px",
								}}>
								{regStatus ? "Register" : "Login"}
							</span>
						</div>
					</div>
					<div className="card-body">
						<form onSubmit={handleSubmit}>
							{/* only ask for username if user is registering */}
							{regStatus && (
								<div className="form-group">
									<label
										htmlFor="username"
										className="label-adjust">
										Username
									</label>
									<input
										type="text"
										className="form-control"
										id="username"
										name="username"
										maxLength={100}
										value={formData.username}
										onChange={handleChange}
									/>
								</div>
							)}
							<div className="form-group">
								<label htmlFor="email" className="label-adjust">
									Email
								</label>
								<input
									type="email"
									className="form-control"
									id="email"
									name="email"
									maxLength={100}
									value={formData.email}
									onChange={handleChange}
								/>
							</div>

							<div className="form-group">
								<label
									htmlFor="password"
									className="label-adjust">
									Password
								</label>
								<input
									type="password"
									className="form-control"
									id="password"
									name="password"
									maxLength={255}
									value={formData.password}
									onChange={handleChange}
								/>
								{!regStatus && (
									<label>
										<a href="/forgotpass">
											Forgot password?
										</a>
									</label>
								)}
							</div>
							{/* only ask for password confirmation & ReCAPTCHA if user is registering */}
							{regStatus && (
								<div className="form-group">
									<label
										htmlFor="passwordConfirm"
										className="label-adjust">
										Confirm Password
									</label>
									<input
										type="password"
										className="form-control"
										id="passwordConfirm"
										name="passwordConfirm"
										maxLength={255}
										value={formData.passwordConfirm}
										onChange={handleChange}
									/>
									<label>
										<ReCAPTCHA
											sitekey="6LezV9ElAAAAAEm93SBxKkO6-ZFiKaewaqfTWHUt"
											onChange={handleCaptchaUpdate}
										/>
									</label>
								</div>
							)}
							<br />
							<button type="submit" className="btn btn-primary">
								{regStatus ? "Register" : "Login"}
							</button>
							<br />
							<br />
							{regStatus ? (
								<span className="align-text-middle">
									Already have an account?{" "}
									<a
										href="#!"
										onClick={() => setRegStatus(false)}>
										Login
									</a>
								</span>
							) : (
								<span className="align-text-middle">
									Don't have an account yet?{" "}
									<a
										href="#!"
										onClick={() => setRegStatus(true)}>
										Register
									</a>
								</span>
							)}
						</form>
					</div>
				</div>
				<br />
			</div>
		</>
	);
};

export default Auth;
