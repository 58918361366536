import React from "react";

const StarFull = ({ width, height, color }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 180 245"
			version="1.1"
			id="svg5"
			// @ts-ignore
			sodipodidocname="star_full.svg"
			inkscapeversion="1.2.2 (732a01da63, 2022-12-09)"
			xmlnsinkscape="http://www.inkscape.org/namespaces/inkscape"
			xmlnssodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
			xmlns="http://www.w3.org/2000/svg"
			xmlnssvg="http://www.w3.org/2000/svg">
			{/* @ts-ignore */}
			<namedview
				id="namedview7"
				pagecolor="#ffffff"
				bordercolor="#000000"
				borderopacity="0.25"
				showpageshadow="2"
				pageopacity="0.0"
				pagecheckerboard="0"
				deskcolor="#d1d1d1"
				documentunits="mm"
				showgrid="true"
				zoom="0.72515432"
				cx="37.922962"
				cy="612.28346"
				windowwidth="1920"
				windowheight="1001"
				windowx="-9"
				windowy="-9"
				windowmaximized="1"
				currentlayer="svg5">
				{/* @ts-ignore */}
				<grid type="xygrid" id="grid767" />
				{/* @ts-ignore */}
			</namedview>
			<defs id="defs2" />
			{/* @ts-ignore */}
			<g label="Laag 1" groupmode="layer" id="layer1">
				<path
					type="star"
					style={{
						opacity: 1,
						fill: "#241c1c",
						stroke: "#ffffff",
						strokeWidth: 6,
						strokeLinecap: "round",
						strokeLinejoin: "round",
						strokeMiterlimit: 20,
						strokeDasharray: "none",
						strokeDashoffset: 0,
						strokeOpacity: 1,
						paintOrder: "normal",
					}}
					id="path2825"
					// @ts-ignore
					flatsided="false"
					sides="5"
					cx="57.283787"
					cy="95.959457"
					r1="77.375358"
					r2="50.199165"
					arg1="1.5707963"
					arg2="2.1991148"
					rounded="0.22"
					randomized="0"
					d="m 57.283789,173.33482 c -10.370777,0 -21.116194,-30.66759 -29.506329,-36.76338 -8.390135,-6.09579 -40.877265,-6.83848 -44.082012,-16.70168 C -19.509298,110.00657 6.3367967,90.310259 9.5415426,80.447065 12.746289,70.58387 3.4135559,39.457269 11.80369,33.361479 c 8.390134,-6.09579 35.109318,12.398813 45.480094,12.398813 10.370777,0 37.089961,-18.494605 45.480096,-12.398816 8.39013,6.095789 -0.9426,37.222389 2.26215,47.085583 3.20475,9.863195 29.05084,29.559501 25.8461,39.422701 -3.20475,9.86319 -35.691878,10.60588 -44.082012,16.70167 -8.390135,6.09579 -19.135552,36.76339 -29.506329,36.76339 z"
					transform-center-y="-7.3331445"
					transform="matrix(1,0,0,-1,39.770259,243.36485)"
					transform-center-x="-2.3247776e-06"
				/>
				<path
					type="star"
					style={{
						fill: color ? color : "#ffcc00",
						stroke: "none",
						strokeWidth: 6.00009,
						strokeLinecap: "round",
						strokeLinejoin: "bevel",
						strokeDasharray: "none",
						strokeDashoffset: 0,
						strokeOpacity: 1,
						paintOrder: "normal",
					}}
					id="path2825-3"
					// @ts-ignore
					flatsided="false"
					sides="5"
					cx="57.283787"
					cy="95.959457"
					r1="93.050522"
					r2="58.092331"
					arg1="1.5707963"
					arg2="2.1991148"
					rounded="0.2"
					randomized="0"
					d="m 57.283789,189.00998 c -11.466125,0 -24.869526,-39.31322 -34.145816,-46.05284 -9.276291,-6.73962 -50.807263,-7.33855 -54.350491,-18.24349 C -34.755745,113.80872 -1.5085308,88.912875 2.0346964,78.007941 5.5779237,67.103008 -6.6862297,27.419625 2.5900603,20.680005 11.86635,13.940386 45.81766,37.867127 57.283785,37.867126 c 11.466125,0 45.417435,-23.926743 54.693725,-17.187124 9.27629,6.739619 -2.98786,46.423003 0.55537,57.327936 3.54322,10.904933 36.79044,35.800772 33.24721,46.705712 -3.54322,10.90493 -45.0742,11.50387 -54.350487,18.24349 -9.27629,6.73962 -22.679689,46.05284 -34.145814,46.05284 z"
					transform-center-y="-4.6311016"
					transform="matrix(-0.63153135,0,0,-0.63153135,133.23055,206.4862)"
					transform-center-x="1.663172e-06"
				/>
			</g>
		</svg>
	);
};

export default StarFull;
