/**
 * Sleep for "ms" milliseconds.
 *
 * @param {*} ms
 * @returns
 */
export const sleep = (ms: number) => {
	return new Promise((resolve) => {
		setTimeout(resolve, ms);
	});
};

/**
 * Calculate the rewards for a level based upon its properties
 *
 * @param {*} collectedRewards
 * @returns experience, shimflakes, caskets
 */
export const calculateLevelRewards = (level) => {
	// get the game of the level
	const resultObj: any = {
		plays: parseInt(level?.plays[0]) || 0,
		rates: level?.rates || [],
		rating: level?.rating || 2.5,
		favourites: level?.favourites || 0,
		commenters: level?.commenters?.length || 0,
		experience: 0,
		shimflakes: 0,
		caskets: 0,
		specials: [],
	};
	// push specials
	switch (true) {
		case level?.rating >= 4.9 && level?.rates?.length > 5:
			resultObj?.specials?.push("greenstars");
			break;
	}
	// loop over objects to remove excessive values
	level?.collectedRewards?.forEach((object) => {
		// update the calcObj
		resultObj.specials = level?.specials?.filter(
			(special) => !object?.specials?.includes(special)
		);
		resultObj.plays -= object?.plays || 0;
		resultObj.rates = resultObj?.rates?.slice(
			object?.rates?.length,
			resultObj?.rates?.length
		);
		resultObj.favourites -= object?.favourites;
		resultObj.commenters -= object?.commenters;
	});

	resultObj.plays = Math.max(0, resultObj.plays);
	resultObj.favourites = Math.max(0, resultObj.favourites);
	resultObj.commenters = Math.max(0, resultObj.commenters);

	// ! CALCULATE REWARDS
	// define constants
	const game = level?.game;
	const plays = Math.max(resultObj?.plays, 0) || 0;
	const rates = resultObj?.rates || [];
	const rating = Math.ceil(resultObj?.rating * 2) / 2 || 2.5;
	const favourites = Math.max(resultObj?.favourites, 0) || 0;
	const commenters = Math.max(resultObj?.commenters, 0) || 0;
	let multiplier = 1;
	let xp = 0;

	// define multiplier
	switch (game) {
		case 0:
			multiplier = 1;
			break;
		case 1:
			multiplier = 1.3;
			break;
		case 2:
			multiplier = 1.5;
			break;
		case 3:
			multiplier = 0;
			break;
		case 4:
			multiplier = 1.2;
			break;
	}
	// now calculate the experience from this collect
	xp += (plays / 2) ** 0.8;
	xp += Math.floor(
		rates.reduce(
			// max 2.25 xp per rate on the level
			(sum, rate) => sum + Math.round((rate - 1) / 0.8) * 0.35,
			0
		)
	);
	xp += 4 * Math.round(favourites ** 0.7);
	xp += 3 * Math.round(commenters ** 0.7);
	xp = xp ** (Math.sqrt(rating + 1) / 2.25); // base the xp gain on the overal level rating
	xp *= multiplier; // apply the game multiplier
	xp = Math.round(xp * 100) / 100;
	// minimum xp of 3
	if (xp < 3) xp = 3;
	// set the experience and shimflake reward
	resultObj.experience = xp;
	resultObj.shimflakes = Math.round(xp * 0.25); //  shimflake reward is 25% of xp
	// include specials
	resultObj?.specials?.forEach((special) => {
		switch (special) {
			case "greenstars":
				resultObj.experience += 10;
				resultObj.shimflakes += 30;
				break;
		}
	});
	// minimum xp
	if (resultObj?.experience < 3) resultObj.experience = 3;
	if (level?.rating >= 4.9 && level?.rates?.length > 5)
		resultObj?.specials?.push("greenstars");
	// directly add values of specials for collective
	switch (true) {
		case resultObj?.specials?.includes("greenstars"):
			resultObj.experience += 10;
			resultObj.shimflakes += 30;
	}
	// end the function
	return resultObj;
};
