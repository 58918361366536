import { api } from "./index";

//!-------------------------//
//! --- hint api routes --- //
//!-------------------------//

// --- hints --- //
export const getHints = () => api.get(`/staff/hints/get`);
export const createHint = (hint) => api.post("/staff/hints/create", hint);
export const deleteHint = (hint) => api.put(`/staff/hints/delete/${hint}`);
